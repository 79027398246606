import { Link, useParams } from "react-router-dom";
import JoditEditor from "jodit-react";
import React, { useState, useRef, useEffect, useCallback } from "react";
import axios from "axios";
// import { ProgressBar } from "react-bootstrap";
import { Modal, Form, Table } from 'react-bootstrap';
import Box from "@mui/material/Box";
import Select from "react-select";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
// import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { TagsInput } from "react-tag-input-component";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import { useDropzone } from "react-dropzone";
import Dropzone from "react-dropzone";
import "./Editvideo.css";
import "vidstack/styles/defaults.css";
import { toast } from "react-toastify";
// import { defineCustomElements } from "vidstack/elements";
import "vidstack/styles/base.css";
import "vidstack/styles/ui/buttons.css";
import "vidstack/styles/ui/buffering.css";
import "vidstack/styles/ui/captions.css";
import "vidstack/styles/ui/tooltips.css";
import "vidstack/styles/ui/live.css";
import "vidstack/styles/ui/sliders.css";
import "vidstack/styles/ui/menus.css";
import "vidstack/define/media-player.js";
// import Slider from "react-slick";
// import AwesomeSlider from "react-awesome-slider";
import ReactPlayer from "react-player";
import "react-awesome-slider/dist/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import deleteitem from "../../../components/Images/deleteitem.svg";
import useCompressImageConfig from "../../../components/CommonApis/Compressimageapi";
import usePpvGlobalImageConfig from "../../../components/CommonApis/ppv_global_change";
import NavigationData from "../../../layout/Sidebar/NavigationData";

export default function Editvideo(props) {
  const [activeStep, setActiveStep] = React.useState(0);

  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  const handleReset = () => {
    setActiveStep(0);
  };

  const { id } = useParams();

  const { label_management } = NavigationData();

  const [Subtitlesmap, setSubtitlesmap] = useState([]);
  const [reelsvideoget, setReelsvideoget] = useState([]);
  // const [pre_ads_category, setPre_ads_category] = useState("");
  // const [mid_ads_category, setMid_ads_category] = useState("");
  // const [post_ads_category, setPost_ads_category] = useState("");
  // const [Block_Country, setBlock_Country] = useState([]);
  // const [Available_country, setAvailable_country] = useState([]);
  // const [trailer, setTrailer] = useState("");
  // const [Video_Title_Thumbnail, setVideo_Title_Thumbnail] = useState("");
  // const [image, setImage] = useState("");
  // const [player_image, setPlayer_image] = useState("");
  // const [video_tv_image, setVideo_tv_image] = useState("");
  const [pdf_files, setPdf_files] = useState("");
  // const [reels_thumbnail, setReels_thumbnail] = useState("");
  // const [reelvideo, setReelvideo] = useState("");
  // const [trailer_type, setTrailer_type] = useState("");
  // const [video_title_image, setVideo_title_image] = useState("");

  const [edittitle, setEdittitle] = useState({
    title: "",
  });

  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
    id: "",
    draft: "1",
    related_videos_title: "1",
    user_id: "",
    type: "",
    status: "",
    active: "",
    rating: "",
    duration: "",
    description: "",
    details: "",
    image: "",
    mobile_image: "",
    tablet_image: "",
    player_image: "",
    video_tv_image: "",
    enable_video_title_image: "",
    video_title_image: "",
    android_tv: "",
    video_gif: "",
    search_tags: "",
    access: "",
    global_ppv: "",
    ppv_price: "",
    ios_ppv_price: "",
    featured: "",
    banner: "",
    enable: "",
    embed_code: "",
    mp4_url: "",
    m3u8_url: "",
    webm_url: "",
    ogg_url: "",
    disk: "",
    original_name: "",
    path: "",
    stream_path: "",
    processed_low: "",
    converted_for_streaming_at: "",
    views: "",
    year: "",
    age_restrict: "",
    Recommendation: "",
    country: "",
    publish_status: "",
    publish_type: "",
    publish_time: "",
    skip_start_time: "",
    skip_end_time: "",
    skip_start_session: "",
    recap_start_time: "",
    recap_end_time: "",
    recap_start_session: "",
    pdf_files: "",
    reels_thumbnail: "",
    reelvideo: "",
    url: "",
    url_link: "",
    url_linktym: "",
    url_linksec: "",
    urlEnd_linksec: "",
    trailer_type: "null",
    trailer: "",
    trailer_description: "",
    ads_status: "",
    default_ads: "",
    pre_ads_category: "",
    mid_ads_category: "",
    post_ads_category: "",
    mid_ads: "",
    pre_ads: "",
    post_ads: "",
    ads_tag_url_id: "",
    free_duration_time: "",
    free_duration_status: "",
    time_zone: "",
    home_genre: "",
    in_menu: "",
    footer: "",
    uploaded_by: "",
    language: "",
    video_category_id: "",
    Video_reels_video_url: "",
    video_categories: "",
    video_block_countries: "",
    artists: "",
    realted_videos: "",
    Thumbnail: "",
    Player_thumbnail: "",
    TV_Thumbnail: "",
    Video_Title_Thumbnail: "",
    Video_reels_Thumbnail: "",
    Video_upload_url: "",
    website_page_title: "",
    website_URL: "",
    Meta_description: "",
    Video_reels_video: "",
    EPaper: "",
    trailer_filename: "",
    urlEnd_linksec: "",
    trailer_videos_url: "",
  });

  // const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [user_access, setUser_access] = useState([]);
  const [inapppurchase, setInappPurchase] = useState("");
  const [age_restrictdata, setAge_restrictdata] = useState([]);
  const [loading, setLoading] = useState(true);

  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");

  const handleTagsChange = (newTags) => {
    setTags(newTags);
  };

  const handleInputChangetag = (input) => {
    setNewTag(input);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault(); // Prevent the default behavior (adding space or newline)

      // Only add the tag if it's not empty
      if (newTag.trim() !== "") {
        setTags([...tags, newTag]);
        setNewTag("");
      }
    }
  };

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value; // setInputValue(event.target.value);
    setEditUser((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  // const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setEditUser((prevState) => {
        return { ...prevState, ppv_price: inputValueAPI };
      });
    } else {
      setEditUser((prevState) => {
        return { ...prevState, ppv_price: "" };
      });
    }
  };

  const handleChangeFirst = (e) => {
    setEditUser({ ...editUser, details: e });
    // console.log(e, "onchnageddddd");
  };
  const handleChangeFirstdes = (e) => {
    setEditUser({ ...editUser, trailer_description: e });
    // console.log(e, "onchnageddddd");
  };

  const [videosreels, setVideosreels] = useState([]);
  // const [uploadedFileNames, setUploadedFileNames] = useState([]);

  const handleDropreels = (acceptedFiles) => {
    setVideosreels(acceptedFiles);
  };

  const [videosubtitles, setVideoSubtitles] = useState({});

  const handleDropsubtitles = (language, acceptedFiles) => {
    // Handle dropped files here
    setVideoSubtitles((prevState) => ({
      ...prevState,
      [language]: acceptedFiles,
    }));
  };

  const [selectedFiles, setSelectedFiles] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFiles(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "video/*",
  });

  const { compressResolutionFormat } = useCompressImageConfig();

  const [error, setError] = useState({
    file1: '',
    file2: '',
    file3: '',
    file4: '',
    file5: ''
  });

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);
  const fileInputRef5 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);
  const [selectedFile5, setSelectedFile5] = useState(null);

  // const handleFileChangesss = (event, fileInputRef, setSelectedFile) => {
  //   const file = event.target.files[0];
  //   setSelectedFile(file);
  // };

  const validateImageRatio = (file, expectedRatio, expectedWidth, expectedHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const ratio = width / height;
        const expectedRatioPrecision = 0.01; // Allow for some minor floating-point precision issues
        const ratioMatch = Math.abs(ratio - expectedRatio) < expectedRatioPrecision;
        const dimensionMatch = (width === expectedWidth && height === expectedHeight);
        if (ratioMatch || dimensionMatch) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
      img.onerror = () => {
        reject(new Error('Error loading image'));
      };
    });
  };

  const handleFileChangesss = async (event, fileInputRef, setSelectedFile, errorKey) => {
    const file = event.target.files[0];
    setError(prevErrors => ({ ...prevErrors, [errorKey]: '' }));

    if (compressResolutionFormat?.videos === 1) {
      let isValid = false;

      if (fileInputRef === fileInputRef1) {
        isValid = await validateImageRatio(file, 9 / 16, 1080, 1920);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file1: 'Image must be in 9:16 Ratio or 1080x1920px.' }));
          fileInputRef.current.value = null;
          return;
        }
      } else if (fileInputRef === fileInputRef2) {
        isValid = await validateImageRatio(file, 16 / 9, 1280, 720);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file2: 'Image must be in 16:9 Ratio or 1280x720px.' }));
          fileInputRef.current.value = null;
          return;
        }
      } else if (fileInputRef === fileInputRef3) {
        isValid = await validateImageRatio(file, 16 / 9, 1280, 720);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file3: 'Image must be in 16:9 Ratio or 1280x720px.' }));
          fileInputRef.current.value = null;
          return;
        }
      } else if (fileInputRef === fileInputRef4) {
        isValid = await validateImageRatio(file, 16 / 9, 1280, 720);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file4: 'Image must be in 16:9 Ratio or 1280x720px.' }));
          fileInputRef.current.value = null;
          return;
        }
      } else if (fileInputRef === fileInputRef5) {
        isValid = await validateImageRatio(file, 16 / 9, 1280, 720);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file5: 'Image must be in 16:9 Ratio or 1280x720px.' }));
          fileInputRef.current.value = null;
          return;
        }
      }
    }

    setSelectedFile(file);
    setError(prevErrors => ({ ...prevErrors, [errorKey]: '' }));
  };


  const handleDelete = (index) => {
    setError('');
    switch (index) {
      case 1:
        setSelectedFile1(null);
        if (fileInputRef1.current) {
          fileInputRef1.current.value = null;
        }
        break;
      case 2:
        setSelectedFile2(null);
        if (fileInputRef2.current) {
          fileInputRef2.current.value = null;
        }
        break;
      case 3:
        setSelectedFile3(null);
        if (fileInputRef3.current) {
          fileInputRef3.current.value = null;
        }
        break;
      case 4:
        setSelectedFile4(null);
        if (fileInputRef4.current) {
          fileInputRef4.current.value = null;
        }
        break;
      case 5:
        setSelectedFile5(null);
        if (fileInputRef5.current) {
          fileInputRef5.current.value = null;
        }
        break;
      default:
        break;
    }
  };

  // MULTI SELECT NEW
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const [optionsrelated_video, setOptionsrelated_video] = useState([]);
  const [selectedValuesrelated_video, setSelectedValuesrelated_video] =
    useState([]);

  const [optionsplaylistvideo, setOptionsPlaylistvideo] = useState([]);
  const [selectedValuesplaylistvideo, setSelectedValuesPlaylistvideo] =
    useState([]);

  const [optionsartists, setOptionsartists] = useState([]);
  const [selectedValuesartists, setSelectedValuesartists] = useState([]);

  const [optionscategories, setOptionscategories] = useState([]);
  const [selectedValuescategories, setSelectedValuescategories] = useState([]);

  const [optionslanguages, setOptionslanguages] = useState([]);
  const [selectedValueslanguages, setSelectedValueslanguages] = useState([]);

  const [optionsavailable_country, setOptionsAvailable_country] = useState([]);
  const [selectedValuesavailable_country, setSelectedValuesAvailable_country] =
    useState([]);

  // const [optionsAge_Restrict, setOptionsAge_Restrict] = useState([]);
  // const [selectedValuesAge_Restrict, setSelectedValuesAge_Restrict] = useState(
  //   []
  // );

  const [publishType, setPublishType] = useState("publish_now");
  const [publishTime, setPublishTime] = useState("");
  const [formDatapublishedit, setFormDatapublishedit] = useState(
    new FormData()
  );

  const [formData, setFormData] = useState({
  });


  const [ppv_gobal_price, setppv_gobal_price] = useState();
  const [allCurrencyglobal, setAllCurrencyglobal] = useState([]);
  const [allCurrencycustoms, setAllCurrencycustoms] = useState([]);
  const [allCurrencylistfromappichoosed, setAllCurrencylistfromappichoosed] = useState();
  const { allCurrencylistfromappi, globalPpvStatusActive } = usePpvGlobalImageConfig();
  const global_ppv_status_active = globalPpvStatusActive


  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/videos-edit/${id}`,
        { headers: headers }
      );
      const data = response?.data?.videos[0]?.video_block_countries;
      // const test = response?.data;
      const dataavailable_country =
        response?.data?.videos[0]?.available_countries;
      const dataartists = response?.data?.videos[0]?.artists;
      const datacategories = response?.data?.videos[0]?.video_categories;

      const datalanguages = response?.data?.videos[0]?.Language;
      // const dataAge_Restrict = response?.data?.selected_Age_Restrict;
      const datarelated_video = response?.data?.videos[0]?.realted_videos;
      const dataplaylistvideo = response?.data?.videos[0]?.video_playlist;
      // console.log(typeof data)
      var result = response?.data?.user_access;
      var InappPurchase = response?.data?.InappPurchase;
      setUser_access(result);
      setInappPurchase(InappPurchase);
      var DefaultCurrencytest = response?.data?.DefaultCurrency[0];
      setAllCurrencylistfromappichoosed(DefaultCurrencytest);
      var DefaultGlobalContentPriceRates = response?.data?.GlobalContentPriceRates;
      setAllCurrencyglobal(DefaultGlobalContentPriceRates);
      var DefaultContentPriceRates = response?.data?.ContentPriceRates;
      setAllCurrencycustoms(DefaultContentPriceRates);

      const subtitlesmap = response?.data?.video_subtitle;
      const ontentPriceRates = response?.data?.ContentPriceRates;
      // setContentPriceRates(ontentPriceRates)
      const reelsvideoget = response?.data?.videos[0]?.Video_reels_video;
      setSubtitlesmap(subtitlesmap);
      setReelsvideoget(reelsvideoget);
      // console.log(reelsvideoget);

      var res = response?.data?.videos[0];
      // var globelset = response?.data?.ppv_gobal_price;
      var age_restrictdata = response?.data?.Age_Restrict;
      setAge_restrictdata(age_restrictdata);
      // setInputValueAPI(globelset);
      // console.log(datacategories)
      setEditUser(res);
      setEdittitle(res);
      setPublishType(res?.publish_type);
      setPublishTime(res?.publish_time);
      // setPublishType(res);
      // setPublishTime(mockApiresponse?.publish_time);

      setLoading(false);

      const selectedData = data;
      const formattedOptions = selectedData?.map((item) => ({
        value: item?.country_id,
        label: item?.name,
      }));

      const formattedOptionsavailable_country = dataavailable_country?.map(
        (item) => ({
          value: item?.id,
          label: item?.name,
        })
      );
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item?.artist_id,
        label: item?.name,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item?.category_id,
        label: item?.name,
      }));

      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item?.language_id,
        label: item?.name,
      }));
      // const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
      //   value: item?.id,
      //   label: item?.name,
      // }));
      const formattedOptionsrelated_video = datarelated_video?.map((item) => ({
        value: item?.related_videos_id,
        label: item?.title,
      }));
      const formattedOptionsplaylistvideo = dataplaylistvideo?.map((item) => ({
        value: item?.playlistvideo_id,
        label: item?.title,
      }));

      // Set the initial selected values
      setSelectedValues(formattedOptions);
      setOptions(formattedOptions);

      setSelectedValuesrelated_video(formattedOptionsrelated_video);
      setOptionsrelated_video(formattedOptionsrelated_video);

      setSelectedValuesPlaylistvideo(formattedOptionsplaylistvideo);
      setOptionsPlaylistvideo(formattedOptionsplaylistvideo);

      setSelectedValuesAvailable_country(formattedOptionsavailable_country);
      setOptionsAvailable_country(formattedOptionsavailable_country);

      setSelectedValuesartists(formattedOptionsartists);
      setOptionsartists(formattedOptionsartists);

      setSelectedValuescategories(formattedOptionscategories);
      setOptionscategories(formattedOptionscategories);

      setSelectedValueslanguages(formattedOptionslanguages);
      setOptionslanguages(formattedOptionslanguages);

      // setSelectedValuesAge_Restrict(formattedOptionsAge_Restrict);
      // setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      // console.log(formattedOptions);
      // console.log(formattedOptions);

      setTags(
        response?.data?.videos[0]?.search_tags
          .split(",")
          .map((tag) => tag.trim())
      );
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/videos-create`,
        { headers: headers }
      );
      const data = response?.data?.Block_Country;
      const dataavailable_country = response?.data?.Available_country;
      const datarelated_video = response?.data?.Related_videos;
      const dataplaylistvideo = response?.data?.all_video_playlist;
      const dataartists = response?.data?.video_artist;
      const datacategories = response?.data?.video_category;
      const datalanguages = response?.data?.video_language;
      // const dataAge_Restrict = response?.data?.Age_Restrict;

      // setSelectedLanguages(data)
      // console.log(data);

      const globelset = response?.data?.ppv_gobal_price;
      setInputValueAPI(globelset);
      setppv_gobal_price(globelset)
      // console.log(data);
      // console.log(subtitlesmap, "subtitlesmap");
      // Extract the selected data from the response
      const selectedData = data;
      const formattedOptions = selectedData?.map((item) => ({
        value: item?.id,
        label: item?.country_name,
      }));
      const formattedOptionsavailable_country = dataavailable_country?.map(
        (item) => ({
          value: item?.id,
          label: item?.country_name,
        })
      );
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item?.id,
        label: item?.artist_name,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      // const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
      //   value: item?.id,
      //   label: item?.age,
      // }));
      const formattedOptionsrelated_video = datarelated_video?.map((item) => ({
        value: item?.id,
        label: item?.title,
      }));
      const formattedOptionsplaylistvideo = dataplaylistvideo?.map((item) => ({
        value: item?.id,
        label: item?.title,
      }));

      // Set the options for the multi-select component
      setOptions(formattedOptions);
      setOptionsAvailable_country(formattedOptionsavailable_country);
      setOptionsartists(formattedOptionsartists);
      setOptionscategories(formattedOptionscategories);
      setOptionslanguages(formattedOptionslanguages);
      // setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      setOptionsrelated_video(formattedOptionsrelated_video);
      setOptionsPlaylistvideo(formattedOptionsplaylistvideo);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
    } catch (error) {
      console.error(error);
    }
  };
  // const handleLanguageChange = (event) => {
  //   const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
  //   setSelectedLanguages(selectedOptions);
  // };

  useEffect(async () => {
    fetchData();
  }, []);

  const handleSelectChange = (selectedOptions) => {
    // const block_country = selectedOptions?.map((option) => option?.value);

    setFormData({
      // ...formData,
      // ...editInputvalue,
      // block_country_id: block_country,
    });

    setSelectedValues(selectedOptions);
  };
  const handleSelectChangeavailable_country = (
    selectedOptionsavailable_country
  ) => {
    const available_country = selectedOptionsavailable_country?.map(
      (option) => option?.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      available_country: available_country,
    });

    setSelectedValuesAvailable_country(selectedOptionsavailable_country);
  };
  const handleSelectChangeartists = (selectedOptionsartists) => {
    // const artists = selectedOptionsartists?.map((option) => option?.value);

    setFormData({
      // ...formData,
      // ...editInputvalue,
      // artist_id: artists,
    });

    setSelectedValuesartists(selectedOptionsartists);
  };

  const handleSelectChangecategories = (selectedOptionscategories) => {
    // const categories = selectedOptionscategories?.map(
    //   (option) => option?.value
    // );

    setFormData({
      // ...formData,
      // ...editInputvalue,
      // category_id: categories,
    });
    setIsValidationHiddencategory(true);
    setSelectedValuescategories(selectedOptionscategories);
  };

  const handleSelectChangerelated_video = (selectedOptionsrelated_video) => {
    // const related_video = selectedOptionsrelated_video?.map(
    //   (option) => option?.value
    // );

    setFormData({
      // ...formData,
      // ...editInputvalue,
      // related_videos: related_video,
    });

    setSelectedValuesrelated_video(selectedOptionsrelated_video);
  };

  const handleSelectChangeplaylistvideo = (selectedOptionsplaylistvideo) => {
    // const playlistvideo = selectedOptionsplaylistvideo?.map(
    //   (option) => option?.value
    // );

    setFormData({
      // ...formData,
      // ...editInputvalue,
      // playlistvideo: playlistvideo,
    });

    setSelectedValuesPlaylistvideo(selectedOptionsplaylistvideo);
  };

  const handleSelectChangelanguages = (selectedOptionslanguages) => {
    // const languages = selectedOptionslanguages?.map((option) => option?.value);

    setFormData({
      // ...formData,
      // ...editInputvalue,
      // languages: languages,
    });
    setIsValidationHiddenlanguages(true);
    setSelectedValueslanguages(selectedOptionslanguages);
  };
  // const handleSelectChangeAge_Restrict = (selectedOptionsAge_Restrict) => {
  //   const Age_Restrict = selectedOptionsAge_Restrict?.map(
  //     (option) => option?.value
  //   );

  //   setFormData({
  //     // ...formData,
  //     // ...editInputvalue,
  //     // Age_Restrict: Age_Restrict,
  //   });

  //   setIsValidationHiddenlanguages(true);
  //   setSelectedValueslanguages(selectedOptionsAge_Restrict);
  // };

  // MULTI SELECT NEW

  const convertToTitleCase = (str) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const formattedDatatype = convertToTitleCase(editUser?.type);

  const handlePublishTypeChange = (event) => {
    const selectedValue = event.target.value;
    setPublishType(selectedValue);

    if (selectedValue === "publish_now") {
      setPublishTime(""); // Reset the publish time when switching to "Publish Now"
      formDatapublishedit?.delete("publish_time"); // Remove publish_time from FormData
    }
  };

  const handlePublishTimeChange = (event) => {
    const timeValue = event.target.value;
    setPublishTime(timeValue);
    formDatapublishedit?.set("publish_time", timeValue);
    // formDatapublishedit.set('publish_status', 'your_publish_status'); // Replace with your actual value
    formDatapublishedit?.set("publish_type", publishType);
  };

  useEffect(async () => {
    // const fetchAdsPositions = async () => {
    //   try {
    //     const response = await fetch(
    //       `${process.env.REACT_APP_Baseurl}/admin/videos-create`,
    //       {
    //         headers: headers,
    //       }
    //     );

    //     if (response?.ok) {
    //       const data = await response?.json();
    //       // console.log(data);

    //       const adsPositionOptions = data?.ads_position?.map((position) => ({
    //         value: position?.position,
    //         label: position?.name,
    //       }));
    //       // console.log(adsPositionOptions);
    //       setAdsPositions(adsPositionOptions);
    //     } else {
    //       throw new Error("Request failed with status: " + response?.status);
    //     }
    //   } catch (error) {
    //     console.log("Error fetching ads positions:", error);
    //   }
    // };
    // fetchAdsPositions();

    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/videos-create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response?.data.livestream_language)
        var age_restrictdata = response?.data?.Age_Restrict;
        // var ads_category = response?.data?.ads_category;
        // var Block_Country = response?.data?.Block_Country;
        // var Available_country = response?.data?.Available_country;
        // var user_access = response?.data?.user_access;
        setAge_restrictdata(age_restrictdata);
        // setPre_ads_category(ads_category);
        // setMid_ads_category(ads_category);
        // setPost_ads_category(ads_category);
        // console.log(response)
        // console.log(age_restrictdata)
        // console.log(user_access)
        // console.log(Available_country)

        // setBlock_Country(Block_Country);
        // setAvailable_country(Available_country);
      })
      .catch((error) => console.log(error));
  }, [id]);

  // useEffect(async () => {
  //   if (selectedAdsPosition !== "") {
  //     // console.log(selectedAdsPosition, "adsssssssssss");
  //     const formData = new FormData();
  //     formData?.append("position", selectedAdsPosition?.value);

  //     axios

  //       .post(
  //         `${process.env.REACT_APP_Baseurl}/admin/advertisement-tag-url`,
  //         formData,
  //         { headers: headers }
  //       )

  //       .then((response) => {
  //         const data = response?.data;
  //         // console.log(data, "exceptioned");

  //         const adsNames = data?.Advertisement?.map((position) => ({
  //           value: position?.id,
  //           label: position?.ads_name,
  //         }));

  //         setSecondSelectOptions(adsNames);
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //       });
  //   }
  // }, [selectedAdsPosition]);

  // const [enable_restreampublic, setEnable_restreampublic] = useState("");
  // const [enablestreamvisiblepublic, setEnablestreamvisiblepublic] =
  //   useState("");

  // function proceednextpublic() {
  //   switch (enable_restreampublic) {
  //     case "publish_now":
  //       return <div></div>;
  //     case "publish_later":
  //       return (
  //         <div className="mb-3">
  //           <label className="m-0">Publish Time</label>
  //           <input
  //             type="datetime-local"
  //             className="form-control"
  //             id="publish_time"
  //             name="publish_time"
  //             onChange={handleInput}
  //             value={editUser?.publish_time}
  //           />
  //         </div>
  //       );
  //     default:
  //       return null;
  //   }
  // }

  const [enable_restreamtrailer, setEnable_restreamtrailer] = useState("");
  const [enablestreamvisibletrailer, setEnablestreamvisibletrailer] =
    useState("");

  function proceednexttrailer() {
    switch (enable_restreamtrailer) {
      case "0":
        return <div></div>;
      case "1":
        return (
          <div className="col-lg-12 row mt-2">
            {editUser?.trailer ? (
              <div className="">
                <label className="mb-1"> Trailer Video </label>
                <div className="col-lg-12 row mt-2">
                  <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                    <media-player
                      // title={editUser?.Video_reels_video}
                      src={editUser?.trailer}
                      poster=""
                      controls
                    >
                      <media-outlet></media-outlet>
                    </media-player>
                  </div>
                  <div className="col-6 col-sm-6 col-md-6 col-lg-6  align-self-center">
                    <Link to="" className="delete ms-1">
                      <span onClick={() => deleteOperationtrailer(id)}>
                        <img
                          src={deleteitem}
                          alt="flogo"
                          width={20}
                          height={20}
                        />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="">
              <label className="mb-1"> Upload Trailer </label>

              <div className="file-draganddrop">
                <div
                  {...getRootProps()}
                  className={`mt-3 drop-area ${isDragActive ? "active" : ""}`}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the video file here...</p>
                  ) : (
                    <p>Drag and drop your video file here</p>
                  )}
                </div>
                {selectedFiles && (
                  <div>
                    <p>{selectedFiles?.name}</p>
                  </div>
                )}
              </div>

              {/* <div className=" text-center file-drag mt-2">
              <label id="label-file-upload" htmlFor="input-file-upload">
                <div>
                  <p>Drag and drop your file here or</p>
                  <p>
                    <input
                      type="file"
                      className="form-control-file"
                      multiple
                      accept="video/mp4"
                      id="input-file-upload"
                      onChange={(e) => setTrailer(e.target.files[0])}
                    />
                  </p>
                </div>
                <br />
              </label>
            </div> */}
            </div>
          </div>
        );
      case "m3u8_url":
        return (
          <div>
            <div className="col-lg-12 row mt-2">
              {editUser?.trailer ? (
                <div className="">
                  <label className="mb-1"> M3u8 Video </label>

                  <div>
                    <media-player
                      // title={editUser?.Video_reels_video}
                      src={editUser?.trailer}
                      poster=""
                      controls
                    >
                      <media-outlet></media-outlet>
                    </media-player>
                  </div>
                </div>
              ) : null}
              <div className="col-sm-12 mt-3 text-start">
                <div className="">
                  <label className="mb-1">Trailer m3u8 URL </label>
                  <input
                    type="text"
                    className="form-control-lg rs-input"
                    name="trailer"
                    onChange={handleInput}
                    value={editUser?.trailer}
                    placeholder="m3u8 URL"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case "mp4_url":
        return (
          <div>
            <div className="col-lg-12 row mt-2">
              {editUser?.trailer ? (
                <div className="">
                  <label className="mb-1"> Mp4 Video </label>

                  <div>
                    <media-player
                      // title={editUser?.Video_reels_video}
                      src={editUser?.trailer}
                      poster=""
                      controls
                    >
                      <media-outlet></media-outlet>
                    </media-player>
                  </div>
                </div>
              ) : null}
              <div className="col-sm-12 mt-3 text-start">
                <div className="">
                  <label className="mb-1"> Trailer Mp4 File URL </label>
                  <input
                    type="text"
                    className="form-control-lg rs-input"
                    name="trailer"
                    onChange={handleInput}
                    value={editUser?.trailer}
                    placeholder="mp4 Trailer"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case "embed_url":
        return (
          <div>
            <div className="col-lg-12 row mt-2">
              {editUser?.trailer ? (
                <div className="">
                  <label className="mb-1"> Embed Video </label>

                  <div>
                    <media-player
                      // title={editUser?.Video_reels_video}
                      src={editUser?.trailer}
                      poster=""
                      controls
                    >
                      <media-outlet></media-outlet>
                    </media-player>
                  </div>
                </div>
              ) : null}
              <div className="col-sm-12 mt-3 text-start">
                <div className="">
                  <label className="mb-1"> Trailer Embed URL: </label>
                  <input
                    type="text"
                    className="form-control-lg rs-input"
                    name="trailer"
                    placeholder="Embed URL"
                    onChange={handleInput}
                    value={editUser?.trailer}
                  />
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  }

  const [allliveuseraccess, setallliveuseraccess] = useState("");
  // const [storeliveuseraccess, setStoreliveuseraccess] = useState("");

  function alllive_streamuseraccess() {
    switch (allliveuseraccess) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <>
            {global_ppv_status_active?.PPV_Individual_Contents === "0" || global_ppv_status_active?.PPV_Individual_Contents === 0 ? (
              <div className="row mt-3 text-start ">
                <div className="col-sm-12">
                  <label className="m-0">{label_management?.videos[71]?.title}</label>
                  <div className="col-lg-12 row">
                    <div className="col-8 col-lg-8">
                      <p className="p1">{label_management?.videos[71]?.sub_title}</p>
                    </div>
                    <div className="col-4 col-lg-4">
                      <label className="switch">
                        <input
                          name="ppv_price"
                          defaultChecked={checkboxChecked}
                          onChange={handleCheckboxChange}
                          type="checkbox"
                          className="rs-input"
                        />
                        <span
                          className="slider round"
                          name="ppv_price"
                          checked={checkboxChecked}
                          onChange={handleCheckboxChange}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="panel-body">
                    <input
                      type="number"
                      className="rs-input"
                      placeholder="PPV Price"
                      value={editUser?.ppv_price}
                      id="ppv_price"
                      name="ppv_price"
                      // value={inputValue}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-sm-12 mt-2">
                  <label className="m-0">{label_management?.videos[68]?.title}</label>
                  <p className="p1">{label_management?.videos[68]?.sub_title}</p>
                  <div className="panel-body ppv_price_ios mt-2">
                    <select
                      name="ios_ppv_price"
                      id="ios_ppv_price"
                      onChange={handleInput}
                      value={editUser?.ios_ppv_price}
                      className="form-select"
                    >
                      {inapppurchase?.map((item, key) => (
                        <option value={item?.product_id}>{item?.plan_price}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            ) :
              <div className="mt-2">
                <div className="col-sm-12">
                  <div className='modalofglobalppv'>
                    <Form>
                      <>
                        {global_ppv_status_active?.ppv_status === "1" || global_ppv_status_active?.ppv_status === 1 ? (
                          <Form.Check
                            type="radio"
                            label={label_management?.videos[65]?.title}
                            name="priceOption"
                            value="1"
                            checked={editUser?.global_ppv === "1" || editUser?.global_ppv === 1}
                            // onChange={handleChange}
                            onClick={() => handleChange({ target: { value: '1' } })}
                          />
                        ) : null}
                      </>
                      <Form.Check
                        type="radio"
                        label={label_management?.videos[66]?.title}
                        name="priceOption"
                        value="2"
                        checked={editUser?.global_ppv === null || (editUser?.global_ppv !== "1" && editUser?.global_ppv !== 1)}
                        // onChange={handleChange}
                        onClick={() => handleChange({ target: { value: '2' } })}
                      />
                    </Form>
                    <Modal show={showModal} onHide={handleClose} dialogClassName="modal-lg modal-dialog-scrollable" backdrop="static"
                      keyboard={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>{modalType === '1' ? `${label_management?.videos[70]?.title}` : `${label_management?.videos[71]?.title}`}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="modal-body-custom">
                        {step === 1 ? (
                          <Form>
                            <Form.Group>
                              <Form.Label
                                className="mandatorySimple">{modalType === '1' ? `${label_management?.videos[65]?.title}` : `${label_management?.videos[65]?.title}`}</Form.Label>
                              <Form.Control
                                type="number"
                                name="price"
                                value={modalType === '1' ? formDataglobalprice.price : formDatacustomprice.price}
                                onChange={(e) => handlePriceChange(e, modalType === '1' ? 'global' : 'custom')}
                                disabled={modalType === '1'}
                              />
                            </Form.Group>
                            {modalType === '2' && (
                              <Form.Group>
                                <Form.Label
                                  className="mandatorySimple">{label_management?.videos[67]?.title}</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="priceType"
                                  value={formDatacustomprice.priceType}
                                  onChange={handleCustomPriceTypeChange}
                                  disabled
                                >
                                  <option value="">Select Countries</option>
                                  {allCurrencylistfromappi?.map(item => (
                                    <option key={item?.code} value={item?.code}>{item?.country}</option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            )}
                            {modalType === '1' && (
                              <Form.Group>
                                <Form.Label
                                  className="mandatorySimple">{label_management?.videos[67]?.title}</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="priceType"
                                  value={formDatacustomprice.priceType}
                                  onChange={handleCustomPriceTypeChange}
                                  disabled
                                >
                                  <option value="">Select Countries</option>
                                  {allCurrencylistfromappi?.map(item => (
                                    <option key={item?.code} value={item?.code}>{item?.country}</option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            )}
                            {/* <div className='text-end mt-2'>
                            <Button variant="primary" onClick={handleNext}>
                              Next
                            </Button>
                          </div> */}

                            <div className="col-sm-12 mt-2">
                              <label className="m-0">{label_management?.videos[68]?.title}</label>
                              <p className="p1">{label_management?.videos[68]?.sub_title}</p>
                              <div className="panel-body ppv_price_ios mt-2">
                                <select
                                  name="ios_ppv_price"
                                  id="ios_ppv_price"
                                  onChange={handleInput}
                                  value={editUser?.ios_ppv_price}
                                  className="form-select"
                                >
                                  <option value="">Select IOS Price</option>
                                  {inapppurchase?.map((item, key) => (
                                    <option value={item?.product_id}>{item?.plan_price}</option>
                                  ))}
                                </select>
                              </div>
                            </div>

                          </Form>
                        ) : (
                          <>
                            <div className="table-responsive">
                              <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Country</th>
                                    <th>Current Price</th>
                                    <th>Converted Price</th>
                                    <th>Difference</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(modalType === '1' ? formDataglobalprice.convertedPrices : formDatacustomprice.convertedPrices).map((item, index) => (
                                    <tr key={index}>
                                      <td>{item?.country_name || item?.Country} ({item?.Code})</td>
                                      <td>{item?.Symbol || item?.symbol} {item?.price || item?.ActualPrice}</td>
                                      <td>
                                        <Form.Control
                                          type="number"
                                          value={item?.editPrice !== undefined ? item?.editPrice : item?.convertedPrice}
                                          onChange={(e) => handleInputChangeeditable(index, 'editPrice', e.target.value)}
                                          disabled={modalType === '1'}
                                        />
                                      </td>
                                      <td>{item?.difference || item?.RateDifference}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                            {/* <div className='text-end mt-2'>
                            <Button variant="secondary" onClick={() => setStep(1)}>
                              Previous
                            </Button>
                            <Button variant="primary" onClick={handleSubmit}>
                              Submit
                            </Button>
                          </div> */}
                          </>
                        )}
                      </Modal.Body>

                      <hr></hr>
                      {step === 1 ? (

                        <div className='text-end p-2'>
                          <Button variant="primary" onClick={handleNext}>
                            Next
                          </Button>
                        </div>
                      ) : (
                        <div className='text-end p-2'>
                          <Button variant="secondary" onClick={() => setStep(1)}>
                            Previous
                          </Button>
                          <Button variant="primary" onClick={handleSubmit} className='ms-2'>
                            Submit
                          </Button>
                        </div>
                      )}
                    </Modal>
                  </div>
                </div>
              </div>
            }
          </>
        );
      default:
        return null;
    }
  }

  // const [selectedValue, setSelectedValue] = useState("");
  // const [apiResponse, setApiResponse] = useState("");

  // const handleSelectChanges = async (event) => {
  //   const { value } = event.target;
  //   setSelectedValue(value);

  //   const formData = new FormData();

  //   formData?.append("value", value);

  //   await axios
  //     .post(
  //       `${process.env.REACT_APP_Baseurl}/admin/advertisement-tag-url`,
  //       formData,
  //       { headers: headers }
  //     )
  //     .then((response) => {
  //       // console.log("apiasas", response);
  //       if (response?.data?.status === true) {
  //         var result = response?.data;

  //         console.log("api", result);
  //       } else {
  //         console.log("Error");
  //         alert("Enter Correct Details");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("Error");
  //       alert("Enter Correct Details");
  //     });
  // };

  // const handleInputdata = (e) => {
  //   const inputName = e.target.name;
  //   const inputValue = e.target.value;

  //   // Remove non-digit characters from input
  //   const formattedInput = inputValue.replace(/\D/g, "");

  //   // Add colons to maintain the format HH:MM:SS
  //   const formattedTime = formattedInput
  //     .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
  //     .substr(0, 8);

  //   setEditUser({
  //     ...editUser,
  //     [inputName]: formattedTime,
  //   });
  // };

  const [errors, setErrors] = useState({
    error_skip_start_time: "",
    error_skip_end_time: "",
    error_skip_start_session: "",
    error_recap_start_time: "",
    error_recap_end_time: "",
    error_recap_start_session: "",
  });

  const handleInputdata = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });

    validateInput(inputName, formattedTime);
  };

  const validateInput = (inputName, formattedTime) => {
    const errorsCopy = { ...errors };

    switch (inputName) {
      case "skip_start_time":
        if (formattedTime === "") {
          errorsCopy.error_skip_start_time = "";
        } else {
          errorsCopy.error_skip_start_time = "";
        }
        break;

      case "skip_end_time":
        if (formattedTime === "") {
          errorsCopy.error_skip_end_time = "";
        } else if (formattedTime <= editUser?.skip_start_time) {
          errorsCopy.error_skip_end_time =
            "Skip End time must be greater than Skip Start time";
        } else {
          errorsCopy.error_skip_end_time = "";
        }
        break;

      case "skip_start_session":
        if (formattedTime === "") {
          errorsCopy.error_skip_start_session = "";
        } else if (formattedTime <= editUser?.skip_end_time) {
          errorsCopy.error_skip_start_session =
            "Skip Start Session must be greater than Skip End time";
        } else if (formattedTime === editUser?.skip_start_time) {
          errorsCopy.error_skip_start_session =
            "Skip Start Session cannot be the same as Skip Start Time";
        } else {
          errorsCopy.error_skip_start_session = "";
        }
        break;

      case "recap_start_time":
        if (formattedTime === "") {
          errorsCopy.error_recap_start_time = "";
        } else {
          errorsCopy.error_recap_start_time = "";
        }
        break;

      case "recap_end_time":
        if (formattedTime === "") {
          errorsCopy.error_recap_end_time = "";
        } else if (formattedTime <= editUser?.recap_start_time) {
          errorsCopy.error_recap_end_time =
            "Recap End time must be greater than Recap Start time";
        } else {
          errorsCopy.error_recap_end_time = "";
        }
        break;

      case "recap_start_session":
        if (formattedTime === "") {
          errorsCopy.error_recap_start_session = "";
        } else if (formattedTime <= editUser?.recap_end_time) {
          errorsCopy.error_recap_start_session =
            "Recap Start Session must be greater than Recap End time";
        } else if (formattedTime === editUser?.recap_start_time) {
          errorsCopy.error_recap_start_session =
            "Recap Start Session cannot be the same as Recap Start Time";
        } else {
          errorsCopy.error_recap_start_session = "";
        }
        break;

      default:
        break;
    }

    setErrors(errorsCopy);
  };

  // const insertColons = (time) => {
  //   if (time?.length >= 6) {
  //     const hours = time.substr(0, 2);
  //     const minutes = time.substr(2, 2);
  //     const seconds = time.substr(4, 2);

  //     return `${hours}:${minutes}:${seconds}`;
  //   }
  //   return time;
  // };

  const handleChangeyear = (event) => {
    const value = event.target.value;
    setEditUser({ ...editUser, year: value });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setValidationMessageAccess("");
    setIsValidationHiddenAccess(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    // if (e.target.checked === true) {
    //   setEditUser({ ...editUser, [e.target.name]: e.target.value });
    //   setEditUser({ ...editUser, [e.target.id]: 1 });
    //   setEditUser({
    //     ...editUser,
    //     [e.target.name]: e.target.value,
    //     [e.target.name]: 1,
    //   });
    // } else {
    //   setEditUser({ ...editUser, [e.target.name]: e.target.value });
    //   setEditUser({ ...editUser, [e.target.id]: 0 });
    //   setEditUser({ ...editUser, [e.target.name]: e.target.value });
    // }
  };

  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const [validationMessagecategory, setValidationMessagecategory] =
    useState("");
  const [isValidationHiddencategory, setIsValidationHiddencategory] =
    useState(true);

  const [validationMessagelanguages, setValidationMessagelanguages] =
    useState("");
  const [isValidationHiddenlanguages, setIsValidationHiddenlanguages] =
    useState(true);

  const titleInputRef = useRef(null);
  const categoryInputRef = useRef(null);
  const languageInputRef = useRef(null);
  const accessInputRef = useRef(null);

  // const handleUpdate = async (e) => {
  //   e.preventDefault();

  //   let focusInputRef = null;

  //   if (editUser?.title === "") {
  //     setValidationMessagetitle("Title cannot be empty.");
  //     setIsValidationHiddentitle(false);
  //     focusInputRef = titleInputRef;
  //   } else if (
  //     !selectedValuescategories ||
  //     selectedValuescategories.length === 0
  //   ) {
  //     setValidationMessagecategory("Please select at least one Category.");
  //     setIsValidationHiddencategory(false);
  //     focusInputRef = categoryInputRef;
  //   } else if (
  //     !selectedValueslanguages ||
  //     selectedValueslanguages.length === 0
  //   ) {
  //     setValidationMessagelanguages("Please select at least one Language.");
  //     setIsValidationHiddenlanguages(false);
  //     focusInputRef = languageInputRef;
  //   }

  //   if (focusInputRef) {
  //     focusInputRef.current.focus();
  //     return;
  //   }

  //   const updatedTagsString = tags.join(", ");

  //   const editInputvalue = {
  //     title: editUser?.title,
  //     type: editUser?.type,
  //     slug: editUser?.slug,
  //     id: editUser?.id,
  //     active: editUser?.active,
  //     user_id: editUser?.user_id,
  //     status: editUser?.status,
  //     draft: editUser?.draft,
  //     rating: editUser?.rating,
  //     duration: editUser?.duration,
  //     details: editUser?.details,
  //     description: editUser?.description,
  //     image: editUser?.image,
  //     mobile_image: editUser?.mobile_image,
  //     tablet_image: editUser?.tablet_image,
  //     player_image: editUser?.player_image,
  //     video_tv_image: editUser?.video_tv_image,
  //     enable_video_title_image: editUser?.enable_video_title_image,
  //     video_title_image: editUser?.video_title_image,
  //     android_tv: editUser?.android_tv,
  //     video_gif: editUser?.video_gif,
  //     search_tags: updatedTagsString,
  //     access: editUser?.access,
  //     global_ppv: editUser?.global_ppv,
  //     ppv_price: editUser?.ppv_price,
  //     ios_ppv_price: editUser?.ios_ppv_price,
  //     featured: editUser?.featured,
  //     banner: editUser?.banner,
  //     enable: editUser?.enable,
  //     embed_code: editUser?.embed_code,
  //     mp4_url: editUser?.mp4_url,
  //     m3u8_url: editUser?.m3u8_url,
  //     webm_url: editUser?.webm_url,
  //     ogg_url: editUser?.ogg_url,
  //     disk: editUser?.disk,
  //     original_name: editUser?.original_name,
  //     path: editUser?.path,
  //     stream_path: editUser?.stream_path,
  //     processed_low: editUser?.processed_low,
  //     converted_for_streaming_at: editUser?.converted_for_streaming_at,
  //     views: editUser?.views,
  //     year: editUser?.year,
  //     age_restrict: editUser?.age_restrict,
  //     Recommendation: editUser?.Recommendation,
  //     country: editUser?.country,
  //     publish_status: editUser?.publish_status,
  //     publish_type: editUser?.publish_type,
  //     publish_time: editUser?.publish_time,
  //     recap_end_time: editUser?.recap_end_time,
  //     skip_start_time: editUser?.skip_start_time,
  //     recap_end_time: editUser?.recap_end_time,
  //     recap_end_time: editUser?.recap_end_time,
  //     skip_end_time: editUser?.skip_end_time,
  //     skip_start_session: editUser?.skip_start_session,
  //     pdf_files: editUser?.pdf_files,
  //     reels_thumbnail: editUser?.reels_thumbnail,
  //     reelvideo: editUser?.reelvideo,
  //     url: editUser?.url,
  //     url_link: editUser?.url_link,
  //     url_linktym: editUser?.url_linktym,
  //     url_linksec: editUser?.url_linksec,
  //     urlEnd_linksec: editUser?.urlEnd_linksec,
  //     trailer_type: editUser?.trailer_type,
  //     trailer: editUser?.trailer,
  //     trailer_description: editUser?.trailer_description,
  //     ads_status: editUser?.ads_status,
  //     default_ads: editUser?.default_ads,
  //     pre_ads_category: editUser?.pre_ads_category,
  //     mid_ads_category: editUser?.mid_ads_category,
  //     post_ads_category: editUser?.post_ads_category,
  //     pre_ads: editUser?.pre_ads,
  //     mid_ads: editUser?.mid_ads,
  //     post_ads: editUser?.post_ads,
  //     ads_tag_url_id: editUser?.ads_tag_url_id,
  //     time_zone: editUser?.time_zone,
  //     home_genre: editUser?.home_genre,
  //     in_menu: editUser?.in_menu,
  //     footer: editUser?.footer,
  //     uploaded_by: editUser?.uploaded_by,
  //     language: editUser?.language,
  //     video_category_id: editUser?.video_category_id,
  //     Thumbnail: editUser?.Thumbnail,
  //     Player_thumbnail: editUser?.Player_thumbnail,
  //     TV_Thumbnail: editUser?.TV_Thumbnail,
  //     Video_Title_Thumbnail: editUser?.Video_Title_Thumbnail,
  //     Video_reels_Thumbnail: editUser?.Video_reels_Thumbnail,
  //     Video_reels_video_url: editUser?.Video_reels_video_url,
  //     Video_upload_url: editUser?.Video_upload_url,
  //     video_category_id: editUser?.video_category_id,
  //     ...formData,
  //     ...formDatapublishedit,
  //   };
  //   // console.log(editInputvalue);
  //   let res = await fetch(
  //     `${process.env.REACT_APP_Baseurl}/admin/videos-update/` + id,
  //     {
  //       method: "POST",
  //       headers: headers,
  //       body: JSON.stringify(editInputvalue),
  //     }
  //   );

  //   let resjson = await res.json();
  //   // console.log(resjson)
  //   if (resjson.status === true) {
  //     // setMessage(resjson.success);
  //     // setTimeout(() => {}, 2000);
  //     var result = resjson.videos.id;
  //     var videosvideos = resjson;
  //     // console.log(result, "vododa");

  //     const formData1 = new FormData();
  //     formData1.append("video_id", id);
  //     videosreels.forEach((video, index) => {
  //       formData1.append(`reels_videos[${index}]`, video);
  //     });
  //     // if (selectedFile5) {
  //     //   formData1.append("reels_thumbnail", selectedFile5);
  //     // }

  //     axios
  //       .post(`${process.env.REACT_APP_Baseurl}/admin/video-reels`, formData1, {
  //         headers: headers,
  //       })
  //       .then((response) => {})
  //       .catch((error) => {
  //         console.error("Error uploading file(s):", error);
  //         // Handle any errors that occurred during the upload process.
  //       });

  //     const formDatasubtitles = new FormData();

  //     Object.keys(videosubtitles).forEach((language, index) => {
  //       formDatasubtitles.append(
  //         `short_code[${index}]`,
  //         Subtitlesmap[index].short_code
  //       );
  //       formDatasubtitles.append(
  //         `language[${index}]`,
  //         Subtitlesmap[index].language
  //       );
  //       videosubtitles[language].forEach((file) => {
  //         formDatasubtitles.append(`subtitles[${index}]`, file);
  //       });
  //     });
  //     axios
  //       .post(
  //         `${process.env.REACT_APP_Baseurl}/admin/videos/subtitles`,
  //         formDatasubtitles,
  //         {
  //           headers: headers,
  //         }
  //       )
  //       .then((response) => {})
  //       .catch((error) => {
  //         // console.error("Error uploading file(s):", error);
  //         // Handle any errors that occurred during the upload process.
  //       });

  //     const formDatareel = new FormData();

  //     formDatareel.append("video_id", id);
  //     // formData1.append("reels_videos", reelvideo);
  //     if (selectedFile5) {
  //       formDatareel.append("reels_thumbnail", selectedFile5);
  //     }

  //     axios
  //       .post(
  //         `${process.env.REACT_APP_Baseurl}/admin/video-reels-thumbnail`,
  //         formDatareel,
  //         { headers: headers }
  //       )
  //       .then((response) => {
  //         // console.log("File(s) uploaded successfully:", response?.message);
  //         // Perform any actions with the response data as needed.
  //         //       // navigate('/all-artist')
  //         // navigate("admin/allvideo");
  //         // alert(response?.data.message + "!");
  //       })
  //       .catch((error) => {
  //         console.error("Error uploading file(s):", error);
  //         // Handle any errors that occurred during the upload process.
  //       });

  //     const formDatass = new FormData();

  //     formDatass.append("video_id", id);
  //     formDatass.append("trailer", editUser?.embed_code);
  //     formDatass.append("trailer", editUser?.m3u8_url);
  //     formDatass.append("trailer", editUser?.mp4_url);
  //     formDatass.append("trailer_type", trailer_type);

  //     axios
  //       .post(
  //         `${process.env.REACT_APP_Baseurl}/admin/video-Trailer-url`,
  //         formDatass,
  //         { headers: headers }
  //       )
  //       .then((response) => {
  //         // console.log('api' , response);
  //         if (response?.data.status == true) {
  //           var result = response?.data;
  //           // console.log('result', response);
  //           // navigate('/all-artist')
  //           // alert("Added successfully  !")
  //         }
  //       });

  //     const formD = new FormData();

  //     formD.append("video_id", id);
  //     formD.append("trailer", selectedFiles);

  //     axios
  //       .post(
  //         `${process.env.REACT_APP_Baseurl}/admin/video-Trailer-video`,
  //         formD,
  //         { headers: headers }
  //       )
  //       .then((response) => {
  //         // console.log('api' , response);
  //         if (response?.data.status == true) {
  //           var result = response?.data;
  //           // console.log('result', response);
  //           // navigate('/all-artist')
  //         }
  //       });

  //     const formDataseo = new FormData();
  //     formDataseo.append("video_id", id);
  //     formDataseo.append("website_page_title", editUser?.website_page_title);
  //     formDataseo.append("website_URL", editUser?.website_URL);
  //     formDataseo.append("Meta_description", editUser?.Meta_description);

  //     axios
  //       .post(
  //         `${process.env.REACT_APP_Baseurl}/admin/videos-SEO`,
  //         formDataseo,
  //         { headers: headers }
  //       )
  //       .then((response) => {
  //         // console.log("File(s) uploaded successfully:", response?.data);
  //       });

  //     const formDa = new FormData();

  //     formDa.append("video_id", id);
  //     formDa.append("pdf_file", pdf_files);

  //     axios
  //       .post(`${process.env.REACT_APP_Baseurl}/admin/Epaper-video`, formDa, {
  //         headers: headers,
  //       })
  //       .then((response) => {
  //         // console.log('api' , response);
  //         if (response?.data.status == true) {
  //           var result = response?.data;
  //           // console.log('result', response);
  //           // navigate('/all-artist')
  //         }
  //       });

  //     const formData = new FormData();
  //     formData?.append("video_id", id);
  //     if (selectedFile1) {
  //       formData?.append("image", selectedFile1);
  //     }
  //     if (selectedFile2) {
  //       formData?.append("player_image", selectedFile2);
  //     }
  //     if (selectedFile3) {
  //       formData?.append("video_tv_image", selectedFile3);
  //     }
  //     if (selectedFile4) {
  //       formData?.append("video_title_image", selectedFile4);
  //     }

  //     axios
  //       .post(
  //         `${process.env.REACT_APP_Baseurl}/admin/Image-upload-video`,
  //         formData,
  //         { headers: headers }
  //       )
  //       .then((response) => {
  //         // console.log("File(s) uploaded successfully:", response?.message);
  //         // Perform any actions with the response data as needed.
  //         //       // navigate('/all-artist')
  //         // navigate("/allvideo");
  //         // alert(response?.data.message + "!");
  //       });

  //     // alert("Added successfully !");
  //     setValidationMessagetitle("");
  //     setValidationMessageslug("");

  //     setValidationMessagecategory("");
  //     setValidationMessagelanguages("");

  //     props?.setApiresponsealert(videosvideos);
  //     props?.setShowalert(true);

  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 6000);
  //   } else {
  //     if (resjson.status === false) {
  //       var resultError = resjson;
  //       props?.setApiresponsealert(resultError);
  //       props?.setShowalert(true);
  //       // console.log(resultError)

  //       setIsValidationHiddentitle(false);
  //       setValidationMessageslug(false);

  //       setIsValidationHiddencategory(false);
  //       setIsValidationHiddenlanguages(false);
  //     }
  //   }
  // };


  const [validationMessageAccess, setValidationMessageAccess] =
    useState("");
  const [isValidationHiddenAccess, setIsValidationHiddenAccess] =
    useState(true);

  const [validationMessagefree_duration_time, setValidationMessagefree_duration_time] = useState('');
  const [isValidationHiddenfree_duration_time, setIsValidationHiddenfree_duration_time] = useState(true);

  const free_duration_timeInputRef = useRef(null);

  const handleInputdatatime = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });
  };

  const handleInputenabletime = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.checked ? 1 : 0;

    setEditUser({
      ...editUser,
      [inputName]: inputValue,
    });
  };


  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();

    let focusInputRef = null;

    // if (editUser?.title === "") {
    //   setValidationMessagetitle("Title cannot be empty.");
    //   setIsValidationHiddentitle(false);
    //   focusInputRef = titleInputRef;
    // } else if (
    //   !selectedValuescategories ||
    //   selectedValuescategories?.length === 0
    // ) {
    //   setValidationMessagecategory("Please select at least one Category.");
    //   setIsValidationHiddencategory(false);
    //   focusInputRef = categoryInputRef;
    // } else if (
    //   !selectedValueslanguages ||
    //   selectedValueslanguages?.length === 0
    // ) {
    //   setValidationMessagelanguages("Please select at least one Language.");
    //   setIsValidationHiddenlanguages(false);
    //   focusInputRef = languageInputRef;
    // }

    if (editUser?.title === "") {
      setValidationMessagetitle("Title cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (!selectedValuescategories || selectedValuescategories?.length === 0) {
      setValidationMessagecategory("Please select at least one Category.");
      setIsValidationHiddencategory(false);
      focusInputRef = categoryInputRef;
    }
    // if (!selectedValueslanguages || selectedValueslanguages?.length === 0) {
    //   setValidationMessagelanguages("Please select at least one Language.");
    //   setIsValidationHiddenlanguages(false);
    //   focusInputRef = languageInputRef;
    // }
    if (editUser?.user_access === "") {
      setValidationMessageAccess("Please select user access.");
      setIsValidationHiddenAccess(false);
      focusInputRef = accessInputRef;
    }

    // Reset validation messages
    setValidationMessagefree_duration_time("");
    setIsValidationHiddenfree_duration_time(true);

    if (editUser?.free_duration_status === 1) {
      // Validate free_duration_time only if free_duration_status is active (1)
      if (!editUser?.free_duration_time) {
        setValidationMessagefree_duration_time("If Free Duration Status is active, Free Duration Time is mandatory.");
        setIsValidationHiddenfree_duration_time(false);
        focusInputRef = free_duration_timeInputRef;
        return;
      }

      // Additional validation for free_duration_time format can be added here if needed
      // e.g., regex check for HH:MM:SS format
    }

    let valid = true;

    if (compressResolutionFormat?.videos === 1) {
      if (!selectedFile1 && !editUser?.Thumbnail) {
        setError(prevErrors => ({ ...prevErrors, file1: 'Image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }

      if (!selectedFile2 && !editUser?.Player_thumbnail) {
        setError(prevErrors => ({ ...prevErrors, file2: 'Player image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }

      if (!selectedFile3 && !editUser?.TV_Thumbnail) {
        setError(prevErrors => ({ ...prevErrors, file3: 'Video TV image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }

      if (!selectedFile4 && !editUser?.Video_Title_Thumbnail) {
        setError(prevErrors => ({ ...prevErrors, file4: 'Video title image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }

      if (!selectedFile5 && !editUser?.Video_reels_Thumbnail) {
        setError(prevErrors => ({ ...prevErrors, file5: 'Video title image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }
    }

    if (!valid) {
      return;
    }

    if (focusInputRef) {
      focusInputRef?.current?.focus();
      return;
    }

    const updatedTagsString = tags?.join(", ");

    const editInputvalue = {
      title: editUser?.title,
      type: editUser?.type,
      slug: editUser?.slug,
      video_id: editUser?.id,
      active: editUser?.active,
      user_id: editUser?.user_id,
      status: editUser?.status,
      draft: editUser?.draft,
      rating: editUser?.rating,
      duration: editUser?.duration,
      details: editUser?.details,
      description: editUser?.description,
      image: editUser?.image,
      mobile_image: editUser?.mobile_image,
      tablet_image: editUser?.tablet_image,
      player_image: editUser?.player_image,
      video_tv_image: editUser?.video_tv_image,
      enable_video_title_image: editUser?.enable_video_title_image,
      video_title_image: editUser?.video_title_image,
      android_tv: editUser?.android_tv,
      video_gif: editUser?.video_gif,
      search_tags: updatedTagsString,
      access: editUser?.access,
      global_ppv: checkboxChecked === true ? 1 : 0 || editUser?.global_ppv,
      ppv_price: editUser?.ppv_price,
      ios_ppv_price: editUser?.ios_ppv_price,
      featured: editUser?.featured,
      banner: editUser?.banner,
      enable: editUser?.enable,
      embed_code: editUser?.embed_code,
      mp4_url: editUser?.mp4_url,
      m3u8_url: editUser?.m3u8_url,
      webm_url: editUser?.webm_url,
      ogg_url: editUser?.ogg_url,
      disk: editUser?.disk,
      original_name: editUser?.original_name,
      path: editUser?.path,
      stream_path: editUser?.stream_path,
      processed_low: editUser?.processed_low,
      converted_for_streaming_at: editUser?.converted_for_streaming_at,
      views: editUser?.views,
      year: editUser?.year,
      age_restrict: editUser?.age_restrict,
      Recommendation: editUser?.Recommendation,
      country: editUser?.country,
      publish_status: editUser?.publish_status,
      publish_type: publishType,
      publish_time: publishTime,
      skip_start_time: editUser?.skip_start_time,
      skip_end_time: editUser?.skip_end_time,
      skip_start_session: editUser?.skip_start_session,
      recap_start_time: editUser?.recap_start_time,
      recap_end_time: editUser?.recap_end_time,
      recap_start_session: editUser?.recap_start_session,
      pdf_files: editUser?.pdf_files,
      reels_thumbnail: editUser?.reels_thumbnail,
      reelvideo: editUser?.reelvideo,
      url: editUser?.url,
      url_link: editUser?.url_link,
      url_linktym: editUser?.url_linktym,
      url_linksec: editUser?.url_linksec,
      urlEnd_linksec: editUser?.urlEnd_linksec,
      trailer_type: editUser?.trailer_type,
      trailer: editUser?.trailer,
      trailer_description: editUser?.trailer_description,
      ads_tag_url_id: editUser?.ads_status,
      ads_status: editUser?.ads_status,
      default_ads: editUser?.default_ads,
      pre_ads_category: editUser?.pre_ads_category,
      mid_ads_category: editUser?.mid_ads_category,
      post_ads_category: editUser?.post_ads_category,
      pre_ads: editUser?.pre_ads,
      mid_ads: editUser?.mid_ads,
      post_ads: editUser?.post_ads,
      time_zone: editUser?.time_zone,
      home_genre: editUser?.home_genre,
      in_menu: editUser?.in_menu,
      footer: editUser?.footer,
      uploaded_by: editUser?.uploaded_by,
      language: editUser?.language,
      free_duration_status: editUser?.free_duration_status,
      free_duration_time: editUser?.free_duration_time,
      video_category_id: editUser?.video_category_id,
      Thumbnail: editUser?.Thumbnail,
      Player_thumbnail: editUser?.Player_thumbnail,
      TV_Thumbnail: editUser?.TV_Thumbnail,
      draft: editUser?.draft,
      Video_Title_Thumbnail: editUser?.Video_Title_Thumbnail,
      Video_reels_Thumbnail: editUser?.Video_reels_Thumbnail,
      Video_reels_video_url: editUser?.Video_reels_video_url,
      Video_upload_url: editUser?.Video_upload_url,
      languages_id: selectedValueslanguages?.map((option) => option?.value),
      artist_id: selectedValuesartists?.map((option) => option?.value),
      block_country_id: selectedValues?.map((option) => option?.value),
      category_id: selectedValuescategories?.map((option) => option?.value),
      available_countries_id: selectedValuesavailable_country?.map(
        (option) => option.value
      ),
      // related_videos_title: selectedValuesrelated_video?.map(
      //   (option) => option?.value
      // ),
      related_videos: selectedValuesrelated_video?.map(
        (option) => option?.value
      ),
      video_playlist_id: selectedValuesplaylistvideo?.map(
        (option) => option?.value
      ),
      // available_countries_id: selectedValuesavailable_country?.map(
      //   (option) => option.value
      // ),
      ...formData,
      ...formDatapublishedit,
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let thirdApiStatus = false;
    let fourthApiStatus = false;
    let fifthApiStatus = false;
    let sixthApiStatus = false;
    let seventhApiStatus = false;
    let eighthApiStatus = false;
    let ninethApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/videos-update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;

        resultapi = resjson;

        const formData = new FormData();

        formData?.append("video_id", id);
        if (selectedFile1) {
          formData?.append("image", selectedFile1);
        }
        if (selectedFile2) {
          formData?.append("player_image", selectedFile2);
        }
        if (selectedFile3) {
          formData?.append("video_tv_image", selectedFile3);
        }
        if (selectedFile4) {
          formData?.append("video_title_image", selectedFile4);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-video`,
          formData,
          { headers: headers }
        );

        const formData1 = new FormData();

        // videosreels?.forEach((video, index) => {
        //   formData1.append(`reels_videos[${index}]`, video);
        // });

        let indexUsed = false; // Flag to track if index has been used for videosreels

        if (videosreels && videosreels.length > 0) {
          videosreels.forEach((video, index) => {
            if (video && !indexUsed) {
              formData1.append(`reels_videos[${index}]`, video);
              indexUsed = true;
            }
          });
        } else if (reelsvideoget && reelsvideoget.length > 0) {
          await Promise.all(reelsvideoget.map(async (videosurl) => {
            if (videosurl?.reels_videos_url) {
              const response = await fetch(videosurl?.reels_videos_url);
              const blob = await response?.blob();
              formData1.append(`reels_videos[${videosurl?.id}]`, blob, videosurl?.reels_videos_url);
            }
          }));
        }



        formData1.append("video_id", id);

        const formData1response = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/video-reels`,
          formData1,
          { headers: headers }
        );

        const formDatasubtitles = new FormData();
        formDatasubtitles.append("video_id", id);

        // Object.keys(videosubtitles)?.forEach((language, index) => {
        //   formDatasubtitles.append(
        //     `short_code[${index}]`,
        //     Subtitlesmap[index]?.short_code
        //   );
        //   formDatasubtitles.append(
        //     `language[${index}]`,
        //     Subtitlesmap[index]?.language
        //   );
        //   videosubtitles[language]?.forEach((file) => {
        //     formDatasubtitles.append(`subtitles[${index}]`, file);
        //   });
        // });

        if (videosubtitles && Object.keys(videosubtitles).length > 0) {
          // Process and send newly selected subtitle files
          Object.keys(videosubtitles).forEach((language, index) => {
            formDatasubtitles.append(`short_code[${index}]`, Subtitlesmap[index]?.short_code);
            formDatasubtitles.append(`language[${index}]`, Subtitlesmap[index]?.language);
            videosubtitles[language]?.forEach((file) => {
              formDatasubtitles.append(`subtitles[${index}]`, file);
            });
          });
        } else if (Subtitlesmap && Subtitlesmap.length > 0) {
          // Process and send existing subtitle data from Subtitlesmap if no new files are selected
          await Promise.all(Subtitlesmap.map(async (videosurl, index) => {
            if (videosurl?.subtitle_url) {
              const response = await fetch(videosurl.subtitle_url);
              const blob = await response?.blob();
              formDatasubtitles.append(`subtitles[${index}]`, blob, videosurl.subtitle_url);
              formDatasubtitles.append(`short_code[${index}]`, videosurl.short_code);
              formDatasubtitles.append(`language[${index}]`, videosurl.language);
            }
          }));
        }

        const formDatasubtitlesresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/videos/subtitles`,
          formDatasubtitles,
          { headers: headers }
        );

        const formDatareel = new FormData();

        formDatareel.append("video_id", id);
        // formData1.append("reels_videos", reelvideo);
        // if (selectedFile5) {
        //   formDatareel.append("reels_thumbnail", !selectedFile5 && !editUser?.Video_reels_Thumbnail);
        // }

        if (selectedFile5) {
          // Append the selected file
          formDatareel.append("reels_thumbnail", selectedFile5);
        } else if (editUser?.Video_reels_Thumbnail) {
          // Fetch the existing file and append it as a Blob
          const response = await fetch(editUser?.Video_reels_Thumbnail);
          const blob = await response?.blob();
          formDatareel.append("reels_thumbnail", blob, 'thumbnail.jpg'); // Specify the filename
        }

        const formDatareelresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/video-reels-thumbnail`,
          formDatareel,
          { headers: headers }
        );

        const formDaEpaper = new FormData();

        formDaEpaper.append("video_id", id);
        // formDaEpaper.append("pdf_file", pdf_files);

        if (pdf_files) {
          // Append the selected PDF file
          formDatareel.append("pdf_file", pdf_files);
        } else if (editUser?.EPaper) {
          // Fetch the existing PDF file and append it as a Blob
          const response = await fetch(editUser?.EPaper);
          const blob = await response?.blob();
          formDatareel.append("pdf_file", blob, 'document.pdf'); // Specify the filename
        }


        const formDaEpaperresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/Epaper-video`,
          formDaEpaper,
          { headers: headers }
        );

        const formDataseo = new FormData();
        formDataseo.append("video_id", id);
        formDataseo.append("website_page_title", editUser?.website_page_title);
        formDataseo.append("website_URL", editUser?.website_URL);
        formDataseo.append("Meta_description", editUser?.Meta_description);

        const formDataseoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/videos-SEO`,
          formDataseo,
          { headers: headers }
        );

        const formDatatrailerurl = new FormData();

        formDatatrailerurl.append("video_id", id);

        formDatatrailerurl.append("mp4_url", editUser?.trailer);
        formDatatrailerurl.append("m3u8_trailer", editUser?.trailer);
        formDatatrailerurl.append("embed_url", editUser?.trailer);
        formDatatrailerurl.append("trailer_type", editUser?.trailer_type);

        const formDatatrailerurlresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/video-Trailer-url`,
          formDatatrailerurl,
          { headers: headers }
        );

        const formDtrailervideo = new FormData();

        formDtrailervideo.append("video_id", id);
        // formDtrailervideo.append("trailer", selectedFiles);
        if (selectedFiles) {
          // Append the selected file
          formDtrailervideo.append("trailer", selectedFiles);
        } else if (editUser?.trailer_videos_url) {
          // Fetch the existing file and append it as a Blob
          const response = await fetch(editUser?.trailer_videos_url);
          const blob = await response?.blob();
          formDtrailervideo.append("trailer", blob, 'trailer_videos_url.mp4'); // Specify the filename
        }
        formDtrailervideo.append("trailer", editUser?.trailer_videos_url);
        // formDtrailervideo.append(
        //   "trailer_filename",
        //   editUser?.trailer_filename
        // );

        const formDtrailervideoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/video-Trailer-video`,
          formDtrailervideo,
          { headers: headers }
        );

        if (imageResponse?.data?.status === true) {
          secondApiStatus = true;
        }
        if (formData1response?.data?.status === true) {
          thirdApiStatus = true;
        }
        if (formDatasubtitlesresponse?.data?.status === true) {
          fourthApiStatus = true;
        }
        if (formDatareelresponse?.data?.status === true) {
          fifthApiStatus = true;
        }
        if (formDaEpaperresponse?.data?.status === true) {
          sixthApiStatus = true;
        }
        if (formDataseoresponse?.data?.status === true) {
          seventhApiStatus = true;
        }
        if (formDatatrailerurlresponse?.data?.status === true) {
          eighthApiStatus = true;
        }
        if (formDtrailervideoresponse?.data?.status === true) {
          ninethApiStatus = true;
        }
        // secondApiStatus = true;
      } else if (resjson?.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (
      firstApiStatus &&
      secondApiStatus &&
      thirdApiStatus &&
      fourthApiStatus &&
      fifthApiStatus &&
      sixthApiStatus &&
      seventhApiStatus &&
      eighthApiStatus &&
      ninethApiStatus
    ) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else if (resultapifalse) {
      // Check if resultapifalse is defined and not null
      props?.setApiresponsealert(resultapifalse);
      console.log(resultapifalse);
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddentitle(false);
      setValidationMessageslug(false);
    }
  };

  const deleteOperationepaper = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/admin/videos/EPaper-delete/` + id,
        { headers: headers }
      )
      .then((response) => {
        if (response?.data?.status === true) {
          var responseData = response?.data;
          props?.setApiresponsealert(responseData);
          props?.setShowalert(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          if (responseData === false) {
            var resultError = responseData;
            props?.setApiresponsealert(resultError);
            props?.setShowalert(true);
          }
        }
      });
  };

  const deleteOperationsubtitle = (subtitles_id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/admin/videos/subtitles-delete/${subtitles_id}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response?.data?.status === true) {
          var responseData = response?.data;
          props?.setApiresponsealert(responseData);
          props?.setShowalert(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          if (responseData === false) {
            var resultError = responseData;
            props?.setApiresponsealert(resultError);
            props?.setShowalert(true);
          }
        }
      });
  };
  const deleteOperationreels = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/admin/videos/Reels-delete/` + id,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response?.data?.status === true) {
          var responseData = response?.data;
          props?.setApiresponsealert(responseData);
          props?.setShowalert(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          if (responseData === false) {
            var resultError = responseData;
            props?.setApiresponsealert(resultError);
            props?.setShowalert(true);
          }
        }
      });
  };
  const deleteOperationtrailer = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/admin/videos/Trailer-delete/` + id,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response?.data?.status === true) {
          var responseData = response?.data;
          props?.setApiresponsealert(responseData);
          props?.setShowalert(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          if (responseData === false) {
            var resultError = responseData;
            props?.setApiresponsealert(resultError);
            props?.setShowalert(true);
          }
        }
      });
  };



  // {ppv price with global and customs} 
  // {ppv price with global and customs} 

  // console.log(allCurrencyglobal, "allCurrencyglobal");
  // console.log(allCurrencylistfromappi, "allCurrencylistfromappi");
  // console.log(allCurrencycustoms, "allCurrencycustoms");


  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [step, setStep] = useState(1);
  const [formDataglobalprice, setFormDataglobalprice] = useState({
    priceType: allCurrencylistfromappichoosed?.code,  // Default value for global price
    price: ppv_gobal_price,
    convertedPrices: []
  });
  const [formDatacustomprice, setFormDatacustomprice] = useState({
    priceType: allCurrencylistfromappichoosed?.code,  // Default value for custom price
    price: editUser?.ppv_price,
    convertedPrices: []
  });



  const handleChange = (e) => {
    const { value } = e.target;
    if (value === "1") {
      setEditUser((prevState) => ({
        ...prevState,
        global_ppv: "1",
      }));
    } else if (value === "2") {
      setEditUser((prevState) => ({
        ...prevState,
        global_ppv: null,
      }));
    }

    setModalType(e.target.value);
    setShowModal(true);
    setStep(1);
  };

  const handleClose = () => {
    setShowModal(false);
    setFormDataglobalprice({
      priceType: allCurrencylistfromappichoosed?.code,
      price: ppv_gobal_price,
      convertedPrices: []
    });
    setFormDatacustomprice({
      priceType: allCurrencylistfromappichoosed?.code,
      price: editUser?.ppv_price,
      convertedPrices: []
    });
  };


  const handleNext = async () => {
    if (step === 1) {
      const formData = modalType === '1' ? formDataglobalprice : formDatacustomprice;

      if (!formData?.priceType || !formData?.price) {
        toast.error('Please fill in the required fields.')
        return;
      }

      try {
        if (modalType === '2' && formData?.price !== editUser?.ppv_price) {
          const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${formData?.priceType}`);
          const rates = response?.data.rates;

          const convertedPrices = allCurrencylistfromappi?.map(item => {
            let country_name = item?.country;
            let Symbol = item?.Symbol || item?.symbol;
            let Code = item?.code;

            const matchingCountry = allCurrencylistfromappi.find(currency => currency.code === item?.code);
            if (matchingCountry) {
              country_name = matchingCountry?.country;
              Symbol = matchingCountry?.Symbol || matchingCountry?.symbol;

            }
            return {
              Code: Code,
              country_name: country_name,
              Symbol: Symbol,
              convertedPrice: (formData?.price * (rates[item?.code] || 0)).toFixed(2),
              price: formData?.price,
              difference: ((formData?.price * (rates[item?.code] || 0)) - formData?.price).toFixed(2),
              editPrice: (formData?.price * (rates[item?.code] || 0)).toFixed(2),
              isEditable: true,
            };
          });

          setFormDatacustomprice(prevState => ({
            ...prevState,
            convertedPrices: convertedPrices
          }));
        } else if (modalType === '2' && formData?.price === editUser?.ppv_price) {
          const customPrices = allCurrencycustoms?.map(item => ({
            ...item,
            convertedPrice: item?.ChangedPrice || (formData?.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
            difference: item?.RateDifference || 0,
            isEditable: true
          }));

          setFormDatacustomprice(prevState => ({
            ...prevState,
            convertedPrices: customPrices
          }));
        } else if (modalType === '1') {

          const globalPrices = allCurrencyglobal?.map(item => {
            let Symbol = item?.Symbol || item?.symbol; // Ensure Symbol is either from the item or set to an empty string

            return {
              ...item,
              convertedPrice: item?.ChangedPrice || (formData?.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
              Symbol: Symbol,  // Ensure the Symbol is set properly here
              isEditable: false
            };
          });

          // const globalPrices = allCurrencyglobal?.map(item => ({
          //   ...item,
          //   convertedPrice: item?.ChangedPrice || (formData?.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
          //   isEditable: false

          // }));
          setFormDataglobalprice(prevState => ({
            ...prevState,
            convertedPrices: globalPrices
          }));
        }

        setStep(2);
      } catch (error) {
        console.error('Error converting currency:', error);
      }
    }
  };


  const handleSubmit = async () => {
    const formData = modalType === '1' ? formDataglobalprice : formDatacustomprice;

    if (!formData || formData?.convertedPrices.length === 0) {
      alert('Please fill in all the fields.');
      return;
    }

    // Prepare data to submit
    const dataToSubmit = {
      source_type: 1, // Note - 1 (Videos) or 2 (Livestream) or 3 (Series) or 4 (Episode) or 5 (Audios) or 6 season or 7 golbal
      source_id: id,
      PPVType: modalType,
      PriceType: formData?.priceType, // Separate priceType outside of convertedPrices
      IOSPrice: editUser?.ios_ppv_price,
      ActualPrice: formData?.price,
      convertedPriceRates: formData?.convertedPrices.map(item => ({
        Code: item?.Code,
        Country: item?.country_name || item?.Country,
        RateDifference: item?.difference || item?.RateDifference,
        ConvertedPriceRate: item?.editPrice || item?.convertedPrice,
        ChangedPrice: item?.editPrice || item?.convertedPrice,
        Symbol: item?.Symbol || item?.symbol,
      }))
    };

    let firstApiStatus = false;
    let resultapi = {};
    let resjson = {};

    try {
      const res = await fetch(`${process.env.REACT_APP_Baseurl}/admin/Video/PayPerViewPrice/StoreUpdate`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(dataToSubmit),
      });

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;
        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
      // Optionally show a user-friendly error message
      alert('An error occurred while submitting the form. Please try again.');
    }

    if (firstApiStatus) {
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      handleClose();
      setStep(1);
    } else {
      if (resjson?.status === false) {
        props?.setApiresponsealert(resjson);
        props?.setShowalert(true);
      }
    }
  };


  const handleInputChangeeditable = (index, fieldName, value) => {
    const updatedConvertedPrices = [...(modalType === '1' ? formDataglobalprice.convertedPrices : formDatacustomprice.convertedPrices)];
    updatedConvertedPrices[index][fieldName] = value;
    if (modalType === '1') {
      setFormDataglobalprice(prevState => ({
        ...prevState,
        convertedPrices: updatedConvertedPrices
      }));
    } else {
      setFormDatacustomprice(prevState => ({
        ...prevState,
        convertedPrices: updatedConvertedPrices
      }));
    }
  };

  const handleCustomPriceTypeChange = async (event) => {
    const selectedCountryCode = event.target.value;

    if (selectedCountryCode && selectedCountryCode !== allCurrencylistfromappichoosed?.code) {
      try {
        // Fetch conversion rates from API
        const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${selectedCountryCode}`);
        const rates = response.data.rates;

        // Convert and update prices based on the API data
        const convertedPrices = allCurrencylistfromappi?.map(item => {
          let country_name = item?.country;
          let Symbol = item?.Symbol || item?.symbol;
          let Code = item?.code;

          // Find matching country data
          const matchingCountry = allCurrencylistfromappi.find(currency => currency.code === item?.code);
          if (matchingCountry) {
            country_name = matchingCountry?.country;
            Symbol = matchingCountry?.Symbol || matchingCountry?.symbol;
          }

          return {
            Code: Code,
            Country: country_name,
            Symbol: Symbol,
            convertedPrice: (formDatacustomprice.price * (rates[item?.code] || 0)).toFixed(2),
            price: formDatacustomprice.price,
            difference: ((formDatacustomprice.price * (rates[item?.code] || 0)) - formDatacustomprice.price).toFixed(2),
            editPrice: (formDatacustomprice.price * (rates[item?.code] || 0)).toFixed(2),
            isEditable: true,
          };
        });

        setFormDatacustomprice(prevState => ({
          ...prevState,
          priceType: selectedCountryCode,
          convertedPrices: convertedPrices
        }));
      } catch (error) {
        console.error('Error fetching currency conversion rates:', error);
      }
    } else {
      // Set the existing data if allCurrencylistfromappichoosed?.code is selected
      const customPrices = allCurrencycustoms?.map(item => ({
        ...item,
        convertedPrice: item?.ChangedPrice || (formDatacustomprice.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
        difference: item?.RateDifference || 0,
        isEditable: true
      }));

      setFormDatacustomprice(prevState => ({
        ...prevState,
        priceType: allCurrencylistfromappichoosed?.code,
        convertedPrices: customPrices
      }));
    }
  };

  const handlePriceChange = (e, formType) => {
    const { name, value } = e.target;
    if (formType === 'global') {
      setFormDataglobalprice(prevState => ({
        ...prevState,
        [name]: value
      }));
    } else if (formType === 'custom') {
      setFormDatacustomprice(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };




  // {ppv price with global and customs} 
  // {ppv price with global and customs} 



  const steps = [
    {
      label: "Video ",
      description: (
        <div className="">
          <section className="">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                <h3>
                  {label_management?.videos[73]?.title}</h3>
              </div>
              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    {label_management?.videos[72]?.title}
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>{edittitle?.title}</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      {label_management?.videos[0]?.title}<span className="mandatory">*</span>
                    </label>
                    <p className="t-1">
                      {label_management?.videos[0]?.sub_title}
                    </p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="title"
                        name="title"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={editUser?.title}
                        placeholder="Title"
                        ref={titleInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddentitle && (
                        <p>{validationMessagetitle}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">{label_management?.videos[1]?.title}</label>
                    <p className="t-1">{label_management?.videos[1]?.sub_title}</p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="slug"
                        name="slug"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={editUser?.slug}
                        placeholder="slug"
                      />
                    </div>
                    {/* <span className="errorred">
                      {!isValidationHiddenslug && (
                        <p>{validationMessageslug}</p>
                      )}
                    </span> */}
                  </div>
                  <div className="mt-2 text-start">

                    <label className="m-0">{label_management?.videos[2]?.title}</label>
                    <p className="t-1">{label_management?.videos[2]?.sub_title}</p>
                    <div className="mt-2">
                      <textarea
                        className="rs-input short-description"
                        id="description"
                        name="description"
                        onChange={handleInput}
                        value={editUser?.description}
                        placeholder="Description"
                      />
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">

                      <label className="m-0">{label_management?.videos[3]?.title}</label>
                      <p className="t-1">{label_management?.videos[3]?.sub_title}</p>
                      <div className="mt-2">
                        <JoditEditor
                          className="rs-input"
                          id="details"
                          value={editUser?.details}
                          onChange={(e) => {
                            handleChangeFirst(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">{label_management?.videos[4]?.title}</label>
                      <p className="t-1">{label_management?.videos[4]?.sub_title}</p>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="duration"
                          id="duration"
                          className="rs-input form-control-lg"
                          value={editUser?.duration}
                          maxLength="8"
                          placeholder="HH:MM:SS"
                          onChange={handleInputdata}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">Free Duration Time</label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="free_duration_time"
                          className="rs-input form-control-lg"
                          value={editUser?.free_duration_time}
                          maxLength="8"
                          placeholder="HH:MM:SS"
                          onChange={handleInputdata}
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">{label_management?.videos[5]?.title}</label>
                      <p className="t-1">{label_management?.videos[5]?.sub_title}</p>
                      <div className="mt-2">
                        <input
                          type="date"
                          name="date"
                          id="date"
                          value={editUser?.year || ''}
                          onChange={handleChangeyear}
                          className="rs-input form-control-lg"
                          placeholder="Year"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                  <div className="card p-3">
                    <h5>{label_management?.videos[6]?.title}</h5>
                    <hr></hr>

                    <div className=" text-start">
                      <label className="m-0">{label_management?.videos[7]?.title}</label>
                      <p className="t-1">{label_management?.videos[7]?.sub_title}</p>
                      <div className="mt-2">
                        <select
                          className="form-select"
                          id="rating"
                          name="rating"
                          size="lg"
                          onChange={handleInput}
                          value={editUser?.rating}
                        >
                          {/* <option value="0">0</option> */}
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                    </div>
                    <div className="mt-2 text-start">
                      <label className="m-0">{label_management?.videos[8]?.title}</label>
                      <p className="t-1">{label_management?.videos[8]?.sub_title}</p>
                      <div className="mt-2">
                        <select
                          className="form-select"
                          id="age_restrict"
                          name="age_restrict"
                          size="lg"
                          onChange={handleInput}
                          value={editUser?.age_restrict}
                        >
                          <option value="">Choose an Age</option>
                          {age_restrictdata?.map((item) => (
                            <option value={item?.age}>{item?.slug}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {/* <div className="mt-2 text-start">
                      <label className="m-0">Album</label>
                      <p className="t-1">Select A Audio Album Below:</p>
                      <div className="mt-2">
                        <select
                          className="rs-input form-control-lg "
                          onChange={(e) => setAlbum_id(e.target.value)}
                        >
                          <option value="">Choose a Album</option>
                          {album?.map((item, key) => (
                            <option value={item?.id}>{item?.albumname}</option>
                          ))}
                        </select>
                      </div>
                    </div> */}

                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.videos[9]?.title}</label>
                      <p className="t-1">{label_management?.videos[9]?.sub_title}</p>

                      <div className="mt-2">
                        <Select
                          options={optionsartists}
                          isMulti
                          className=""
                          id="artists"
                          onChange={handleSelectChangeartists}
                          value={selectedValuesartists}
                        />
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.videos[10]?.title}<span className="mandatory">*</span></label>
                      <p className="t-1">{label_management?.videos[10]?.sub_title}</p>
                      <div className="mt-2">
                        <Select
                          options={optionscategories}
                          isMulti
                          className=""
                          id="category"
                          onChange={handleSelectChangecategories}
                          value={selectedValuescategories}
                          ref={categoryInputRef}
                        />
                      </div>
                      <span className="errorred">
                        {!isValidationHiddencategory && (
                          <p>{validationMessagecategory}</p>
                        )}
                      </span>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.videos[11]?.title}</label>
                      <p className="t-1">{label_management?.videos[11]?.sub_title}</p>
                      <div className="text-dark">
                        <Select
                          options={optionslanguages}
                          isMulti
                          className=""
                          id="languages"
                          onChange={handleSelectChangelanguages}
                          value={selectedValueslanguages}
                          ref={languageInputRef}
                        />
                      </div>
                      <span className="errorred">
                        {!isValidationHiddenlanguages && (
                          <p>{validationMessagelanguages}</p>
                        )}
                      </span>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.videos[12]?.title}</label>
                      <p className="t-1">{label_management?.videos[12]?.sub_title}</p>
                      <div className="text-dark">
                        <Select
                          options={options}
                          isMulti
                          className=""
                          id="countryblock_country"
                          onChange={handleSelectChange}
                          value={selectedValues}
                        />
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.videos[13]?.title}</label>
                      <p className="t-1">{label_management?.videos[13]?.sub_title}</p>
                      <div className="text-dark">
                        <Select
                          options={optionsavailable_country}
                          isMulti
                          className=""
                          id="available_country"
                          onChange={handleSelectChangeavailable_country}
                          value={selectedValuesavailable_country}
                        />
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.videos[14]?.title}</label>
                      <p className="t-1">{label_management?.videos[14]?.sub_title}</p>
                      <div className="text-dark">
                        <Select
                          options={optionsrelated_video}
                          isMulti
                          className=""
                          id="related_videos"
                          onChange={handleSelectChangerelated_video}
                          value={selectedValuesrelated_video}
                        />
                      </div>
                    </div>
                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.videos[15]?.title}</label>
                      <p className="t-1">{label_management?.videos[15]?.sub_title}</p>
                      <div className="text-dark">
                        <Select
                          options={optionsplaylistvideo}
                          isMulti
                          className=""
                          id="video_playlist"
                          onChange={handleSelectChangeplaylistvideo}
                          value={selectedValuesplaylistvideo}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                  <div className="card p-3">
                    <h5>
                      {label_management?.videos[16]?.title} <small>{label_management?.videos[61]?.title}</small>
                    </h5>
                    <hr></hr>

                    <div className="col-lg-12 row">
                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0">
                            {label_management?.videos[17]?.title}
                            <small>  {label_management?.videos[17]?.sub_title}</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              className="rs-input form-control-lg "
                              name="skip_start_time"
                              id="skip_start_time"
                              maxLength="8"
                              placeholder="HH:MM:SS"
                              value={editUser?.skip_start_time}
                              onChange={handleInputdata}
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_skip_start_time}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0">
                            {label_management?.videos[18]?.title}
                            <small>  {label_management?.videos[18]?.sub_title}</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={editUser?.recap_start_time}
                              onChange={handleInputdata}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg "
                              name="recap_start_time"
                              id="recap_start_time"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_recap_start_time}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 row mt-2">
                      <div className="col-lg-6">
                        {" "}
                        <div className="text-start">
                          <label className="m-0">
                            {label_management?.videos[19]?.title}
                            <small>  {label_management?.videos[19]?.sub_title}</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={editUser?.skip_end_time}
                              onChange={handleInputdata}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg "
                              name="skip_end_time"
                              id="skip_end_time"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_skip_end_time}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0">
                            {label_management?.videos[20]?.title}
                            <small>  {label_management?.videos[20]?.sub_title}</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={editUser?.recap_end_time}
                              onChange={handleInputdata}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg "
                              name="recap_end_time"
                              id="recap_end_time"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_recap_end_time}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 row mt-2">
                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0">
                            {label_management?.videos[21]?.title}
                            <small>  {label_management?.videos[21]?.sub_title}</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={editUser?.skip_start_session}
                              onChange={handleInputdata}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg "
                              name="skip_start_session"
                              id="skip_start_session"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_skip_start_session}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0">
                            {label_management?.videos[22]?.title}
                            <small>  {label_management?.videos[22]?.sub_title}</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={editUser?.recap_start_session}
                              onChange={handleInputdata}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg "
                              name="recap_start_session"
                              id="recap_start_session"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_recap_start_session}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>{label_management?.videos[23]?.title}</h5>
                  <hr></hr>

                  <div className=" text-start">
                    <label className="m-0">{label_management?.videos[24]?.title}</label>
                    <p className="t-1">{label_management?.videos[24]?.sub_title}</p>

                    {/* <div className="row">
                      <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                        <Link to={editUser?.EPaper} target="_blank" download>
                          <div className="text-center file-e-paper p-2 mt-2">
                            <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                            <div>Download</div>
                          </div>
                        </Link>
                      </div>
                    </div> */}

                    <div className="row">
                      {editUser?.EPaper ? (
                        <>
                          <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                            <Link
                              to={editUser?.EPaper}
                              target="_blank"
                              download
                            >
                              <div className="text-center file-e-paper p-2 mt-2">
                                <i
                                  className="fa fa-file-pdf-o"
                                  aria-hidden="true"
                                ></i>
                                <div>Download</div>
                              </div>
                            </Link>
                          </div>
                          <div className="col-5 col-sm-5 col-md-5 col-lg-5  align-self-center">
                            <Link to="" className="delete ms-1">
                              <span onClick={() => deleteOperationepaper(id)}>
                                <img
                                  src={deleteitem}
                                  alt="flogo"
                                  width={20}
                                  height={20}
                                />
                              </span>
                            </Link>
                          </div>
                        </>
                      ) : null}
                    </div>

                    <div className="mt-3">
                      <input
                        type="file"
                        className="form-control-lg rs-input"
                        name="pdf_file"
                        id="pdf_file"
                        accept="application/pdf"
                        onChange={(e) => setPdf_files(e.target.files[0])}
                        multiple
                      />
                    </div>
                  </div>

                  <div className=" text-start mt-2">

                    <label className="m-0">{label_management?.videos[25]?.title}</label>
                    <p className="t-1">{label_management?.videos[25]?.sub_title}</p>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="form-control-lg rs-input"
                        name="url_link"
                        onChange={handleInput}
                        value={editUser?.url_link}
                        id="url_link"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 row">
                    <div className="col-lg-6">
                      <div className=" text-start mt-2">

                        <label className="m-0">{label_management?.videos[26]?.title}</label>
                        <p className="t-1">{label_management?.videos[26]?.sub_title}</p>
                        <div className="mt-2">
                          <input
                            type="text"
                            className="form-control-lg rs-input"
                            name="url_linktym"
                            maxLength="8"
                            onChange={handleInputdata}
                            value={editUser?.url_linktym}
                            id="url_linktym"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className=" text-start mt-2">

                        <label className="m-0">{label_management?.videos[27]?.title}</label>
                        <p className="t-1">{label_management?.videos[27]?.sub_title}</p>
                        <div className="mt-2">
                          <input
                            type="text"
                            className="form-control-lg rs-input"
                            name="urlEnd_linksec"
                            maxLength="8"
                            onChange={handleInputdata}
                            value={editUser?.urlEnd_linksec}
                            id="urlEnd_linksec"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className=" text-start mt-2">
                    <label className="m-0">URL Start Time Format </label>
                    <p className="t-1">(HH:MM:SS)</p>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="form-control-lg rs-input"
                        name="url_linktym"
                        maxLength="8"
                        onChange={handleInputdata}
                        value={editUser?.url_linktym}
                        id="url_linktym"
                      />
                    </div>
                  </div> */}
                </div>

                <div className="card p-3">
                  <h5>{label_management?.videos[28]?.title}</h5>
                  <hr></hr>

                  <div className="col-sm-12">
                    <label className="m-0">{label_management?.videos[29]?.title}</label>
                    <p className="t-1">{label_management?.videos[29]?.sub_title}</p>
                    <div className=" mt-2">
                      <input
                        type="text"
                        value={editUser?.website_page_title}
                        name="website_page_title"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                        id="website_page_title"
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 mt-2">
                    <label className="m-0">{label_management?.videos[30]?.title}</label>
                    <p className="t-1">{label_management?.videos[30]?.sub_title}</p>
                    <div className=" mt-2">
                      <input
                        type="text"
                        value={editUser?.website_URL}
                        name="website_URL"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                        id="website_url"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 mt-2">
                    <label className="m-0">{label_management?.videos[31]?.title}</label>
                    <p className="t-1">{label_management?.videos[31]?.sub_title}</p>
                    <div className=" mt-2">
                      <textarea
                        type="text"
                        value={editUser?.Meta_description}
                        name="Meta_description"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                        id="meta_description"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>{label_management?.videos[32]?.title}</h5>
                  <hr></hr>

                  <div className="col-sm-12">
                    <label className="m-0">{label_management?.videos[33]?.title}</label>
                    <p className="p2">{label_management?.videos[34]?.title}</p>

                    <div className="mt-2">
                      {/* <TagsInput
                        value={tags}
                        onChange={handleTagsChange}
                        inputValue={newTag}
                        onChangeInput={(tag) => setNewTag(tag)}
                      /> */}
                      <TagsInput
                        value={tags}
                        onChange={handleTagsChange}
                        inputValue={newTag}
                        id="search_tags"
                        onChangeInput={handleInputChangetag}
                        inputProps={{ onKeyPress: handleInputKeyPress }} // Adding key press event handler
                      />
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>{label_management?.videos[35]?.title}</h5>
                  <hr></hr>
                  <div className="col-sm-12 row">
                    <div className="col-9 col-sm-9 col-lg-9">
                      <p className="p2">{label_management?.videos[36]?.title}</p>
                    </div>
                    <div className="col-3 col-sm-3 col-lg-3">
                      <label className="switch">
                        <input
                          name="featured"
                          id="featured"
                          onChange={handleInputenable}
                          className="rs-input"
                          defaultChecked={
                            editUser?.featured === 1 ? true : false
                          }
                          checked={editUser?.featured === 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="featured"
                          id="featured"
                          onChange={handleInputenable}
                          value={editUser?.featured === 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-12 row mt-2">
                    <div className="col-9 col-sm-9 col-lg-9">
                      <p className="p2">{label_management?.videos[37]?.title}</p>
                    </div>
                    <div className="col-3 col-sm-3 col-lg-3">
                      <label className="switch">
                        <input
                          name="active"
                          id="active"
                          onChange={handleInputenable}
                          defaultChecked={editUser?.active === 1 ? true : false}
                          checked={editUser?.active === 1 ? true : false}
                          className="rs-input"
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="active"
                          id="active"
                          onChange={handleInputenable}
                          value={editUser?.active === 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-12 row mt-2">
                    <div className="col-9 col-sm-9 col-lg-9">
                      <p className="p2">{label_management?.videos[38]?.title}</p>
                    </div>
                    <div className="col-3 col-sm-3 col-lg-3">
                      <label className="switch">
                        <input
                          name="banner"
                          id="banner"
                          onChange={handleInputenable}
                          className="r-input"
                          defaultChecked={editUser?.banner === 1 ? true : false}
                          checked={editUser?.banner === 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="banner"
                          id="banner"
                          onChange={handleInputenable}
                          value={editUser?.banner === 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-12 row mt-2">
                    <div className="col-9 col-sm-9 col-lg-9">
                      <p className="p2">{label_management?.videos[39]?.title}</p>
                    </div>
                    <div className="col-3 col-sm-3 col-lg-3">
                      <label className="switch">
                        <input
                          name="enable_video_title_image"
                          id="enable_video_title_image"
                          onChange={handleInputenable}
                          defaultChecked={
                            editUser?.enable_video_title_image === 1
                              ? true
                              : false
                          }
                          checked={
                            editUser?.enable_video_title_image === 1
                              ? true
                              : false
                          }
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          id="enable_video_title_image"
                          name="enable_video_title_image"
                          onChange={handleInput}
                          value={
                            editUser?.enable_video_title_image === 1 ? "1" : "0"
                          }
                        ></span>
                      </label>
                    </div>
                  </div>

                </div>

                <div className="card p-3">
                  <h5>{label_management?.videos[40]?.title}</h5>
                  <hr />

                  <div className="col-sm-12">
                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">{label_management?.videos[41]?.title}</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="free_duration_status"
                            id="free_duration_status"
                            onChange={handleInputenabletime}
                            defaultChecked={editUser?.free_duration_status === 1}
                            checked={editUser?.free_duration_status === 1}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            id="free_duration_status"
                            name="free_duration_status"
                            value={editUser?.free_duration_status === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="row mt-2 text-start">
                      <div className="col-12">
                        <label className="m-0">{label_management?.videos[42]?.title}</label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="free_duration_time"
                            id="free_duration_time"
                            className="rs-input form-control-lg"
                            value={editUser?.free_duration_time}
                            maxLength="8"
                            placeholder="HH:MM:SS"
                            onChange={handleInputdatatime}
                            ref={free_duration_timeInputRef}
                          />
                          {!isValidationHiddenfree_duration_time && (
                            <div className="mandatory">
                              {validationMessagefree_duration_time}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    {label_management?.videos[72]?.title}
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                {editUser?.videos_url ? (
                  <div className="card p-3">
                    <h5>Videos {formattedDatatype}</h5>
                    <hr></hr>

                    <div className="text-start">
                      <ReactPlayer
                        url={editUser?.videos_url}
                        controls={true} // Enable player controls
                        width="100%"
                        height="230px"
                        config={{
                          file: {
                            attributes: {
                              poster: editUser?.Player_thumbnail
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                ) : null}

                <div className="card p-3">
                  <h5>{label_management?.videos[43]?.title}</h5>
                  <hr></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0">{label_management?.videos[44]?.title}
                      {compressResolutionFormat?.videos === 1 && (
                        <span className="mandatory">*</span>
                      )}
                    </label>
                    <p className="t-1">
                      {label_management?.videos[44]?.sub_title}
                    </p>

                    <div>
                      {loading ? (
                        <Response_Loading_processing></Response_Loading_processing>
                      ) : (
                        <img
                          src={editUser?.Thumbnail}
                          alt="image_all"
                          className="thumbnails-images"
                        />
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              id="video_thumbnail"
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1,
                                  'file1'
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile1)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(1)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {error?.file1 && <div className="errorred mt-1">{error?.file1}</div>}
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">{label_management?.videos[45]?.title}
                      {compressResolutionFormat?.videos === 1 && (
                        <span className="mandatory">*</span>
                      )}
                    </label>
                    <p className="t-1">
                      {label_management?.videos[45]?.sub_title}
                    </p>

                    <div>
                      {loading ? (
                        <Response_Loading_processing></Response_Loading_processing>
                      ) : (
                        <img
                          src={editUser?.Player_thumbnail}
                          alt="image_all"
                          className="thumbnails-images"
                        />
                      )}
                    </div>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef2.current.click()}
                          >
                            <input
                              type="file"
                              id="player_thumbnail"
                              ref={fileInputRef2}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef2,
                                  setSelectedFile2,
                                  'file2'
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile2 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile2)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(2)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {error?.file2 && <div className="errorred mt-1">{error?.file2}</div>}
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">{label_management?.videos[46]?.title}
                      {compressResolutionFormat?.videos === 1 && (
                        <span className="mandatory">*</span>
                      )}
                    </label>
                    <p className="t-1">
                      {label_management?.videos[46]?.sub_title}
                    </p>

                    <div>
                      {loading ? (
                        <Response_Loading_processing></Response_Loading_processing>
                      ) : (
                        <img
                          src={editUser?.TV_Thumbnail}
                          alt="image_all"
                          className="thumbnails-images"
                        />
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef3.current.click()}
                          >
                            <input
                              type="file"
                              id="video_tv_thumbnail"
                              ref={fileInputRef3}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef3,
                                  setSelectedFile3,
                                  'file3'
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile3 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile3)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(3)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {error?.file3 && <div className="errorred mt-1">{error?.file3}</div>}
                  </div>
                  <div className=" text-start mt-2">
                    <label className="m-0">{label_management?.videos[47]?.title}
                      {compressResolutionFormat?.videos === 1 && (
                        <span className="mandatory">*</span>
                      )}
                    </label>
                    <p className="t-1">
                      {label_management?.videos[47]?.sub_title}
                    </p>

                    <div>
                      {loading ? (
                        <Response_Loading_processing></Response_Loading_processing>
                      ) : (
                        <img
                          src={editUser?.Video_Title_Thumbnail}
                          alt="image_all"
                          className="thumbnails-images"
                        />
                      )}
                    </div>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef4.current.click()}
                          >
                            <input
                              type="file"
                              id="video_title_thumbnail"
                              ref={fileInputRef4}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef4,
                                  setSelectedFile4,
                                  'file4'
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile4 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile4)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(4)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {error?.file4 && <div className="errorred mt-1">{error?.file4}</div>}
                  </div>
                </div>

                <div className="card p-3">
                  <h5>{label_management?.videos[48]?.title}</h5>
                  <hr></hr>

                  <div className=" text-start AwesomeSlider">
                    <label className="m-0">
                      {label_management?.videos[49]?.title} <small> {label_management?.videos[63]?.title} </small>
                    </label>
                    <p className=" t-1">{label_management?.videos[50]?.title}</p>

                    <div className="col-lg-12 row">
                      <div className="row">
                        {/* {item?.subtitle_url ? (
                              <>
                                <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                                  <Link
                                    to={item?.subtitle_url}
                                    target="_blank"
                                    download
                                  >
                                    <div className="text-center file-e-paper p-2 mt-2">
                                      <i
                                        className="fa fa-file-pdf-o"
                                        aria-hidden="true"
                                      ></i>
                                      <div>Download</div>
                                    </div>
                                  </Link>
                                </div>
                                <div className="col-5 col-sm-5 col-md-5 col-lg-5  align-self-center">
                                  <Link to="" className="delete ms-1">
                                    <span
                                      onClick={() => deleteOperationsubtitle(item?.subtitle_id)}
                                    >
                                      <img
                                        src={deleteitem}
                                        alt="flogo"
                                        width={20}
                                        height={20}
                                      />
                                    </span>
                                  </Link>
                                </div>
                              </>
                            ) : null} */}
                      </div>
                      {/* <div className="col-lg-6">
                        {loading ? (
                          <img src={loadings} className="thumbnails-images" />
                        ) : (
                          <div>
                            {reelsvideoget?.map((item, key) => (
                              <>


                            {item ? (
                              <>
                                <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                                <media-player
                                  title={item?.reels_videos_slug}
                                  src={item?.reels_videos_url}
                                  poster=""
                                  controls
                                >
                                  <media-outlet></media-outlet>
                                </media-player>
                                </div>
                                <div className="col-5 col-sm-5 col-md-5 col-lg-5  align-self-center">
                                  <Link to="" className="delete ms-1">
                                    <span
                                      onClick={() => deleteOperationsubtitle(item?.id)}
                                    >
                                      <img
                                        src={deleteitem}
                                        alt="flogo"
                                        width={20}
                                        height={20}
                                      />
                                    </span>
                                  </Link>
                                </div>
                              </>
                            ) : null}

                                
                              </>
                            ))}
                          </div>
                        )}
                      </div> */}

                      <div>
                        {loading ? (
                          <Response_Loading_processing></Response_Loading_processing>
                        ) : (
                          <div className="col-lg-12 row">
                            {reelsvideoget?.map((item, key) => (
                              <div key={key}>
                                {item ? (
                                  <div className="col-lg-12 row">
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                      {/* Assuming this is a custom media player component */}
                                      <media-player
                                        title={item?.reels_videos_slug}
                                        src={item?.reels_videos_url}
                                        poster=""
                                        controls
                                      >
                                        <media-outlet></media-outlet>
                                      </media-player>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                      <Link className="delete ms-1">
                                        <span
                                          onClick={() =>
                                            deleteOperationreels(item?.id)
                                          }
                                        >
                                          <img
                                            src={deleteitem}
                                            alt="Delete"
                                            width={20}
                                            height={20}
                                          />
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>

                    {/* <div className="col-lg-12 row">
                      <div className="col-lg-6">
                        {reelsvideoget?.map((item, key) => (
                          <>
                            <media-player
                              title={item?.reels_videos_slug}
                              src={item?.reels_videos_url}
                              poster=""
                              controls
                            >
                              <media-outlet></media-outlet>
                            </media-player>
                          </>
                        ))}
                      </div>
                    </div> */}
                    <div className="mt-2 file-draganddrop">
                      <Dropzone
                        onDrop={handleDropreels}
                        accept="video/*"
                        multiple={true}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()} className="dropzone">
                            <input {...getInputProps()} />
                            <p className="p-2">
                              Drag and drop reels videos here
                            </p>
                            {videosreels?.length > 0 && (
                              <p>
                                {videosreels?.length}{" "}
                                {videosreels?.length === 1 ? "file" : "files"}{" "}
                                selected
                              </p>
                            )}
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">{label_management?.videos[50]?.title}
                      {compressResolutionFormat?.videos === 1 && (
                        <span className="mandatory">*</span>
                      )}
                    </label>
                    <p className="t-1">
                      {label_management?.videos[50]?.sub_title}
                    </p>

                    <div>
                      {loading ? (
                        <Response_Loading_processing></Response_Loading_processing>
                      ) : (
                        <img
                          src={editUser?.Video_reels_Thumbnail}
                          alt="image_all"
                          className="thumbnails-images"
                        />
                      )}
                    </div>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef5.current.click()}
                          >
                            <input
                              type="file"
                              id="reels_video_thumbnail"
                              ref={fileInputRef5}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef5,
                                  setSelectedFile5,
                                  'file5'
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile5 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile5)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(5)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {error?.file5 && <div className="errorred mt-1">{error?.file5}</div>}
                  </div>
                </div>

                <div className="card p-3">
                  <h5>{label_management?.videos[51]?.title}</h5>
                  <hr></hr>

                  <div className=" text-start">
                    <label className="m-0">{label_management?.videos[52]?.title}</label>
                    <p className="t-1">{label_management?.videos[52]?.sub_title}</p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-12 col-sm-12 col-lg-12">
                          <select
                            className="form-select"
                            name="trailer_type"
                            id="trailer_type"
                            onChange={handleInput}
                            value={editUser?.trailer_type}
                            onClick={(event) => {
                              setEnable_restreamtrailer(event.target.value);
                            }}
                          >
                            <option value="null">
                              {" "}
                              Select the Video Trailer Type{" "}
                            </option>
                            <option value="1">Video Upload </option>
                            <option value="m3u8_url">M3u8 Url </option>
                            <option value="mp4_url">Mp4 Url</option>
                            <option value="embed_url">Embed Code</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start">
                    <div
                      className=""
                      onClick={(event) => {
                        setEnablestreamvisibletrailer(event.target.value);
                      }}
                    >
                      {proceednexttrailer()}
                    </div>
                  </div>

                  <div className=" text-start mt-2">

                    <label className="m-0">{label_management?.videos[53]?.title}</label>
                    <p className="t-1">{label_management?.videos[53]?.sub_title}</p>
                    <div className="mt-2">
                      <JoditEditor
                        className="rs-input"
                        id="trailer_description"
                        value={editUser?.trailer_description}
                        onChange={(e) => {
                          handleChangeFirstdes(e);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>{label_management?.videos[54]?.title}</h5>
                  <hr></hr>

                  <div className="col-sm-12">
                    <label className="mb-2">{label_management?.videos[55]?.title}</label>
                    <div>
                      <input
                        type="radio"
                        id="publish_now"
                        name="publish_type"
                        value="publish_now"
                        checked={publishType === "publish_now"}
                        onChange={handlePublishTypeChange}
                      />
                      <label htmlFor="publish_now" className="mt-2 ms-1">
                        {label_management?.videos[56]?.title}
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="publish_later"
                        name="publish_type"
                        value="publish_later"
                        checked={publishType === "publish_later"}
                        onChange={handlePublishTypeChange}
                      />
                      <label htmlFor="publish_later" className="mt-2 ms-1">
                        {label_management?.videos[57]?.title}
                      </label>
                      {publishType === "publish_later" && (
                        <div className="mb-3">
                          <label className="mt-2">{label_management?.videos[64]?.title}</label>
                          <input
                            type="datetime-local"
                            className="form-control"
                            id="publish_time"
                            name="publish_time"
                            value={publishTime}
                            onChange={handlePublishTimeChange}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  {/* <div className="col-sm-12">
                    <label className="mb-2">Publish Type</label>
                    <div
                      onClick={(event) => {
                        setEnable_restreampublic(event.target.value);
                      }}
                    >
                      <input
                        type="radio"
                        id="publish_now"
                        name="publish_type"
                        onClick={(event) => {
                          setEnable_restreampublic(event.target.value);
                        }}
                        onChange={(e) => setPublish_type(e.target.value)}
                        value="publish_now"
                        checked="checked"
                      />{" "}
                      <span
                        className="mt-2"
                        onClick={(event) => {
                          setEnable_restreampublic(event.target.value);
                        }}
                        onChange={(e) => setPublish_type(e.target.value)}
                      >
                        Publish Now
                      </span>
                    </div>
                    <div
                      onClick={(event) => {
                        setEnable_restreampublic(event.target.value);
                      }}
                    >
                      <input
                        type="radio"
                        id="publish_later"
                        value="publish_later"
                        name="publish_type"
                        className="mt-2"
                        onClick={(event) => {
                          setEnable_restreampublic(event.target.value);
                        }}
                        onChange={(e) => setPublish_type(e.target.value)}
                      />{" "}
                      <span
                        value="publish_later"
                        name="publish_type"
                        onClick={(event) => {
                          setEnable_restreampublic(event.target.value);
                        }}
                        onChange={(e) => setPublish_type(e.target.value)}
                      >
                        Publish Later
                      </span>{" "}
                    </div>
                  </div>

                  <div className="col-sm-12" id="publishlater">
                    <div
                      className="mt-2"
                      onClick={(event) => {
                        setEnablestreamvisiblepublic(event.target.value);
                      }}
                    >
                      {proceednextpublic()}
                    </div>
                  </div> */}
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="card p-3">
                    <h5>{label_management?.videos[58]?.title}</h5>
                    <hr></hr>

                    <div className="col-sm-12">
                      <label className="m-0">{label_management?.videos[59]?.title}<span className="mandatory">*</span></label>
                      <p className="p1">{label_management?.videos[59]?.sub_title}</p>
                      <select
                        onChange={handleInput}
                        value={editUser?.access}
                        name="access"
                        id="access"
                        className="form-select"
                        onClick={(event) => {
                          setallliveuseraccess(event.target.value);
                        }}
                        ref={accessInputRef}
                      >
                        <option value="">Select a User Access</option>
                        {user_access?.map((item, key) => (
                          <option value={item?.role}>{item?.name}</option>
                        ))}
                      </select>
                    </div>
                    <div>{alllive_streamuseraccess()}</div>

                    <span className="errorred mt-2 d-block">
                      {!isValidationHiddenAccess && (
                        <p>{validationMessageAccess}</p>
                      )}
                    </span>
                  </div>

                  <div className="card p-3">
                    <label>
                      {label_management?.videos[60]?.title} <small>{label_management?.videos[62]?.title}</small>
                    </label>
                    <hr></hr>
                    <div className="mt-0">
                      {Subtitlesmap?.map((item, index) => (
                        <div className="col-sm-12 mt-2" key={index}>
                          <label>{item?.language}</label>

                          <div className="row">
                            {item?.subtitle_url ? (
                              <>
                                <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                                  <Link
                                    to={item?.subtitle_url}
                                    target="_blank"
                                    download
                                  >
                                    <div className="text-center file-e-paper p-2 mt-2">
                                      <i
                                        className="fa fa-file-pdf-o"
                                        aria-hidden="true"
                                      ></i>
                                      <div>Download</div>
                                    </div>
                                  </Link>
                                </div>
                                <div className="col-5 col-sm-5 col-md-5 col-lg-5  align-self-center">
                                  <Link to="" className="delete ms-1">
                                    <span
                                      onClick={() =>
                                        deleteOperationsubtitle(
                                          item?.subtitles_id
                                        )
                                      }
                                    >
                                      <img
                                        src={deleteitem}
                                        alt="flogo"
                                        width={20}
                                        height={20}
                                      />
                                    </span>
                                  </Link>
                                </div>
                              </>
                            ) : null}
                          </div>

                          <div className="row">
                            <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                              {editUser?.subtitle_url ? (
                                <Link
                                  to={item?.subtitle_url}
                                  target="_blank"
                                  download
                                >
                                  <div className="text-center file-e-paper p-2 mt-2">
                                    <i
                                      className="fa fa-file-pdf-o"
                                      aria-hidden="true"
                                    ></i>
                                    <div>Download</div>
                                  </div>
                                </Link>
                              ) : null}
                            </div>
                          </div>

                          <div className="file-draganddrop mt-2">
                            <Dropzone
                              onDrop={(acceptedFiles) =>
                                handleDropsubtitles(
                                  item?.short_code,
                                  acceptedFiles
                                )
                              }
                              accept="video/*"
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} className="dropzone">
                                  <input {...getInputProps()} />
                                  <p className="p-3">
                                    Drag and drop subtitles here
                                  </p>
                                  {videosubtitles[item?.short_code]?.length >
                                    0 && (
                                      <p>
                                        {videosubtitles[item?.short_code].length}{" "}
                                        {videosubtitles[item?.short_code]
                                          .length === 1
                                          ? "file"
                                          : "files"}{" "}
                                        selected
                                      </p>
                                    )}
                                </div>
                              )}
                            </Dropzone>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* <div className="card p-3">
                    <h5>Choose Ads Settings</h5>
                    <hr></hr>

                    <div className="col-sm-12 mt-2">
                      <label className="m-0">Choose Ads Position</label>

                      <div className=" mt-2">
                        <Select
                          options={adsPositions}
                          className="rs-input"
                          onChange={(e) => handleAdsPositionChange(e)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 mt-2">
                      <label className="m-0">Choose Advertisement</label>

                      <div className=" mt-2">
                        <Select
                          options={secondSelectOptions}
                          className="rs-input"
                          onChange={(e) => setSecondSelectOptions(e)}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </section>

          {/* <h4>Video Information:</h4> */}
          {/* <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 form-group">
              
                <media-player
                  title="Agent 327: Operation Barbershop"
                  src="https://media-files.vidstack.io/720p.mp4"
                  poster="https://media-files.vidstack.io/poster.png"
                  controls
                >
                  <media-outlet></media-outlet>
                </media-player>
              </div>
            </div>
          </div> */}
          {/* <div className="text-end mt-3">
            <Button
              variant="contained"
              onClick={handleNext}
              className="btn btn-primary"
            >
              Next
            </Button>
          </div> */}
        </div>
      ),
    },
    {
      label: "Video Details",
      description: (
        <div className="">
          {/* <h4>Video Information:</h4> */}
          {/* <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6 form-group">
                <label className="m-0">Title :</label>
                <input
                  type="text"
                  className="form-control"
                  value={editUser?.title}
                  name="title"
                  id="title"
                  onChange={handleInput}
                  placeholder="Title"
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">Video Slug:</label>

                <input
                  type="text"
                  className="form-control"
                  name="slug"
                  id="slug"
                  onChange={handleInput}
                  placeholder="Video Slug"
                  value={editUser?.slug}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 form-group">
                <label className="m-0">Age Restrict :</label>
                <select
                  value={editUser?.age_restrict}
                  className="form-control"
                  id="age_restrict"
                  name="age_restrict"
                  onChange={handleInput}
                >
                  {age_restrictdata?.map((item) => (
                    <option selected value={item?.id}>
                      {item?.slug}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-6 form-group ">
                <label className="m-0">Rating:</label>

                <select
                  value={editUser?.rating}
                  className="js-example-basic-single form-control"
                  name="rating"
                  tags="true"
                  onChange={handleInput}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 form-group">
                <label className="m-0">Video Description:</label>
               
                <JoditEditor
                 
                  value={editUser?.description}
                
                  onChange={(e) => {
                    handleChangeFirst(e);
                  }}
                />
              </div>
              <div className="col-12 form-group">
                <label className="m-0">Links &amp; Details:</label>
               
                <JoditEditor
                   value={editUser?.details}
                 
                  onChange={(e) => {
                    handleChangeFirstdetails(e);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 form-group">
                <label className="m-0">
                  Skip Intro Time <small>(Please Give In Seconds)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="skip_start_time"
                  name="skip_start_time"
                  onChange={handleInput}
                  value={editUser?.skip_start_time}
                />
              
              </div>
              <div className="col-sm-4 form-group">
                <label className="m-0">
                  Intro Start Time <small>(Please Give In Seconds)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="skip_end_time"
                  name="skip_end_time"
                  onChange={handleInput}
                  value={editUser?.skip_end_time}
                />
                
              </div>
              <div className="col-sm-4 form-group">
                <label className="m-0">
                  Intro End Time <small>(Please Give In Seconds)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="skip_start_session"
                  name="skip_start_session"
                  onChange={handleInput}
                  value={editUser?.skip_start_session}
                />
                
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 form-group">
                <label className="m-0">
                  Skip Recap <small>(Please Give In Seconds)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="recap_end_time"
                  name="recap_end_time"
                  onChange={handleInput}
                  value={editUser?.recap_end_time}
                />
               
              </div>
              <div className="col-sm-4 form-group">
                <label className="m-0">
                  Recap Start Time <small>(Please Give In Seconds)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="recap_end_time"
                  name="recap_end_time"
                  onChange={handleInput}
                  value={editUser?.recap_end_time}
                />
                
              </div>
              <div className="col-sm-4 form-group">
                <label className="m-0">
                  Recap End Time <small>(Please Give In Seconds)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="recap_end_time"
                  name="recap_end_time"
                  onChange={handleInput}
                  value={editUser?.recap_end_time}
                />
                
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 form-group">
                <label className="m-0">Video Duration:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Video Duration"
                  name="duration"
                  id="duration"
                  onChange={handleInput}
                  value={editUser?.duration}
                />
              </div>
              <div className="col-sm-4 form-group">
                <label className="m-0">Year:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Release Year"
                  name="year"
                  onChange={handleInput}
                  id="year"
                  value={editUser?.year}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 form-group">
                <label className="mb-2">Publish Type</label>
                <br />
                <div
                  onClick={(event) => {
                    setEnable_restreampublic(event.target.value);
                  }}
                >
                  <input
                    type="radio"
                    id="publish_now"
                    name="publish_type"
                    onChange={handleInput}
                    onClick={(event) => {
                      setEnable_restreampublic(event.target.value);
                    }}
                    value="publish_now"
                    checked="checked"
                  />{" "}
                  <span
                    className=""
                    onClick={(event) => {
                      setEnable_restreampublic(event.target.value);
                    }}
                  >
                    Publish Now
                  </span>{" "}
                </div>
                <input
                  type="radio"
                  id="publish_later"
                  value="publish_later"
                  name="publish_type"
                  onChange={handleInput}
                  onClick={(event) => {
                    setEnable_restreampublic(event.target.value);
                  }}
                />{" "}
                <span
                  onClick={(event) => {
                    setEnable_restreampublic(event.target.value);
                  }}
                >
                  Publish Later
                </span>
              </div>

              <div className="col-sm-6 form-group" id="publishlater">
                <div
                  onClick={(event) => {
                    setEnablestreamvisiblepublic(event.target.value);
                  }}
                >
                  {proceednextpublic()}
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="text-end mt-3">
            <Button
              variant="contained"
              onClick={handleUpdate}
              className="btn btn-primary ms-2"
            >
              Upload Video
            </Button>
            <Button onClick={handleBack} className="btn btn-dark ms-2">
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              className="btn btn-primary ms-2"
            >
              Next
            </Button>
          </div> */}
        </div>
      ),
    },
    {
      label: "Category",
      description: (
        <div className="container-fluid mt-4">
          {/* <div className="form-card">
            <div className="row">
              <div className="col-sm-6 form-group">
                <label className="m-0">Select Video Category :</label>
                <Select
                  options={optionscategories}
                  isMulti
                  className="form-control"
                  onChange={handleSelectChangecategories}
                  value={selectedValuescategories}
                />
              </div>
              <div className="col-sm-6 form-group">
                <div className="panel panel-primary" data-collapsed="0">
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label className="m-0">
                        Cast and Crew :{" "}
                        <small>( Add artists for the video below )</small>
                      </label>
                    </div>
                  </div>
                  <div className="panel-body">
                    <Select
                      options={optionsartists}
                      isMulti
                      className="form-control"
                      onChange={handleSelectChangeartists}
                      value={selectedValuesartists}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 form-group">
                <label className="m-0">Choose Language:</label>
                <Select
                  options={optionslanguages}
                  isMulti
                  className="form-control"
                  onChange={handleSelectChangelanguages}
                  value={selectedValueslanguages}
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">
                  E-Paper: <small>(Upload your PDF file)</small>
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="pdf_file"
                  accept="application/pdf"
                  onChange={(e) => setPdf_files(e.target.files[0])}
                  multiple
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">Reels Videos: </label>
                <div>
                  <media-player title="Reels Videos" src="" poster="" controls>
                    <media-outlet></media-outlet>
                  </media-player>
                </div>

                <input
                  type="file"
                  className="form-control"
                  name="reels_videos"
                  accept="video/mp4,video/x-m4v,video/*"
                  onChange={(e) => setReelvideo(e.target.files[0])}
                  multiple
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">
                  Reels Thumbnail: <small>(9:16 Ratio or 720X1080px)</small>
                </label>
                <div>
                  <img
                    src={editUser?.Video_reels_Thumbnail}
                    width={250}
                    height={250}
                  />
                </div>
                <input
                  type="file"
                  className="form-control"
                  name="reels_thumbnail"
                  onChange={(e) => setReels_thumbnail(e.target.files[0])}
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">URL Link </label>
                <input
                  type="text"
                  className="form-control"
                  name="url_link"
                  accept=""
                  value={editUser?.url_link}
                  onChange={handleInput}
                  id="url_link"
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">
                  URL Start Time <small>Format (HH:MM:SS)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="url_linktym"
                  accept=""
                  onChange={handleInput}
                  value={editUser?.url_linktym}
                  id="url_linktym"
                />
              </div>
            </div>
          </div>
          <div className="text-end mt-3">
            <Button
              variant="contained"
              onClick={handleUpdate}
              className="btn btn-primary ms-2"
            >
              Upload Video
            </Button>
            <Button onClick={handleBack} className="btn btn-dark ms-2">
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              className="btn btn-primary ms-2"
            >
              Next
            </Button>
          </div> */}
        </div>
      ),
    },
    {
      label: "User Video Access",
      description: (
        <></>
        // <div className="form-card mt-4">
        //   <div className="row">
        //     <div className="col-lg-12">
        //       <h5 className="fs-title">Geo-location for Videos</h5>
        //     </div>
        //   </div>
        //   <div className="row">
        //     <div className="col-sm-6 form-group">
        //       <label className="m-0">Block Country </label>
        //       <p className="p1">
        //         ( Choose the countries for block the videos )
        //       </p>
        //       <Select
        //         options={options}
        //         isMulti
        //         className="form-control"
        //         onChange={handleSelectChange}
        //         value={selectedValues}
        //       />
        //     </div>

        //     <div className="col-sm-6 form-group">
        //       <label className="m-0">Available Country </label>
        //       <p className="p1">( Choose the countries videos )</p>
        //       <Select
        //         options={optionsavailable_country}
        //         isMulti
        //         className="form-control"
        //         onChange={handleSelectChangeavailable_country}
        //         value={selectedValuesavailable_country}
        //       />
        //     </div>
        //   </div>

        //   {/* <div className="row" id="ppv_price">
        //     <div className="col-sm-6 form-group">
        //       <label className="m-0">PPV Price:</label>
        //       <input
        //         type="text"
        //         className="form-control"
        //         placeholder="PPV Price"
        //         name="ppv_price"
        //         onChange={handleInput}
        //         id="price"
        //         value={editUser?.ppv_price}
        //       />
        //     </div>

        //     <div className="col-sm-6 form-group">
        //       <label className="m-0">IOS PPV Price:</label>
        //       <select
        //         name="ios_ppv_price"
        //         className="form-control"
        //         onChange={handleInput}
        //         id="ios_ppv_price"
        //       >
        //         <option value="">Select IOS PPV Price: </option>
        //       </select>
        //     </div>
        //   </div> */}

        //   <div className="row align-items-center">
        //     <div className="col-sm-6 form-group mt-2">
        //       <label for="">Search Tags </label>
        //       <input
        //         type="text"
        //         id="exist-values"
        //         className="tagged form-control"
        //         data-removeBtn="true"
        //         onChange={handleInput}
        //         name="searchtags"
        //         value={editUser?.search_tags}
        //       />
        //     </div>

        //     <div className="col-sm-6 form-group">
        //       <label className="m-0">Related Videos:</label>
        //       <div>
        //         <Select
        //           options={optionsrelated_video}
        //           isMulti
        //           className="form-control"
        //           onChange={handleSelectChangerelated_video}
        //           value={selectedValuesrelated_video}
        //         />
        //       </div>
        //     </div>

        //     <div className="col-sm-6">
        //       <label className="m-0">User Access</label>
        //       <p className="p1">Who Is Allowed To View This Video ?</p>
        //       <select
        //         onChange={handleInput}
        //         className="form-control"
        //         value={editUser?.access}
        //         onClick={(event) => {
        //           setLivesource(event.target.value);
        //         }}
        //       >
        //         {user_access?.map((item, key) => (
        //           <option value={item?.role}>{item?.name}</option>
        //         ))}
        //       </select>
        //     </div>
        //   </div>

        //   <div>{live_stream_source()}</div>

        //   <div className="row">
        //     <div className="col-sm-6 form-group mt-3" id="ppv_price">
        //       <div className="col-lg-12 row">
        //         <div className="col-lg-10 ">
        //           <label for="global_ppv">Is this video Is Global PPV:</label>
        //         </div>
        //         <div className="col-lg-2 col-3">
        //           <label className="switch">
        //             <input
        //               name="global_ppv"
        //               id="global_ppv"
        //               onChange={handleInput}
        //               defaultChecked={editUser?.global_ppv == 1 ? true : false}
        //               checked={editUser?.global_ppv == 1 ? true : false}
        //               type="checkbox"
        //             />
        //             <span
        //               className="slider round"
        //               name="global_ppv"
        //               id="global_ppv"
        //               onChange={handleInput}
        //               value={editUser?.global_ppv == 1 ? "1" : "0"}
        //             ></span>
        //           </label>
        //         </div>
        //       </div>
        //     </div>
        //     <div className="col-sm-6 mt-3">
        //       <div className="panel panel-primary" data-collapsed="0">
        //         <div className="panel-heading">
        //           <div className="panel-title">
        //             <label>
        //               <h3 className="fs-title">Status Settings</h3>
        //             </label>
        //           </div>
        //         </div>

        //         <div className="panel-body">
        //           <div className="col-lg-12 row">
        //             <div className="col-lg-10 ">
        //               {" "}
        //               <label for="featured">
        //                 Enable this video as Featured:
        //               </label>
        //             </div>
        //             <div className="col-lg-2 col-2">
        //               <label className="switch">
        //                 <input
        //                   name="featured"
        //                   id="featured"
        //                   onChange={handleInput}
        //                   defaultChecked={
        //                     editUser?.featured == 1 ? true : false
        //                   }
        //                   checked={editUser?.featured == 1 ? true : false}
        //                   type="checkbox"
        //                 />
        //                 <span
        //                   className="slider round"
        //                   name="featured"
        //                   id="featured"
        //                   onChange={handleInput}
        //                   value={editUser?.featured == 1 ? "1" : "0"}
        //                 ></span>
        //               </label>
        //             </div>
        //           </div>

        //           <div className="col-lg-12 row">
        //             <div className="col-lg-10 ">
        //               {" "}
        //               <label for="active">Enable this Video:</label>
        //             </div>
        //             <div className="col-lg-2 col-2">
        //               <label className="switch">
        //                 <input
        //                   name="active"
        //                   id="active"
        //                   onChange={handleInput}
        //                   defaultChecked={editUser?.active == 1 ? true : false}
        //                   checked={editUser?.active == 1 ? true : false}
        //                   type="checkbox"
        //                 />
        //                 <span
        //                   className="slider round"
        //                   name="active"
        //                   id="active"
        //                   onChange={handleInput}
        //                   value={editUser?.active == 1 ? "1" : "0"}
        //                 ></span>
        //               </label>
        //             </div>
        //           </div>

        //           <div className="col-lg-12 row">
        //             <div className="col-lg-10 ">
        //               {" "}
        //               <label for="banner">Enable this Video as Slider:</label>
        //             </div>
        //             <div className="col-lg-2 col-2">
        //               <label className="switch">
        //                 <input
        //                   name="banner"
        //                   id="banner"
        //                   onChange={handleInput}
        //                   defaultChecked={editUser?.banner == 1 ? true : false}
        //                   checked={editUser?.banner == 1 ? true : false}
        //                   type="checkbox"
        //                 />
        //                 <span
        //                   className="slider round"
        //                   name="banner"
        //                   id="banner"
        //                   onChange={handleInput}
        //                   value={editUser?.banner == 1 ? "1" : "0"}
        //                 ></span>
        //               </label>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        //   <div className="text-end mt-3">
        //     <Button
        //       variant="contained"
        //       onClick={handleUpdate}
        //       className="btn btn-primary ms-2"
        //     >
        //       Upload Video
        //     </Button>
        //     <Button onClick={handleBack} className="btn btn-dark ms-2">
        //       Back
        //     </Button>
        //     <Button
        //       variant="contained"
        //       onClick={handleNext}
        //       className="btn btn-primary ms-2"
        //     >
        //       Next
        //     </Button>
        //   </div>
        // </div>
      ),
    },
    {
      label: "Upload Image & Trailer",
      description: (
        <></>
        // <div className="form-card mt-4">
        //   <div className="row">
        //     <div className="col-7">
        //       <h3 className="fs-title">Image Upload:</h3>
        //     </div>
        //     <div className="col-5"></div>
        //   </div>

        //   <div className="row">
        //     <div className="col-sm-6 form-group">
        //       <label className="mb-1">
        //         Video Thumbnail <span>(9:16 Ratio or 1080X1920px)</span>
        //       </label>
        //       <input
        //         className="form-control mt-2"
        //         type="file"
        //         name="image"
        //         id="image"
        //         onChange={(e) => setImage(e.target.files[0])}
        //       />
        //       <img
        //         src={editUser?.Thumbnail}
        //         width={300}
        //         height={300}
        //         className="video-img w-100 mt-2"
        //         alt="Thumbnail"
        //       />
        //     </div>

        //     <div className="col-sm-6 form-group">
        //       <label className="mb-1">
        //         Player Thumbnail <span>(16:9 Ratio or 1280X720px)</span>
        //       </label>
        //       <input
        //         className="form-control"
        //         type="file"
        //         name="player_image"
        //         id="player_image"
        //         onChange={(e) => setPlayer_image(e.target.files[0])}
        //       />
        //       <img
        //         src={editUser?.Player_thumbnail}
        //         width={300}
        //         height={300}
        //         className="video-img w-100 mt-2"
        //         alt="Thumbnail"
        //       />
        //     </div>
        //   </div>

        //   <div className="row">
        //     <div className="col-sm-6 form-group">
        //       <label className="mb-1"> Video TV Thumbnail </label>
        //       <input
        //         className="form-control"
        //         type="file"
        //         name="video_tv_image"
        //         onChange={(e) => setVideo_tv_image(e.target.files[0])}
        //         id="video_tv_image"
        //       />

        //       <img
        //         src={editUser?.TV_Thumbnail}
        //         width={300}
        //         height={300}
        //         className="video-img w-100 mt-2"
        //         alt="Thumbnail"
        //       />
        //     </div>
        //   </div>

        //   <div className="row">
        //     <div className="col-sm-6 form-group">
        //       <label className="mb-1"> Video Title Thumbnail </label>
        //       <input
        //         type="file"
        //         className="form-control"
        //         name="video_title_image"
        //         id="video_title_image"
        //         onChange={(e) => setVideo_Title_Thumbnail(e.target.files[0])}
        //       />
        //       <img
        //         src={editUser?.Video_Title_Thumbnail}
        //         width={300}
        //         height={300}
        //         className="video-img w-100 mt-2"
        //         alt="Thumbnail"
        //       />
        //     </div>

        //     <div className="col-sm-6 form-group">
        //       <label className="mb-1">Enable Video Title Thumbnail</label>
        //       <br />
        //       <div className="mt-1">
        //         <label className="switch" onChange={handleInput}>
        //           <input
        //             name="enable_video_title_image"
        //             defaultChecked={
        //               editUser?.enable_video_title_image == 1 ? true : false
        //             }
        //             checked={
        //               editUser?.enable_video_title_image == 1 ? true : false
        //             }
        //             type="checkbox"
        //           />
        //           <span
        //             className="slider round"
        //             name="enable_video_title_image"
        //             value={editUser?.enable_video_title_image == 1 ? "1" : "0"}
        //           ></span>
        //         </label>
        //       </div>
        //     </div>
        //   </div>

        //   <div className="row mt-3">
        //     <div className="col-7">
        //       <h5 className="fs-title">Trailer Upload:</h5>
        //     </div>

        //     <div className="col-sm-6">
        //       <label className="m-0">Video Trailer Type:</label>
        //       <select
        //         className="trailer_type form-control"
        //         name="trailer_type"
        //         id="trailer_type"
        //         onChange={(e) => setTrailer_type(e.target.value)}
        //         onClick={(event) => {
        //           setEnable_restreamtrailer(event.target.value);
        //         }}
        //       >
        //         <option value="null"> Select the Video Trailer Type </option>
        //         <option value="1"> Video Upload </option>
        //         <option value="m3u8_url">m3u8 Url </option>
        //         <option value="mp4_url">mp4 Url</option>
        //         <option value="embed_url">Embed Code</option>
        //       </select>
        //     </div>
        //   </div>

        //   <div>
        //     <div
        //       onClick={(event) => {
        //         setEnablestreamvisibletrailer(event.target.value);
        //       }}
        //     >
        //       {proceednexttrailer()}
        //     </div>
        //   </div>

        //   <div className="row">
        //     <div className="col-sm-8  form-group">
        //       <label className="m-0">Trailer Description:</label>
        //       {/* <textarea
        //         rows="5"
        //         className="form-control mt-2"
        //         name="trailer_description"
        //         id="trailer-ckeditor"
        //         placeholder="Trailer Description"
        //       ></textarea> */}

        //       {/* <JoditEditor value={editUser?.trailer_description} /> */}

        //       <JoditEditor
        //         // ref={editor}
        //         value={editUser?.trailer_description}
        //         //   onChange={(newContent) => setContent(newContent)}
        //         // onChange={handleInput}

        //         onChange={(e) => {
        //           handleChangeFirsttraildetails(e);
        //         }}
        //       />
        //     </div>
        //   </div>

        //   <div className="text-end mt-3">
        //     <Button
        //       variant="contained"
        //       onClick={handleUpdate}
        //       className="btn btn-primary ms-2"
        //     >
        //       Upload Video
        //     </Button>
        //     <Button onClick={handleBack} className="btn btn-dark ms-2">
        //       Back
        //     </Button>
        //     <Button
        //       variant="contained"
        //       onClick={handleNext}
        //       className="btn btn-primary ms-2"
        //     >
        //       Next
        //     </Button>
        //   </div>
        // </div>
      ),
    },
    {
      label: "ADS Management",
      description: (
        <></>
        // <div className="form-card mt-4">
        //   <div className="row">
        //     <div className="col-7">
        //       <h2 className="fs-title">ADS Management:</h2>
        //     </div>

        //     <div className="row text-start mt-4">
        //       <div className="col-sm-6">
        //         <label className="m-0">Choose Ads Position</label>

        //         <div className="">
        //           {/* <AddLiveStreamPosition
        //                         options={adsPositions}
        //                         onChange={handleAdsPositionChange}
        //                       /> */}

        //           <Select
        //             options={adsPositions}
        //             onChange={(e) => handleAdsPositionChange(e)}
        //           />
        //         </div>
        //       </div>

        //       <div className="col-sm-6">
        //         <label className="m-0">Choose Advertisement</label>

        //         <div className="">
        //           <Select
        //             options={secondSelectOptions}
        //             onChange={(e) => setSecondSelectOptions(e)}
        //           />
        //         </div>
        //       </div>
        //     </div>
        //   </div>

        //   <div className="text-end mt-3">
        //     <Button onClick={handleBack} className="btn btn-dark">
        //       Back
        //     </Button>
        //     <Button
        //       variant="contained"
        //       className="btn btn-primary ms-2"
        //       onClick={handleUpdate}
        //     >
        //       Submit
        //     </Button>
        //   </div>
        // </div>
      ),
    },
  ];

  return (
    <div className="m-0 p-0">
      <div className="">
        <section className="section container-fluid ">
          <div className="pt-3">
            <div className="row">
              <div className="col-sm-12">
                <div className=" addvideo">
                  {/* <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                      <h4 className="card-title">Edit Video</h4>
                    </div>
                  </div>
                  <hr /> */}

                  <Box>
                    {/* <Stepper activeStep={activeStep} nonLinear>
                      {steps?.map((step, index) => (
                        <Step key={step.label}>
                          <StepLabel
                            optional={
                              index === 4 ? (
                                <Typography variant="caption"></Typography>
                              ) : null
                            }
                          >
                            {step?.label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper> */}

                    <Stepper
                      className="video"
                      activeStep={activeStep}
                      nonLinear
                    >
                      {steps?.map((step, index) => (
                        <Step key={step?.label}>
                          <StepContent>
                            <Typography>{step?.description}</Typography>
                            <Box sx={{ mb: 2 }}>
                              <div>
                                {/* <Button
                                  variant="contained"
                                  onClick={handleNext}
                                  sx={{ mt: 1, mr: 1 }}
                                  className="btn btn-primary">
                                  {index === steps.length - 1 ? 'Upload Video' : 'Next'}
                                </Button>
                                <Button
                                  disabled={index === 0}
                                  onClick={handleBack}
                                  sx={{ mt: 1, mr: 1 }}
                                  className="btn btn-dark">
                                  Back
                                </Button> */}
                              </div>
                            </Box>
                          </StepContent>
                        </Step>
                      ))}
                    </Stepper>

                    {activeStep === steps?.length && (
                      <Paper square elevation={0} sx={{ p: 3 }}>
                        <Typography>
                          All steps completed - you&apos;re finished
                        </Typography>
                        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                          Reset
                        </Button>
                      </Paper>
                    )}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
