import { useState, useEffect } from "react";
import axios from "axios";

const NavigationData = () => {

    const [label_management, setLabel_management] = useState();

    const fetchCurrencySettingImageConfig = async () => {
        const access_token = localStorage.getItem("access_token");
        const adminApiHeader = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
        };
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_Baseurl}/admin/Label-Management/list-depends-source`,
                { headers: adminApiHeader }
            );
            const label_management = response?.data?.label_management;
            setLabel_management(label_management);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchCurrencySettingImageConfig();
    }, []);

    const videoURL = [
        {
            url: '/video-lists',
            message: `${label_management?.Sidebar_admin[5]?.title}`
        },
        {
            url: '/add-video',
            message: `${label_management?.Sidebar_admin[6]?.title}`
        },
        {
            url: '/video-schedule',
            message: `${label_management?.Sidebar_admin[7]?.title}`
        },
        {
            url: '/video-partner',
            message: `${label_management?.Sidebar_admin[8]?.title}`
        },
        {
            url: '/video-categories',
            message: `${label_management?.Sidebar_admin[9]?.title}`
        },
        {
            url: '/allplaylist',
            message: `${label_management?.Sidebar_admin[10]?.title}`
        },
        {
            url: '/video-content-partner',
            message: `${label_management?.Sidebar_admin[11]?.title}`
        },
        {
            url: '/video-channel-partner',
            message: `${label_management?.Sidebar_admin[12]?.title}`
        },
        // {
        //     url: '/video-for-approval',
        //     message: `${label_management?.Sidebar_admin[13]?.title}`
        // },
    ]

    const seriesURL = [
        {
            url: '/series-list',
            message: `${label_management?.Sidebar_admin[15]?.title}`
        },
        {
            url: '/add-series',
            message: `${label_management?.Sidebar_admin[16]?.title}`
        },
        {
            url: '/Series-Genre',
            message: `${label_management?.Sidebar_admin[17]?.title}`
        },
        {
            url: '/CPPSeriesIndex',
            message: `${label_management?.Sidebar_admin[18]?.title}`
        },
        {
            url: '/series-content-partner',
            message: `${label_management?.Sidebar_admin[19]?.title}`
        },
        {
            url: '/series-channel-partner',
            message: `${label_management?.Sidebar_admin[20]?.title}`
        },
        // {
        //     url: '/all-episode',
        //     message: 'All Episode'
        // },
        // {
        //     url: '/manage-episode',
        //     message: 'Manage Episode'
        // },
        // {
        //     url: '/all-season',
        //     message: 'All Season'
        // },
        // {
        //     url: '/Add-season',
        //     message: 'Add Season'
        // },
        // {
        //     url: '/edit-season',
        //     message: 'Edit Season'
        // },

    ]

    const liveURL = [
        {
            url: '/livestream',
            message: `${label_management?.Sidebar_admin[23]?.title}`
        },
        {
            url: '/add-livestream',
            message: `${label_management?.Sidebar_admin[24]?.title}`
        },
        {
            url: '/CPPLiveVideosIndex',
            message: `${label_management?.Sidebar_admin[25]?.title}`
        },
        {
            url: '/livestream-categories',
            message: `${label_management?.Sidebar_admin[26]?.title}`
        },
        {
            url: '/live-content-partner',
            message: `${label_management?.Sidebar_admin[27]?.title}`
        },
        {
            url: '/live-channel-partner',
            message: `${label_management?.Sidebar_admin[28]?.title}`
        },
        // {
        //     url: '/live-event-artist',
        //     message: 'Live Event Artist'
        // },
    ]

    const audioURL = [
        {
            url: '/audio-lists',
            message: `${label_management?.Sidebar_admin[31]?.title}`
        },
        {
            url: '/add-audio',
            message: `${label_management?.Sidebar_admin[32]?.title}`
        },
        {
            url: '/audio-categories',
            message: `${label_management?.Sidebar_admin[33]?.title}`
        },
        {
            url: '/manage-albums',
            message: `${label_management?.Sidebar_admin[34]?.title}`
        },
        {
            url: '/audio-approval',
            message: `${label_management?.Sidebar_admin[35]?.title}`
        },
        {
            url: '/audio-content-partner',
            message: `${label_management?.Sidebar_admin[36]?.title}`
        },
        {
            url: '/audio-channel-partner',
            message: `${label_management?.Sidebar_admin[37]?.title}`
        }
    ]

    const artistURL = [
        {
            url: '/all-artist',
            message: `${label_management?.Sidebar_admin[39]?.title}`
        },
        {
            url: '/add-artist',
            message: `${label_management?.Sidebar_admin[40]?.title}`
        },
        {
            url: '/artists-musician',
            message: `${label_management?.Sidebar_admin[41]?.title}`
        },
        // {
        //     url: '/artists-songwriter',
        //     message: `${label_management?.Sidebar_admin[42]?.title}`
        // }
    ]

    const userURL = [
        {
            url: '/users',
            message: `${label_management?.Sidebar_admin[45]?.title}`
        },
        {
            url: '/add-users',
            message: `${label_management?.Sidebar_admin[46]?.title}`
        },
        {
            url: '/import-users-view',
            message: `${label_management?.Sidebar_admin[47]?.title}`
        },
        {
            url: '/MultiUser-limit',
            message: `${label_management?.Sidebar_admin[48]?.title}`
        }]

    const menuURL = [
        {
            url: '/menu',
            message: `${label_management?.Sidebar_admin[50]?.title}`
        },
        {
            url: '/footermenu',
            message: `${label_management?.Sidebar_admin[51]?.title}`
        },
        {
            url: '/mobilemenu',
            message: `${label_management?.Sidebar_admin[52]?.title}`
        },
        {
            url: '/signup-menu',
            message: `${label_management?.Sidebar_admin[53]?.title}`
        },
        // {
        //     url: '/filemanager',
        //     message: `${label_management?.Sidebar_admin[54]?.title}`
        // }
    ]

    const languageURL = [
        {
            url: '/admin-languages',
            message: `${label_management?.Sidebar_admin[57]?.title}`
        },
        {
            url: '/subtitlelanguage',
            message: `${label_management?.Sidebar_admin[58]?.title}`
        },
        {
            url: '/translate-languages',
            message: `${label_management?.Sidebar_admin[59]?.title}`
        },
        {
            url: '/translate-languages-index',
            message: `${label_management?.Sidebar_admin[60]?.title}`
        }
    ]

    const contentPartnerURL = [
        {
            url: '/moderator',
            message: `${label_management?.Sidebar_admin[68]?.title}`
        },
        {
            url: '/allmoderator',
            message: `${label_management?.Sidebar_admin[69]?.title}`
        },
        {
            url: '/allmoderator-approval',
            message: `${label_management?.Sidebar_admin[70]?.title}`
        },
        {
            url: '/moderator/role',
            message: `${label_management?.Sidebar_admin[71]?.title}`
        },
        {
            url: '/moderator/view',
            message: `${label_management?.Sidebar_admin[72]?.title}`
        },
        {
            url: '/moderator/commission',
            message: `${label_management?.Sidebar_admin[73]?.title}`
        },
        {
            url: '/moderator/payouts',
            message: `${label_management?.Sidebar_admin[74]?.title}`
        },
    ]

    const channelPartnerURL = [
        {
            url: '/channel/user/create',
            message: `${label_management?.Sidebar_admin[76]?.title}`
        },
        {
            url: '/channel/view-channel-members',
            message: `${label_management?.Sidebar_admin[77]?.title}`
        },
        {
            url: '/channel/partnerapproval',
            message: `${label_management?.Sidebar_admin[78]?.title}`
        },
        {
            url: '/channel/commission',
            message: `${label_management?.Sidebar_admin[79]?.title}`
        },
        {
            url: '/channel/payouts',
            message: `${label_management?.Sidebar_admin[80]?.title}`
        },
        {
            url: '/channel-package-index',
            message: `${label_management?.Sidebar_admin[81]?.title}`
        }
    ]

    const pagesURL = [
        {
            url: '/pages',
            message: `${label_management?.Sidebar_admin[84]?.title}`
        },
        {
            url: '/landing-page/index',
            message: `${label_management?.Sidebar_admin[85]?.title}`
        },
        // {
        //     url: '/landing-page/create',
        //     message: `${label_management?.Sidebar_admin[86]?.title}`
        // },
    ]

    const plansURL = [
        {
            url: '/subscription-plans',
            message: `${label_management?.Sidebar_admin[89]?.title}`
        },
        {
            url: '/inapp-purchase',
            message: `${label_management?.Sidebar_admin[90]?.title}`
        },
        {
            url: '/Life-time-subscription',
            message: `${label_management?.Sidebar_admin[91]?.title}`
        },
        {
            url: '/devices',
            message: `${label_management?.Sidebar_admin[92]?.title}`
        },
    ]
    const paymentURL = [
        {
            url: '/payment/total_revenue',
            message: `${label_management?.Sidebar_admin[95]?.title}`
        },
        {
            url: '/payment/subscription',
            message: `${label_management?.Sidebar_admin[96]?.title}`
        },
        {
            url: '/payment/payPerView',
            message: `${label_management?.Sidebar_admin[97]?.title}`
        }
    ]
    const analyticsURL = [
        {
            url: '/analytics/users',
            message: `${label_management?.Sidebar_admin[100]?.title}`
        },
        {
            url: '/analytics/subscriber',
            message: `${label_management?.Sidebar_admin[101]?.title}`
        },
        {
            url: '/analytics/ppv',
            message: `${label_management?.Sidebar_admin[102]?.title}`
        },
        // {
        //     url: '/users/revenue',
        //     message: 'Users Revenue'
        // },
        // {
        //     url: '/video/purchased-analytics',
        //     message: 'Purchased Video Analytics'
        // },
        // {
        //     url: '/cpp/analytics',
        //     message: 'CPP Analytics'
        // },
        // {
        //     url: '/cpp/video-analytics',
        //     message: 'CPP Video Analytics'
        // },
        // {
        //     url: '/cpp/revenue',
        //     message: 'CPP Revenue'
        // },
        // {
        //     url: '/analytics/ViewsRegion',
        //     message: 'View By Region'
        // },
        // {
        //     url: '/analytics/RevenueRegion',
        //     message: 'Revenue By Region'
        // },
        // {
        //     url: '/analytics/PlayerVideoAnalytics',
        //     message: 'Player Video Analytics'
        // },
        // {
        //     url: '/analytics/RegionVideoAnalytics',
        //     message: 'Region Video Analytics'
        // },
        // {
        //     url: '/analytics/PlayerUserAnalytics',
        //     message: 'Player User Analytics'
        // },
        // {
        //     url: '/analytics/livestream-analytics',
        //     message: 'CPP Live Video'
        // },
        // {
        //     url: '/live/purchased-analytics',
        //     message: 'Purchased LiveStream Analytics'
        // }
    ]

    const settingsURL = [
        {
            url: '/settings',
            message: `${label_management?.Sidebar_admin[105]?.title}`
        },
        {
            url: '/home-settings',
            message: `${label_management?.Sidebar_admin[106]?.title}`
        },
        {
            url: '/audio-settings',
            message: `${label_management?.Sidebar_admin[107]?.title}`
        },
        {
            url: '/movie-settings',
            message: `${label_management?.Sidebar_admin[108]?.title}`
        },
        {
            url: '/show-settings',
            message: `${label_management?.Sidebar_admin[109]?.title}`
        },
        {
            url: '/linking_settings',
            message: `${label_management?.Sidebar_admin[110]?.title}`
        },
        {
            url: '/age/index',
            message: `${label_management?.Sidebar_admin[111]?.title}`
        },
        {
            url: '/theme_settings',
            message: `${label_management?.Sidebar_admin[112]?.title}`
        },
        {
            url: '/email_settings',
            message: `${label_management?.Sidebar_admin[113]?.title}`
        },
        {
            url: '/payment_settings',
            message: `${label_management?.Sidebar_admin[114]?.title}`
        },
        {
            url: '/storage_settings',
            message: `${label_management?.Sidebar_admin[115]?.title}`
        },
        {
            url: '/mobileapp',
            message: `${label_management?.Sidebar_admin[116]?.title}`
        },
        {
            url: '/system_settings',
            message: `${label_management?.Sidebar_admin[117]?.title}`
        },
        {
            url: '/currency_settings',
            message: `${label_management?.Sidebar_admin[118]?.title}`
        },
        {
            url: '/revenue_settings/index',
            message: `${label_management?.Sidebar_admin[119]?.title}`
        },
        {
            url: '/ThumbnailSetting',
            message: `${label_management?.Sidebar_admin[120]?.title}`
        },
        {
            url: '/ChooseProfileScreen',
            message: `${label_management?.Sidebar_admin[121]?.title}`
        },
        {
            url: '/compress-image-setting',
            message: `${label_management?.Sidebar_admin[122]?.title}`
        },
        {
            url: '/pop-up-setting',
            message: `${label_management?.Sidebar_admin[123]?.title}`
        },
        {
            url: '/comment-section-setting',
            message: `${label_management?.Sidebar_admin[124]?.title}`
        },
        {
            url: '/site-meta-settings',
            message: `${label_management?.Sidebar_admin[125]?.title}`
        },
        {
            url: '/tv-settings',
            message: `${label_management?.Sidebar_admin[126]?.title}`
        },
        {
            url: '/dsp-settings',
            message: `${label_management?.Sidebar_admin[127]?.title}`
        },
        {
            url: '/labels',
            message: `${label_management?.Sidebar_admin[128]?.title}`
        },
        // {
        //     url: '/ThemeIntegration',
        //     message: `${label_management?.Sidebar_admin[129]?.title}`
        // },
    ]

    const advertiserURL = [
        {
            url: '/advertisers',
            message: `${label_management?.Sidebar_admin[132]?.title}`
        }
    ]

    const faqURL = [
        {
            url: '/faq',
            message: `${label_management?.Sidebar_admin[146]?.title}`
        },
        {
            url: '/faq-category',
            message: `${label_management?.Sidebar_admin[147]?.title}`
        }
    ]

    return { label_management, artistURL, audioURL, faqURL, liveURL, userURL, menuURL, videoURL, seriesURL, languageURL, contentPartnerURL, channelPartnerURL, pagesURL, plansURL, paymentURL, analyticsURL, settingsURL, advertiserURL };
};

export default NavigationData;



// const audioURL = [
//     {
//         url: '/audio-lists',
//         message: 'All Audios'
//     },
//     {
//         url: '/add-audio',
//         message: 'Add New Audio'
//     },
//     {
//         url: '/audio-categories',
//         message: 'Manage Audio Categories'
//     },
//     {
//         url: '/manage-albums',
//         message: 'Manage Albums'
//     },
//     {
//         url: '/audio-approval',
//         message: 'Move Audio to Partner'
//     },
//     {
//         url: '/audio-content-partner',
//         message: 'Audio To Content Partner'
//     },
//     {
//         url: '/audio-channel-partner',
//         message: 'Audio To Channel Partner'
//     }
// ]

// const artistURL = [
//     {
//         url: '/all-artist',
//         message: 'All Trainers'
//     },
//     {
//         url: '/add-artist',
//         message: 'Add New Trainer'
//     },
//     {
//         url: '/artists-musician',
//         message: 'Record holder Trainer'
//     },
//     // {
//     //     url: '/artists-songwriter',
//     //     message: 'Publisher/Songwriter'
//     // }
// ]

// const liveURL = [
//     {
//         url: '/livestream',
//         message: 'All Live Streams'
//     },
//     {
//         url: '/add-livestream',
//         message: 'Add New Live Stream'
//     },
//     {
//         url: '/CPPLiveVideosIndex',
//         message: 'Move Live Stream To Partner'
//     },
//     {
//         url: '/livestream-categories',
//         message: 'Manage Live Stream Categories'
//     },
//     {
//         url: '/live-content-partner',
//         message: 'Live Stream To Content Partner'
//     },
//     {
//         url: '/live-channel-partner',
//         message: 'Live Stream To Channel Partner'
//     },
//     // {
//     //     url: '/live-event-artist',
//     //     message: 'Live Event Artist'
//     // },
// ]

// const userURL = [
//     {
//         url: '/users',
//         message: 'All User'
//     },
//     {
//         url: '/add-users',
//         message: 'Add New User'
//     },
//     {
//         url: '/import-users-view',
//         message: 'Import User'
//     },
//     {
//         url: '/MultiUser-limit',
//         message: 'Multi User Management'
//     }]

// const menuURL = [
//     {
//         url: '/menu',
//         message: 'Menus'
//     },
//     {
//         url: '/footermenu',
//         message: 'Footer Menus'
//     },
//     {
//         url: '/mobilemenu',
//         message: 'Mobile Side Menus'
//     },
//     {
//         url: '/signup-menu',
//         message: 'Signup Menus'
//     },
//     // {
//     //     url: '/filemanager',
//     //     message: 'File Manager'
//     // }
// ]

// const videoURL = [
//     {
//         url: '/video-lists',
//         message: 'All Video'
//     },
//     {
//         url: '/add-video',
//         message: 'Add Video'
//     },
//     {
//         url: '/video-schedule',
//         message: 'Video Schedule'
//     },
//     {
//         url: '/video-partner',
//         message: 'Move Video To Partner'
//     },
//     {
//         url: '/video-categories',
//         message: 'Manage Video Categories'
//     },
//     {
//         url: '/allplaylist',
//         message: 'Manage Video Playlist'
//     },
//     {
//         url: '/video-content-partner',
//         message: 'Video To Content Partner'
//     },
//     {
//         url: '/video-channel-partner',
//         message: 'Video To Channel Partner'
//     },
//     // {
//     //     url: '/video-for-approval',
//     //     message: 'Video For Approval'
//     // },
// ]

// const seriesURL = [
//     {
//         url: '/series-list',
//         message: 'All Series'
//     },
//     {
//         url: '/add-series',
//         message: 'Add New Series'
//     },
//     {
//         url: '/Series-Genre',
//         message: 'Manage Series Genre'
//     },
//     {
//         url: '/CPPSeriesIndex',
//         message: 'Move Series To Partner'
//     },
//     {
//         url: '/series-content-partner',
//         message: 'Series To Content Partner'
//     },
//     {
//         url: '/series-channel-partner',
//         message: 'Series To Channel Partner'
//     },
//     // {
//     //     url: '/all-episode',
//     //     message: 'All Episode'
//     // },
//     // {
//     //     url: '/manage-episode',
//     //     message: 'Manage Episode'
//     // },
//     // {
//     //     url: '/all-season',
//     //     message: 'All Season'
//     // },
//     // {
//     //     url: '/Add-season',
//     //     message: 'Add Season'
//     // },
//     // {
//     //     url: '/edit-season',
//     //     message: 'Edit Season'
//     // },

// ]
// const languageURL = [
//     {
//         url: '/admin-languages',
//         message: 'Equipment'
//     },
//     {
//         url: '/subtitlelanguage',
//         message: 'Subtitle Languages'
//     },
//     {
//         url: '/translate-languages',
//         message: 'Manage Translate Languages'
//     },
//     {
//         url: '/translate-languages-index',
//         message: 'Manage Translations'
//     }
// ]

// const contentPartnerURL = [
//     {
//         url: '/moderator',
//         message: 'Add Content Partner'
//     },
//     {
//         url: '/allmoderator',
//         message: 'View Content Partner'
//     },
//     {
//         url: '/allmoderator-approval',
//         message: 'Content Partner For Approval'
//     },
//     {
//         url: '/moderator/role',
//         message: 'Add Role'
//     },
//     {
//         url: '/moderator/view',
//         message: 'View Role'
//     },
//     {
//         url: '/moderator/commission',
//         message: 'Commission'
//     },
//     {
//         url: '/moderator/payouts',
//         message: 'Content Partners Payout'
//     },
// ]
// const channelPartnerURL = [
//     {
//         url: '/channel/user/create',
//         message: 'Add Channel Partner'
//     },
//     {
//         url: '/channel/view-channel-members',
//         message: 'View Channel Partner'
//     },
//     {
//         url: '/channel/partnerapproval',
//         message: 'Channel Partner For Approval'
//     },
//     {
//         url: '/channel/commission',
//         message: 'Commission'
//     },
//     {
//         url: '/channel/payouts',
//         message: 'Channel Partners Payout'
//     },
//     {
//         url: '/channel-package-index',
//         message: 'Channel Package'
//     }
// ]
// const pagesURL = [
//     {
//         url: '/pages',
//         message: 'All Pages'
//     },
//     {
//         url: '/landing-page/index',
//         message: 'Landing Pages'
//     },
//     // {
//     //     url: '/landing-page/create',
//     //     message: 'Create Landing Pages'
//     // },
// ]
// const plansURL = [
//     {
//         url: '/subscription-plans',
//         message: 'Manage Subscription Plans'
//     },
//     {
//         url: '/inapp-purchase',
//         message: 'Manage In App Purchase Plans'
//     },
//     {
//         url: '/Life-time-subscription',
//         message: 'Life Time Subscription'
//     },
//     {
//         url: '/devices',
//         message: 'Devices'
//     },
// ]
// const paymentURL = [
//     {
//         url: '/payment/total_revenue',
//         message: 'Total Revenues'
//     },
//     {
//         url: '/payment/subscription',
//         message: 'Subscription Payments'
//     },
//     {
//         url: '/payment/payPerView',
//         message: 'PayPerView Payments'
//     }
// ]
// const analyticsURL = [
//     {
//         url: '/analytics/users',
//         message: 'Users Analytics'
//     },
//     {
//         url: '/analytics/subscriber',
//         message: 'Subscriber Analytics'
//     },
//     {
//         url: '/analytics/ppv',
//         message: 'PPV Analytics'
//     },
//     {
//         url: '/users/revenue',
//         message: 'Users Revenue'
//     },
//     {
//         url: '/video/purchased-analytics',
//         message: 'Purchased Video Analytics'
//     },
//     {
//         url: '/cpp/analytics',
//         message: 'CPP Analytics'
//     },
//     {
//         url: '/cpp/video-analytics',
//         message: 'CPP Video Analytics'
//     },
//     {
//         url: '/cpp/revenue',
//         message: 'CPP Revenue'
//     },
//     {
//         url: '/analytics/ViewsRegion',
//         message: 'View By Region'
//     },
//     {
//         url: '/analytics/RevenueRegion',
//         message: 'Revenue By Region'
//     },
//     {
//         url: '/analytics/PlayerVideoAnalytics',
//         message: 'Player Video Analytics'
//     },
//     {
//         url: '/analytics/RegionVideoAnalytics',
//         message: 'Region Video Analytics'
//     },
//     {
//         url: '/analytics/PlayerUserAnalytics',
//         message: 'Player User Analytics'
//     },
//     {
//         url: '/analytics/livestream-analytics',
//         message: 'CPP Live Video'
//     },
//     {
//         url: '/live/purchased-analytics',
//         message: 'Purchased LiveStream Analytics'
//     }
// ]

// const settingsURL = [
//     {
//         url: '/settings',
//         message: 'Storefront Settings'
//     },
//     {
//         url: '/home-settings',
//         message: 'HomePage Settings'
//     },
//     {
//         url: '/audio-settings',
//         message: 'AudioPage Settings'
//     },
//     {
//         url: '/movie-settings',
//         message: 'Movie Settings'
//     },
//     {
//         url: '/show-settings',
//         message: 'Show Settings'
//     },
//     {
//         url: '/linking_settings',
//         message: 'Link Settings'
//     },
//     {
//         url: '/age/index',
//         message: 'Manage Age'
//     },
//     {
//         url: '/theme_settings',
//         message: 'Theme Settings'
//     },
//     {
//         url: '/email_settings',
//         message: 'Email Settings'
//     },
//     {
//         url: '/payment_settings',
//         message: 'Payment Settings'
//     },
//     {
//         url: '/storage_settings',
//         message: 'Storage Settings'
//     },
//     {
//         url: '/mobileapp',
//         message: 'Mobile App Settings'
//     },
//     {
//         url: '/system_settings',
//         message: 'Social Login Settings'
//     },
//     {
//         url: '/currency_settings',
//         message: 'Currency Settings'
//     },
//     {
//         url: '/revenue_settings/index',
//         message: 'Revenue Settings'
//     },
//     {
//         url: '/ThumbnailSetting',
//         message: 'Thumbnail Settings'
//     },
//     {
//         url: '/ChooseProfileScreen',
//         message: 'Profile Screen'
//     },
//     {
//         url: '/compress-image-setting',
//         message: 'Image Settings'
//     },
//     {
//         url: '/pop-up-setting',
//         message: 'Home Page Pop Up Settings'
//     },
//     {
//         url: '/comment-section-setting',
//         message: 'Comment Settings'
//     },
//     {
//         url: '/site-meta-settings',
//         message: 'Site Meta Settings'
//     },
//     {
//         url: '/tv-settings',
//         message: 'Tv Settings'
//     },
//     {
//         url: '/dsp-settings',
//         message: 'DSP Settings'
//     },
//     {
//         url: '/labels',
//         message: 'Labels Settings'
//     },
//     // {
//     //     url: '/ThemeIntegration',
//     //     message: 'Theme'
//     // },
// ]

// const advertiserURL = [
//     {
//         url: '/advertisers',
//         message: 'Advertiser'
//     }
// ]
// const faqURL = [
//     {
//         url: '/faq',
//         message: 'FAQ'
//     },
//     {
//         url: '/faq-category',
//         message: 'FAQ Category'
//     }
// ]

// export { artistURL, audioURL, faqURL, liveURL, userURL, menuURL, videoURL, seriesURL, languageURL, contentPartnerURL, channelPartnerURL, pagesURL, plansURL, paymentURL, analyticsURL, settingsURL, advertiserURL }