// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eyepassworduser {
    position: absolute;
    padding: 35px;
    right: 0%;
    font-size: 18px !important;
    top: 80%;
}

.subscriptionName{
        font-size: 13px;
        font-weight: bold;
        line-height: 30px;
}
.subscriptionSub{
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Account/User/AddUser.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,SAAS;IACT,0BAA0B;IAC1B,QAAQ;AACZ;;AAEA;QACQ,eAAe;QACf,iBAAiB;QACjB,iBAAiB;AACzB;AACA;QACQ,eAAe;QACf,iBAAiB;QACjB,gBAAgB;QAChB,mBAAmB;AAC3B","sourcesContent":[".eyepassworduser {\n    position: absolute;\n    padding: 35px;\n    right: 0%;\n    font-size: 18px !important;\n    top: 80%;\n}\n\n.subscriptionName{\n        font-size: 13px;\n        font-weight: bold;\n        line-height: 30px;\n}\n.subscriptionSub{\n        font-size: 12px;\n        line-height: 20px;\n        font-weight: 400;\n        margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
