// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userAnalyticsBox {
    width: 200px !important;
}

.userDateTime {
    font-size: 11px;
    line-height: 25px
}

.totalResult {
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
}

.countUsers {
    font-size: 23px;
    line-height: 40px;
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Site/Analytics/UsersAnalytics.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf;AACJ;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".userAnalyticsBox {\n    width: 200px !important;\n}\n\n.userDateTime {\n    font-size: 11px;\n    line-height: 25px\n}\n\n.totalResult {\n    font-size: 18px;\n    line-height: 25px;\n    font-weight: 500;\n}\n\n.countUsers {\n    font-size: 23px;\n    line-height: 40px;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
