import { Link, useNavigate, useParams } from "react-router-dom";
import JoditEditor from "jodit-react";
import { toast } from "react-toastify"
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { TagsInput } from "react-tag-input-component";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";

import { Modal, Form, Table, Button } from 'react-bootstrap';
import { FcOk, FcHighPriority, FcFile } from "react-icons/fc";
import { BsFiletypeXlsx } from "react-icons/bs";
import { IoClose } from "react-icons/io5";


import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import ReactAudioPlayer from "react-audio-player";
import { adminApiHeader, audioDefaultURL } from "../../../components/CommonApis/CommonAudioApis";
import AdminSaveHeader from "../../../components/CommonComponents/AdminSaveHeader";
import FormContainer from "../../../components/CommonComponents/FormContainer";
import LeftWrapper from "../../../components/CommonComponents/LeftWrapper";
import RightWrapper from "../../../components/CommonComponents/RightWrapper";
import MessageSubHeading from "../../../components/CommonComponents/MessageSubHeading";
import MessageHeading from "../../../components/CommonComponents/MessageHeading";
import MessageBox from "../../../components/CommonComponents/MessageBox";
import Wrapper from "../../../components/CommonComponents/Wrapper";
import MessageLabel from "../../../components/CommonComponents/MessageLabel";
import { siteThemApi } from "../../../components/CommonApis/CommonApiUrl";
import FetchLoader from "../../../components/CommonComponents/FetchLoader";
import AdminCustomButton from "../../../components/CommonComponents/AdminCustomButton";
import useCompressImageConfig from "../../../components/CommonApis/Compressimageapi";
import usePpvGlobalImageConfig from "../../../components/CommonApis/ppv_global_change";

import NavigationData from "../../../layout/Sidebar/NavigationData";

function Editnewaudio() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { label_management } = NavigationData();
  const [formData, setFormData] = useState(null)
  const [defaultOption, setDefaultOption] = useState({
    artistsOption: [],
    categoryOption: [],
    languageOption: [],
    blockCountryOption: [],
    availableCountryOption: [],
    writerOption: [],
    musicianOption: [],
    ageOption: [],
    albumOption: [],
    accessOption: [],
    iosOption: [],
    lyricsOption: [],
    globalOption: [],
  })

  const [editUser, setEditUser] = useState({
    id: "",
    title: "",
    duration: "",
    slug: "",
    free_duration_time: "",
    free_duration_status: "",
    active: "1",
    year: "",
    ppv_price: "",
    source_type: "",
    PPVType: "",
    global_ppv: "",
    ios_ppv_price: "",
  });

  const [formDataError, setFormDataError] = useState({})
  const [getSuccessLyrics, setSuccessLyrics] = useState(null);
  const [getDangerLyrics, setDangerLyrics] = useState(null);
  const [getLyrics, setLyrics] = useState(null);
  const [coverImageFile, setCoverImageFile] = useState(null);
  const [playerImageFile, setPlayerImageFile] = useState(null);
  const [tvImageFile, setTvImageFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [audioCheck, setAudioCheck] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [seoFormData, setSeoFormData] = useState({})

  //input ref
  const titleInputRef = useRef(null);
  const categoryInputRef = useRef(null);
  const languageInputRef = useRef(null);
  const slugInputRef = useRef(null);
  const coverImageRef = useRef(null);
  const playerImageRef = useRef(null);
  const tvImageRef = useRef(null);
  const accessInputRef = useRef(null);
  const audioRef = useRef(null);

  const assignDefault = (value, defaultValue = 0) => value ? value : defaultValue;

  const { compressResolutionFormat } = useCompressImageConfig();

  const [error, setError] = useState({
    file1: '',
    file2: '',
    file3: '',
  });

  const validateImageRatio = (file, expectedRatio, expectedWidth, expectedHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const ratio = width / height;
        const expectedRatioPrecision = 0.01; // Allow for some minor floating-point precision issues
        const ratioMatch = Math.abs(ratio - expectedRatio) < expectedRatioPrecision;
        const dimensionMatch = (width === expectedWidth && height === expectedHeight);
        if (ratioMatch || dimensionMatch) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
      img.onerror = () => {
        reject(new Error('Error loading image'));
      };
    });
  };




  const [inapppurchase, setInappPurchase] = useState([]);
  const [ppv_gobal_price, setppv_gobal_price] = useState();
  const [allCurrencyglobal, setAllCurrencyglobal] = useState([]);
  const [allCurrencycustoms, setAllCurrencycustoms] = useState([]);
  const [allCurrencylistfromappichoosed, setAllCurrencylistfromappichoosed] = useState();
  const { allCurrencylistfromappi, globalPpvStatusActive } = usePpvGlobalImageConfig();
  const global_ppv_status_active = globalPpvStatusActive

  // Api start

  const fetchData = async () => {
    try {
      const response = await axios.get(`${audioDefaultURL?.editAudioApi}/${id}`, { headers: adminApiHeader });
      const result = response?.data;
      const previousFormData = result?.audios[0];
      setEditUser(previousFormData);
      const updatArtist = previousFormData?.selected_artists?.map((item) => ({
        value: item.artist_id,
        label: item.name
      })) || [];
      const updateCategory = previousFormData?.selected_categories?.map((item) => ({
        value: item.category_id,
        label: item.name
      })) || [];
      const updateLanguage = previousFormData?.selected_Language?.map((item) => ({
        value: item.language_id,
        label: item.name
      })) || [];
      const updateBlockCountry = previousFormData?.selected_block_countries?.map((item) => ({
        value: item.id,
        label: item.name
      })) || [];
      const updateAvailableCountry = previousFormData?.available_countries?.map((item) => ({
        value: item.id,
        label: item.name
      })) || [];
      const updatePerformance = previousFormData?.publisher_musician?.map((item) => ({
        value: item.id,
        label: item.name
      })) || [];
      const updateWriter = previousFormData?.publisher_songwriter?.map((item) => ({
        value: item.artist_id,
        label: item.name
      })) || [];
      const updateSearch = previousFormData?.search_tags?.split(",").map((tag) => tag.trim())
      const updatedFormData = {
        ...previousFormData,
        selected_artists: updatArtist,
        selected_categories: updateCategory,
        selected_Language: updateLanguage,
        selected_block_countries: updateBlockCountry,
        available_countries: updateAvailableCountry,
        publisher_musician: updatePerformance,
        publisher_songwriter: updateWriter,
        search_tags: updateSearch,
        ppv_status: assignDefault(previousFormData.ppv_status),
        album_id: assignDefault(previousFormData.album_id),
        rating: assignDefault(previousFormData.rating),
        active: assignDefault(previousFormData.active),
        status: assignDefault(1),
        draft: assignDefault(1),
        featured: assignDefault(previousFormData.featured),
        banner: assignDefault(previousFormData.banner),
        views: assignDefault(previousFormData.views),
        year: assignDefault(previousFormData.year),
        age_restrict: assignDefault(previousFormData.age_restrict),
      };
      setFormData(updatedFormData)
      setCheckboxChecked(previousFormData?.ppv_status == 1 ? true : false)
      const defaultoptionslist = {
        artistsOption: Array.isArray(result.artists) ? result.artists : [],
        categoryOption: Array.isArray(result.audio_categories) ? result.audio_categories : [],
        languageOption: Array.isArray(result.languages) ? result.languages : [],
        blockCountryOption: Array.isArray(result.Block_Country) ? result.Block_Country : [],
        availableCountryOption: Array.isArray(result.Available_country) ? result.Available_country : [],
        writerOption: Array.isArray(result.SongWriter) ? result.SongWriter : [],
        musicianOption: Array.isArray(result.Musician) ? result.Musician : [],
        ageOption: Array.isArray(result.Age_Restrict) ? result.Age_Restrict : [],
        albumOption: Array.isArray(result.audio_albums) ? result.audio_albums : [],
        accessOption: Array.isArray(result.user_access) ? result.user_access : [],
        iosOption: Array.isArray(result.InappPurchase) ? result.InappPurchase : [],
        lyricsOption: result.sample_audio_lyrics ? result.sample_audio_lyrics : '',
        globalOption: result.ppv_global_price ? result.ppv_global_price : '',
      }
      setDefaultOption(defaultoptionslist);


      var InappPurchase = response?.data?.InappPurchase;
      setInappPurchase(InappPurchase);
      var Defaultppv_gobal_price = response?.data?.ppv_global_price;
      setppv_gobal_price(Defaultppv_gobal_price)
      var DefaultCurrencytest = response?.data?.DefaultCurrency[0];
      setAllCurrencylistfromappichoosed(DefaultCurrencytest);
      var DefaultGlobalContentPriceRates = response?.data?.GlobalContentPriceRates;
      setAllCurrencyglobal(DefaultGlobalContentPriceRates);
      var DefaultContentPriceRates = response?.data?.ContentPriceRates;
      setAllCurrencycustoms(DefaultContentPriceRates);
      setLoading(false)
    } catch (error) {
      console.error(error);
    }
  };

  const audioContent = async () => {
    try {
      const response = await axios.get(`${siteThemApi}`, { headers: adminApiHeader })
      const result = response?.data?.Site_theme_setting[0];
      if (response?.data?.status) {
        setAudioCheck(result)
      }

    }
    catch (err) {
      console.error(err)
    }
  }
  // Api end

  //function start
  const handleBlurTitleEdit = () => {
    generateSlug(formData?.slug);
  };

  const handleAutoFillChangetitle = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      slug: event.target.value,
    }));
    setFormDataError((prevFormData) => ({
      ...prevFormData,
      [event.target.name]: "",
    }));
  };

  const generateSlug = (title) => {
    title = title?.trim();
    const slug = title?.toLowerCase().replace(/\s+/g, "-");
    setFormData((prevFormData) => ({
      ...prevFormData,
      slug: slug,
    }));
  };

  const handleInputKeyDowntitle = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      generateSlug(formData?.title);
    }
  };

  //Handle jodit edit
  const handleJodiEditor = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      details: value,
    }));
  };

  //Handle Year
  // const handleChangeyear = (event) => {
  //   const name = event.target.name;
  //   const value = event.target.value;
  //   if (/^\d{0,4}$/.test(value)) {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       [name]: value,
  //     }));
  //   }
  // };

  const handleChangeyear = (event) => {
    const value = event.target.value;
    setFormData({ ...formData, year: value });
  };

  //Handle duration
  const handleInputdata = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    const formattedInput = inputValue?.replace(/\D/g, "");
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [inputName]: formattedTime,
    }));
  };

  //Hanele Artists

  const handleSelectChangeartists = (selectedValues) => {
    setFormData((prevOptionData) => ({
      ...prevOptionData,
      selected_artists: selectedValues
    }));
  };

  //Handle Categories
  const handleSelectChangecategory = (selectedValues) => {
    setFormData((prevOptionData) => ({
      ...prevOptionData,
      selected_categories: selectedValues,
    }));
    setFormDataError((prevFormData) => ({
      ...prevFormData,
      categoryerror: "",
    }));
  };

  //Handle Language
  const handleSelectChangelanguage = (selectedValues) => {
    setFormData((prevOptionData) => ({
      ...prevOptionData,
      selected_Language: selectedValues,
    }));
    setFormDataError((prevFormData) => ({
      ...prevFormData,
      languageerror: "",
    }));
  };

  //Handle Blocked country
  const handleSelectChangeBlockedCountry = (selectedValues) => {
    setFormData((prevOptionData) => ({
      ...prevOptionData,
      selected_block_countries: selectedValues,
    }));
  };
  //Handle Available country
  const handleSelectChangeAvailable_country = (selectedValues) => {
    setFormData((prevOptionData) => ({
      ...prevOptionData,
      available_countries: selectedValues,
    }));
  };

  //Writer 
  const handleWriter = (selectedValues) => {
    setFormData((prevOptionData) => ({
      ...prevOptionData,
      publisher_songwriter: selectedValues,
    }));

  }
  //Performance
  const handlePerfomance = (selectedValues) => {
    setFormData((prevOptionData) => ({
      ...prevOptionData,
      publisher_musician: selectedValues,
    }));
  }

  //Handle Image upload
  // const handleCoverImageChange = (event) => {
  //   const selectedFile = event.target.files;
  //   if (selectedFile.length > 0) {
  //     const newFile = selectedFile[0];
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setCoverImageFile(newFile);
  //     };
  //     reader.readAsDataURL(newFile);
  //   } else {
  //   }
  // };

  // const handlePlayerImageChange = (event) => {
  //   const selectedFile = event.target.files;
  //   if (selectedFile.length > 0) {
  //     const newFile = selectedFile[0];
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setPlayerImageFile(newFile);
  //     };
  //     reader.readAsDataURL(newFile);
  //   } else {
  //   }
  // };

  // const handleTvImageChange = (event) => {
  //   const selectedFile = event.target.files;
  //   if (selectedFile.length > 0) {
  //     const newFile = selectedFile[0];
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setTvImageFile(newFile);
  //     };
  //     reader.readAsDataURL(newFile);
  //   } else {
  //   }
  // };

  const handleCoverImageChange = async (event, errorKey) => {
    const selectedFile = event.target.files[0]; // Get the first selected file
    setError(prevErrors => ({ ...prevErrors, [errorKey]: '' }));

    // Check if compressResolutionFormat.audio === 1 to decide whether to validate image ratio
    if (compressResolutionFormat.audios === 1 && selectedFile) {
      let isValid;
      try {
        isValid = await validateImageRatio(selectedFile, 9 / 16, 1080, 1920);
      } catch (error) {
        console.error('Error validating image ratio:', error);
        setError(prevErrors => ({ ...prevErrors, [errorKey]: 'Error loading image for validation.' }));
        return;
      }

      if (!isValid) {
        setError(prevErrors => ({ ...prevErrors, [errorKey]: 'Image must be in 9:16 Ratio or 1080x1920px.' }));
        event.target.value = null; // Reset the file input
        return;
      }
    }

    // Proceed to set the image file if validation passed or if validation is not required
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setCoverImageFile(selectedFile);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handlePlayerImageChange = async (event, errorKey) => {
    const selectedFile = event.target.files[0]; // Get the first selected file
    setError(prevErrors => ({ ...prevErrors, [errorKey]: '' }));

    // Check if image validation is needed (example condition: compressResolutionFormat.audios === 1)
    if (compressResolutionFormat.audios === 1 && selectedFile) {
      let isValid;
      try {
        // Validate image ratio and dimensions
        isValid = await validateImageRatio(selectedFile, 16 / 9, 1280, 720);
      } catch (error) {
        console.error('Error validating image:', error);
        setError(prevErrors => ({ ...prevErrors, [errorKey]: 'Error loading image for validation.' }));
        return;
      }

      if (!isValid) {
        setError(prevErrors => ({
          ...prevErrors,
          file2: 'Image must be in 16:9 ratio or 1280x720px.' // Set error specifically for file2
        }));
        event.target.value = null; // Reset the file input
        return;
      }
    }

    // Proceed to set the image file if validation passed or if validation is not required
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setPlayerImageFile(selectedFile);
      };
      reader.readAsDataURL(selectedFile);
    }
  };


  const handleTvImageChange = async (event, errorKey) => {
    const selectedFile = event.target.files[0]; // Get the first selected file
    setError(prevErrors => ({ ...prevErrors, [errorKey]: '' }));

    // Check if image validation is needed (example condition: compressResolutionFormat.audios === 1)
    if (compressResolutionFormat.audios === 1 && selectedFile) {
      let isValid;
      try {
        // Validate image ratio and dimensions
        isValid = await validateImageRatio(selectedFile, 16 / 9, 1280, 720);
      } catch (error) {
        console.error('Error validating image:', error);
        setError(prevErrors => ({ ...prevErrors, [errorKey]: 'Error loading image for validation.' }));
        return;
      }

      if (!isValid) {
        setError(prevErrors => ({
          ...prevErrors,
          file3: 'Image must be in 16:9 ratio or 1280x720px.' // Set error specifically for file2
        }));
        event.target.value = null; // Reset the file input
        return;
      }
    }

    // Proceed to set the image file if validation passed or if validation is not required
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setTvImageFile(selectedFile);
      };
      reader.readAsDataURL(selectedFile);
    }
  };


  //Handle ppv price function
  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ppv_price: defaultOption?.globalOption && defaultOption?.globalOption,
        ppv_status: 1
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ppv_price: "",
        ppv_status: 0
      }));
    }
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    setEditUser({ ...editUser, [e.target.name]: e.target.value });
    setFormDataError((prevFormData) => ({
      ...prevFormData,
      title: "",
      slug: "",
      accesserror: "",
    }));
  };

  const handleInputSwitch = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: formData[e.target.name] == 1 ? 0 : 1,
    }));
    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   [e.target.name]: formData.enable_chats == 1 ? 0 : 1,
    // }));
    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   [e.target.name]: formData.enable_Tips == 1 ? 0 : 1,
    // }));
  }


  const handleSeoInput = (e) => {
    const { name, value } = e.target;
    setSeoFormData((prevSeoFormData) => ({
      ...prevSeoFormData,
      [name]: value,
    }));
    setFormData((prevSeoFormData) => ({
      ...prevSeoFormData,
      [name]: value,
    }));
  }
  //Handle search #tags
  const handleTagsChange = (newTags) => {
    const cleanedTags = newTags
      .map((tag) => tag.trim().toLowerCase())
      .filter((tag) => tag !== "")
      .filter((value, index, self) => self.indexOf(value) == index);
    setFormData((prevFormData) => ({
      ...prevFormData,
      search_tags: cleanedTags,
    }));
  };
  // function end
  const handleUpdate = async (e) => {
    e.preventDefault();
    if (formValidation()) {
      handleAdd();
    } else {
      console.log("Form validation failed ");
    }
  }

  const formValidation = () => {
    let formIsValid = true;
    if (getDangerLyrics) {
      audioRef.current.focus();
      formIsValid = false;
    }
    if (!formData?.access) {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        accesserror: "Please select the access",
      }));
      accessInputRef.current.focus();
      formIsValid = false;
    }
    if (formData?.selected_Language?.length <= 0) {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        languageerror: "Please select at least one Language",
      }));
      languageInputRef.current.focus();
      formIsValid = false;
    }
    if (formData?.selected_categories?.length <= 0) {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        categoryerror: "Please select at least one Category",
      }));
      categoryInputRef.current.focus();
      formIsValid = false;
    }
    if (!formData?.title) {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        title: "Title cannot be empty.",
      }));
      titleInputRef.current.focus();
      formIsValid = false;
    } else if (!formData?.slug) {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        slug: "Slug cannot be empty.",
      }));
      slugInputRef.current.focus();
      formIsValid = false;
    }

    let valid = true;

    if (compressResolutionFormat?.audios === 1) {
      if (!coverImageFile && !formData?.image_url) {
        setError(prevErrors => ({ ...prevErrors, file1: 'Image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }

      if (!playerImageFile && !formData?.Player_thumbnail) {
        setError(prevErrors => ({ ...prevErrors, file2: 'Player image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file2: '' }));
      }

      if (!tvImageFile && !formData?.TV_Thumbnail) {
        setError(prevErrors => ({ ...prevErrors, file3: 'Video TV image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file3: '' }));
      }

    }

    if (!valid) {
      return;
    }

    return formIsValid;
  }

  const handleAdd = async () => {
    setShowOverlay(true);
    try {
      const formDataAdd = new FormData();
      for (const key in formData) {
        if (['title', 'slug', 'ppv_status', 'ppv_price', 'type', 'access', 'album_id', 'artists', 'rating', 'details', 'description', 'active', 'status', 'draft', 'featured', 'banner', 'duration', 'views', 'year', 'albumname', 'age_restrict', 'mp3_url', 'ios_ppv_price', 'uploaded_by', 'image_url', 'Player_thumbnail', 'TV_Thumbnail', 'event_date', 'master_percentage_holding', 'songwriter_percentage_holding'].includes(key)) {
          formDataAdd.append(key, formData[key]);
        }
      }
      formDataAdd.append("artists", 1);
      formDataAdd.append("audio_id", id);
      formDataAdd.append("global_ppv", editUser?.global_ppv);
      formDataAdd.append("languages_id", JSON.stringify(formData?.selected_Language?.map((option) => option?.value)));
      formDataAdd.append("category_id", JSON.stringify(formData?.selected_categories?.map((option) => option?.value)));
      formDataAdd.append("search_tags", formData?.search_tags);
      formDataAdd.append("artist_id", JSON.stringify(formData?.selected_artists?.map((option) => option?.value)));
      formDataAdd.append("publisher_musician", JSON.stringify(formData?.publisher_musician?.map((option) => option?.value)));
      formDataAdd.append("publisher_songwriter", JSON.stringify(formData?.publisher_songwriter?.map((option) => option?.value)));
      formDataAdd.append("available_countries_id", JSON.stringify(formData?.available_countries?.map((option) => option?.value)));
      formDataAdd.append("country", JSON.stringify(formData?.selected_block_countries?.map((option) => option?.value)));
      formDataAdd.append("ads_position", JSON.stringify(formData?.available_countries?.map((option) => option?.value)));

      const response = await axios.post(`${audioDefaultURL?.updateAudio}/${id}`, formDataAdd, { headers: adminApiHeader });
      const result = response?.data;
      if (result?.status) {
        if (seoFormData?.website_page_title || seoFormData?.website_URL || seoFormData?.Meta_description) {
          await handleSeoUpload()
        }
        if (coverImageFile || playerImageFile || tvImageFile) {
          await handleImageUpload()
        }
        if (getLyrics) {
          await handleLyricsUpload()
        }
        setShowOverlay(false);
        toast.success(result?.message)
        // if (audioCheck) {
        navigate(`/update-song-writer-percentage-holding/${id}`);
        // } else {
        //   navigate("/audio-lists");
        // }
      }
      else {
        setShowOverlay(false);
        toast.error(result?.message)
      }
    }
    catch (err) {
      setShowOverlay(false);
      const errorMessage = err?.response?.data?.message
      toast.error(errorMessage)
      console.error("error", err)
    }
  }


  const handleSeoUpload = async () => {
    try {
      const formData = new FormData();
      for (const key in seoFormData) {
        formData.append(key, seoFormData[key]);
      }
      formData.append("audio_id", id);
      const response = await axios.post(`${audioDefaultURL?.uploadSEO}`, formData, { headers: adminApiHeader });
      if (!response?.data?.status) {
        toast.error(response?.data?.message)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleImageUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("audio_id", id);
      formData.append("image", coverImageFile);
      formData.append("player_image", playerImageFile);
      formData.append("tv_image", tvImageFile);
      const response = await axios.post(`${audioDefaultURL?.uploadImageURL}`, formData, { headers: adminApiHeader });
      if (!response?.data?.status) {
        toast.error(response?.data?.message)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleLyricsUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("lyrics", getLyrics);
      formData.append("audio_id", id);
      const response = await axios.post(`${audioDefaultURL?.uploadLyrics}`, formData, { headers: adminApiHeader });
      if (!response?.data?.status) {
        toast.error(response?.data?.message)
      }
    }
    catch (err) {
      console.error(err)
    }
  }

  const handleLyrics = async (event) => {
    setSuccessLyrics(null);
    setDangerLyrics(null);
    const selectedFile = event.target.files;
    if (selectedFile) {
      const newFile = selectedFile[0];
      setLyrics(newFile);
      try {
        const lyricsDatas = new FormData();
        lyricsDatas.append("lyrics", newFile);
        const response = await axios.post(`${audioDefaultURL?.validateLyrics}`, lyricsDatas, { headers: adminApiHeader });
        let result = response?.data;
        if (result?.status == true) {
          setSuccessLyrics(result?.message);
        } else {
          setSuccessLyrics(null);
          setDangerLyrics(result?.message);
        }
      } catch (err) {
        var errorMessage = err?.response?.data?.message;
        setSuccessLyrics(null);
        setDangerLyrics(errorMessage);
      }
    }
  };

  const handleClearFile = () => {
    setLyrics(null);
    setSuccessLyrics(null);
    setDangerLyrics(null);
  };

  useEffect(async () => {
    fetchData();
    audioContent()
  }, []);




  // {ppv price with global and customs} 
  // {ppv price with global and customs} 

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [step, setStep] = useState(1);
  const [formDataglobalprice, setFormDataglobalprice] = useState({
    priceType: allCurrencylistfromappichoosed?.code,  // Default value for global price
    price: ppv_gobal_price,
    convertedPrices: []
  });
  const [formDatacustomprice, setFormDatacustomprice] = useState({
    priceType: allCurrencylistfromappichoosed?.code,  // Default value for custom price
    price: editUser?.ppv_price,
    convertedPrices: []
  });



  const handleChange = (e) => {
    const { value } = e.target;
    if (value === "1") {
      setEditUser((prevState) => ({
        ...prevState,
        global_ppv: "1",
      }));
    } else if (value === "2") {
      setEditUser((prevState) => ({
        ...prevState,
        global_ppv: null,
      }));
    }

    setModalType(e.target.value);
    setShowModal(true);
    setStep(1);
  };

  const handleClose = () => {
    setShowModal(false);
    setFormDataglobalprice({
      priceType: allCurrencylistfromappichoosed?.code,
      price: ppv_gobal_price,
      convertedPrices: []
    });
    setFormDatacustomprice({
      priceType: allCurrencylistfromappichoosed?.code,
      price: editUser?.ppv_price,
      convertedPrices: []
    });
  };

  const handleNext = async () => {
    if (step === 1) {
      const formData = modalType === '1' ? formDataglobalprice : formDatacustomprice;

      if (!formData?.priceType || !formData?.price) {
        toast.error('Please fill in the required fields.')
        return;
      }

      try {
        if (modalType === '2' && formData?.price !== editUser?.ppv_price) {
          const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${formData?.priceType}`);
          const rates = response?.data.rates;

          const convertedPrices = allCurrencylistfromappi?.map(item => {
            let country_name = item?.country;
            let Symbol = item?.Symbol || item?.symbol;
            let Code = item?.code;

            const matchingCountry = allCurrencylistfromappi.find(currency => currency.code === item?.code);
            if (matchingCountry) {
              country_name = matchingCountry?.country;
              Symbol = matchingCountry?.Symbol || matchingCountry?.symbol;
            }

            return {
              Code: Code,
              country_name: country_name,
              Symbol: Symbol,
              convertedPrice: (formData?.price * (rates[item?.code] || 0)).toFixed(2),
              price: formData?.price,
              difference: ((formData?.price * (rates[item?.code] || 0)) - formData?.price).toFixed(2),
              editPrice: (formData?.price * (rates[item?.code] || 0)).toFixed(2),
              isEditable: true,
            };
          });

          setFormDatacustomprice(prevState => ({
            ...prevState,
            convertedPrices: convertedPrices
          }));
        } else if (modalType === '2' && formData?.price === editUser?.ppv_price) {
          const customPrices = allCurrencycustoms?.map(item => ({
            ...item,
            convertedPrice: item?.ChangedPrice || (formData?.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
            difference: item?.RateDifference || 0,
            isEditable: true
          }));

          setFormDatacustomprice(prevState => ({
            ...prevState,
            convertedPrices: customPrices
          }));
        } else if (modalType === '1') {
          const globalPrices = allCurrencyglobal?.map(item => ({
            ...item,
            convertedPrice: item?.ChangedPrice || (formData?.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
            isEditable: false
          }));
          setFormDataglobalprice(prevState => ({
            ...prevState,
            convertedPrices: globalPrices
          }));
        }

        setStep(2);
      } catch (error) {
        console.error('Error converting currency:', error);
      }
    }
  };


  const handleSubmit = async () => {
    const formData = modalType === '1' ? formDataglobalprice : formDatacustomprice;

    if (!formData || formData?.convertedPrices.length === 0) {
      alert('Please fill in all the fields.');
      return;
    }

    // Prepare data to submit
    const dataToSubmit = {
      source_type: 5, // Note - 1 (Videos) or 2 (Livestream) or 3 (Series) or 4 (Episode) or 5 (Audios) or 6 season or 7 golbal
      source_id: id,
      PPVType: modalType,
      PriceType: formData?.priceType, // Separate priceType outside of convertedPrices
      IOSPrice: editUser?.ios_ppv_price,
      ActualPrice: formData?.price,
      convertedPriceRates: formData?.convertedPrices.map(item => ({
        Code: item?.Code,
        Country: item?.country_name || item?.Country,
        RateDifference: item?.difference || item?.RateDifference,
        ConvertedPriceRate: item?.editPrice || item?.convertedPrice,
        ChangedPrice: item?.editPrice || item?.convertedPrice,
        Symbol: item?.Symbol || item?.symbol,
      }))
    };

    let firstApiStatus = false;
    let resultapi = {};
    let resjson = {};

    try {
      const res = await fetch(`${process.env.REACT_APP_Baseurl}/admin/Audio/PayPerViewPrice/StoreUpdate`, {
        method: "POST",
        headers: adminApiHeader,
        body: JSON.stringify(dataToSubmit),
      });

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;
        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
      // Optionally show a user-friendly error message
      alert('An error occurred while submitting the form. Please try again.');
    }

    if (firstApiStatus) {
      // props?.setApiresponsealert(resultapi);
      // props?.setShowalert(true);
      toast.success("Audio Updated PPV Price Successfully")
      handleClose();
      setStep(1);
    } else {
      if (resjson?.status === false) {
        // props?.setApiresponsealert(resjson);
        // props?.setShowalert(true);
        toast.error("Audio PPV Price Failed")
      }
    }
  };

  const handleInputChangeeditable = (index, fieldName, value) => {
    const updatedConvertedPrices = [...(modalType === '1' ? formDataglobalprice.convertedPrices : formDatacustomprice.convertedPrices)];
    updatedConvertedPrices[index][fieldName] = value;
    if (modalType === '1') {
      setFormDataglobalprice(prevState => ({
        ...prevState,
        convertedPrices: updatedConvertedPrices
      }));
    } else {
      setFormDatacustomprice(prevState => ({
        ...prevState,
        convertedPrices: updatedConvertedPrices
      }));
    }
  };

  const handleCustomPriceTypeChange = async (event) => {
    const selectedCountryCode = event.target.value;

    if (selectedCountryCode && selectedCountryCode !== allCurrencylistfromappichoosed?.code) {
      try {
        // Fetch conversion rates from API
        const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${selectedCountryCode}`);
        const rates = response.data.rates;

        // Convert and update prices based on the API data
        const convertedPrices = allCurrencylistfromappi?.map(item => {
          let country_name = item?.country;
          let Symbol = item?.Symbol || item?.symbol;
          let Code = item?.code;

          // Find matching country data
          const matchingCountry = allCurrencylistfromappi.find(currency => currency.code === item?.code);
          if (matchingCountry) {
            country_name = matchingCountry?.country;
            Symbol = matchingCountry?.Symbol || matchingCountry?.symbol;
          }

          return {
            Code: Code,
            Country: country_name,
            Symbol: Symbol,
            convertedPrice: (formDatacustomprice.price * (rates[item?.code] || 0)).toFixed(2),
            price: formDatacustomprice.price,
            difference: ((formDatacustomprice.price * (rates[item?.code] || 0)) - formDatacustomprice.price).toFixed(2),
            editPrice: (formDatacustomprice.price * (rates[item?.code] || 0)).toFixed(2),
            isEditable: true,
          };
        });

        setFormDatacustomprice(prevState => ({
          ...prevState,
          priceType: selectedCountryCode,
          convertedPrices: convertedPrices
        }));
      } catch (error) {
        console.error('Error fetching currency conversion rates:', error);
      }
    } else {
      // Set the existing data if allCurrencylistfromappichoosed?.code is selected
      const customPrices = allCurrencycustoms?.map(item => ({
        ...item,
        convertedPrice: item?.ChangedPrice || (formDatacustomprice.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
        difference: item?.RateDifference || 0,
        isEditable: true
      }));

      setFormDatacustomprice(prevState => ({
        ...prevState,
        priceType: allCurrencylistfromappichoosed?.code,
        convertedPrices: customPrices
      }));
    }
  };

  const handlePriceChange = (e, formType) => {
    const { name, value } = e.target;
    if (formType === 'global') {
      setFormDataglobalprice(prevState => ({
        ...prevState,
        [name]: value
      }));
    } else if (formType === 'custom') {
      setFormDatacustomprice(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };




  // {ppv price with global and customs} 
  // {ppv price with global and customs} 



  return (
    <>
      <div className="">
        {showOverlay && (<Response_Processing></Response_Processing>)}
        {loading && (<FetchLoader />)}

        <AdminSaveHeader heading={label_management?.audios[70]?.title} saveText="Update" saveMethod={handleUpdate} />

        <FormContainer>
          <LeftWrapper>
            <Wrapper>

              <MessageHeading text={formData?.title} />
              <MessageLabel text={label_management?.audios[1]?.title} className="mandatorySimple" />
              <MessageSubHeading text={label_management?.audios[1]?.sub_title} />
              <input type="text" name="title" value={formData?.title} onChange={handleInput} placeholder="Title" ref={titleInputRef} onKeyDown={handleInputKeyDowntitle} className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0 mb-3" />
              {formDataError?.title && (<MessageBox text={`${formDataError?.title}`} classname='errorred mb-2 d-block' />)}

              <MessageLabel text={label_management?.audios[2]?.title} />
              <MessageSubHeading text={label_management?.audios[2]?.sub_title} />
              <input type="text" name="slug" id="slug" value={formData?.slug} onChange={handleAutoFillChangetitle} ref={slugInputRef} onBlur={handleBlurTitleEdit} placeholder="slug" className="mb-3 rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0" />
              {formDataError?.slug && (<MessageBox text={`${formDataError?.slug}`} classname='errorred mb-2 d-block' />)}

              <MessageLabel text={label_management?.audios[3]?.title} />
              <MessageSubHeading text={label_management?.audios[3]?.sub_title} />
              <textarea id="description" name="description" value={formData?.description} placeholder="Description" onChange={handleInput} className="rs-input short-description theme-bg-color-secondary custom-placeholder theme-text-color border-0  mb-3" />

              <MessageLabel text={label_management?.audios[4]?.title} />
              <MessageSubHeading text={label_management?.audios[4]?.sub_title} />
              <JoditEditor value={formData?.details} onChange={(value) => { handleJodiEditor(value) }} className="rs-input custom-placeholder theme-bg-color-secondary  custom-placeholder mb-3 theme-text-color border-0" />

              <MessageLabel text={label_management?.audios[5]?.title} />
              <MessageSubHeading text={label_management?.audios[5]?.sub_title} />
              <input type="text" name="duration" value={formData?.duration} maxLength="8" placeholder="duration" onChange={handleInputdata} className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0 mb-3" />

              <MessageLabel text={label_management?.audios[6]?.title} />
              <MessageSubHeading text={label_management?.audios[6]?.sub_title} />
              <input
                type="date"
                name="year"
                id="year"
                value={formData?.year || ''}
                onChange={handleChangeyear}
                className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0 mb-3"
                placeholder="Year"
              />
            </Wrapper>


            <Wrapper>
              <MessageHeading text={label_management?.audios[7]?.title} />
              <MessageLabel text={label_management?.audios[8]?.title} />
              <MessageSubHeading text={label_management?.audios[8]?.sub_title} />
              <select id="rating" name="rating" size="lg" value={formData?.rating} onChange={handleInput} className="form-select custom-placeholder  mb-3 theme-bg-color-secondary theme-text-color border-0">
                <option value="">Choose an Rating</option>
                {Array.from({ length: 10 }).map((data, index) => { return <option value={index + 1} className="theme-bg-color theme-text-color">{index + 1}</option> })}
              </select>

              <MessageLabel text={label_management?.audios[9]?.title} />
              <MessageSubHeading text={label_management?.audios[9]?.sub_title} />
              <select id="age_restrict" name="age_restrict" value={formData?.age_restrict} onChange={handleInput} className="form-select mb-3 custom-placeholder  theme-bg-color-secondary theme-text-color border-0" >
                {defaultOption?.ageOption.length >= 0 && defaultOption?.ageOption?.map((item) => (<option value={item.id} className="theme-bg-color theme-text-color">{item.slug}</option>))}
              </select>

              <MessageLabel text={label_management?.audios[10]?.title} />
              <MessageSubHeading text={label_management?.audios[10]?.sub_title} />
              <select id="album_id" name="album_id" value={formData?.album_id} onChange={handleInput} className="form-select custom-placeholder mb-3 theme-bg-color-secondary theme-text-color border-0"  >
                {defaultOption?.albumOption.length >= 0 && defaultOption?.albumOption?.map((item, key) => (<option value={item?.id} className="theme-bg-color theme-text-color">{item?.albumname}</option>))}
              </select>

              <MessageLabel text={label_management?.audios[11]?.title} />
              <MessageSubHeading text={label_management?.audios[11]?.sub_title} />
              <Select options={defaultOption?.artistsOption.length >= 0 && defaultOption?.artistsOption?.map((option) => ({ value: option?.id, label: option?.artist_name }))} isMulti onChange={handleSelectChangeartists} value={formData?.selected_artists?.map((option) => ({ value: option?.value, label: option?.label }))} className="theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3" />


              <MessageLabel text={label_management?.audios[12]?.title} className='mandatorySimple' />
              <MessageSubHeading text={label_management?.audios[12]?.sub_title} />
              <Select options={defaultOption?.categoryOption.length >= 0 && defaultOption?.categoryOption?.map((option) => ({ value: option?.id, label: option?.name }))} isMulti onChange={handleSelectChangecategory} value={formData?.selected_categories?.map((option) => ({ value: option?.value, label: option?.label }))} ref={categoryInputRef} className="theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3" />
              {formDataError?.categoryerror && (<MessageBox text={`${formDataError?.categoryerror}`} classname='errorred mb-2 d-block' />)}

              <MessageLabel text={label_management?.audios[13]?.title} />
              <MessageSubHeading text={label_management?.audios[13]?.sub_title} />
              <Select options={defaultOption?.languageOption.length >= 0 && defaultOption?.languageOption?.map((option) => ({ value: option?.id, label: option?.name }))} isMulti onChange={handleSelectChangelanguage} value={formData?.selected_Language?.map((option) => ({ value: option?.value, label: option?.label }))} ref={languageInputRef} className="theme-bg-color-secondary custom-placeholder theme-text-color mb-3 border-0" />
              {formDataError?.languageerror && (<MessageBox text={`${formDataError?.languageerror}`} classname='errorred mb-2 d-block' />)}

              <MessageLabel text={label_management?.audios[14]?.title} />
              <MessageSubHeading text={label_management?.audios[14]?.sub_title} />
              <Select options={defaultOption?.blockCountryOption.length >= 0 && defaultOption?.blockCountryOption?.map((option) => ({ value: option?.id, label: option?.country_name }))} isMulti onChange={handleSelectChangeBlockedCountry} value={formData?.selected_block_countries?.map((option) => ({ value: option?.value, label: option?.label }))} className="custom-placeholder theme-bg-color-secondary theme-text-color mb-3 border-0" />

              <MessageLabel text={label_management?.audios[15]?.title} />
              <MessageSubHeading text={label_management?.audios[15]?.sub_title} />
              <Select options={defaultOption?.availableCountryOption.length >= 0 && defaultOption?.availableCountryOption?.map((option) => ({ value: option?.id, label: option?.country_name }))} isMulti onChange={handleSelectChangeAvailable_country} value={formData?.available_countries?.map((option) => ({ value: option?.value, label: option?.label }))} className="custom-placeholder theme-bg-color-secondary theme-text-color  mb-3 border-0" />

              <MessageLabel text={label_management?.audios[16]?.title} />
              <MessageSubHeading text={label_management?.audios[16]?.sub_title} />
              <Select options={defaultOption?.musicianOption.length >= 0 && defaultOption?.musicianOption?.map((option) => ({ value: option?.id, label: option?.artist_name }))} isMulti onChange={handlePerfomance} value={formData?.publisher_musician?.map((option) => ({ value: option?.value, label: option?.label }))} className="custom-placeholder theme-bg-color-secondary theme-text-color mb-3 border-0" />

              <MessageLabel text={label_management?.audios[17]?.title} />
              <MessageSubHeading text={label_management?.audios[17]?.sub_title} />
              <Select options={defaultOption?.writerOption.length >= 0 && defaultOption?.writerOption?.map((option) => ({ value: option?.id, label: option?.artist_name, }))} isMulti onChange={handleWriter} value={formData?.publisher_songwriter?.map((option) => ({ value: option?.value, label: option?.label }))} className="custom-placeholder theme-bg-color-secondary theme-text-color mb-3 border-0" />

              <MessageLabel text={label_management?.audios[18]?.title} />
              <MessageSubHeading text={label_management?.audios[18]?.sub_title} />
              <input type="number" name="songwriter_percentage_holding" value={formData?.songwriter_percentage_holding} onChange={handleInput} placeholder="Songwriter Percentage Holding" className="mb-3 rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0" />

              <MessageLabel text={label_management?.audios[19]?.title} />
              <MessageSubHeading text={label_management?.audios[19]?.sub_title} />
              <input type="number" name="master_percentage_holding" value={formData?.master_percentage_holding} onChange={handleInput} placeholder="Master Percentage Holding" className="mb-3 rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0" />

              <MessageLabel text={label_management?.audios[20]?.title} />
              <MessageSubHeading text={label_management?.audios[20]?.sub_title} />
              <input type="datetime-local" name="event_date" value={formData?.event_date} onChange={handleInput} placeholder="Event Date" className="mb-3 rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0" />
            </Wrapper>

            <Wrapper>
              <MessageHeading text={label_management?.audios[37]?.title} />
              <MessageLabel text={label_management?.audios[38]?.title} />
              <MessageSubHeading text={label_management?.audios[38]?.sub_title} />
              <TagsInput name="search_tags" value={formData?.search_tags} onChange={handleTagsChange} />
            </Wrapper>

          </LeftWrapper>
          <RightWrapper>
            <Wrapper>
              <MessageHeading text={`Audio ${formData?.type}`} />
              <ReactAudioPlayer className="w-100" src={formData?.mp3_url} controls />
            </Wrapper>

            <Wrapper>
              <MessageHeading text={label_management?.audios[21]?.title} />
              <MessageLabel text={label_management?.audios[22]?.title} />
              <div className="d-flex align-items-cneter gap-2 ">
                <MessageBox classname="theme-text-color fw-bold opacity-75 " text="Ex:xlsx" />
                <Link to={defaultOption?.lyricsOption.length >= 0 && defaultOption?.lyricsOption} className="theme-text-color opacity-75" download >{label_management?.audios[22]?.sub_title} </Link>
                {formData?.lyrics && (<a href={formData?.audio_lyrics} className="fw-bold text-info d-block" ><MessageLabel text="Download Uploaded Lyrics File" /> </a>)}
              </div>

              <div className="d-flex flex-wrap justify-content-between gap-2">
                <div className="col-sm-5 col-md-12 col-lg-5 col-12 position-relative pb-2">
                  <div className="imagedrop theme-border-color mt-1 p-1">
                    <input className="opacity-0 h-100 p-0 m-0 " type="file" accept=".xlsx" ref={audioRef} onChange={handleLyrics} key={getLyrics ? getLyrics : "no"} />
                    <label type="button" className="position-absolute  theme-text-color">
                      <BsFiletypeXlsx className=" theme-text-color" />
                    </label>
                  </div>
                </div>

                {(getSuccessLyrics || getDangerLyrics) && (
                  <div className="col-sm-5 col-12 col-md-12 col-lg-5">
                    <div className="imagedrop theme-border-color d-flex mt-1 p-1 justify-content-between">
                      <button type="button" className="w-75 h-100 bg-transparent"><FcFile /></button>
                      <button type="button" className="btn btn-close theme-text-color" onClick={handleClearFile} ></button>
                    </div>
                  </div>
                )}
              </div>

              {getSuccessLyrics && (
                <p className="fw-bold text-success mt-2 theme-text-color admin-input-description"><FcOk className="me-1" />{getSuccessLyrics}</p>
              )}

              {getDangerLyrics && (
                <p className="fw-bold text-danger mt-2 theme-text-color admin-input-description"><FcHighPriority className="me-1" />{getDangerLyrics} </p>
              )}
            </Wrapper>

            <Wrapper>
              <MessageHeading text={label_management?.audios[23]?.title} />
              <MessageLabel text={label_management?.audios[24]?.title} className={compressResolutionFormat?.audios === 1 ? "mandatorySimple" : ""} />
              <MessageSubHeading text={label_management?.audios[24]?.sub_title} />
              {loading ? (<Response_Loading_processing></Response_Loading_processing>) : (<img src={formData?.image_url} alt="Image" className="thumbnails-images" />)}
              <div className="mx-0 my-3 row">
                <div className="col-6 ps-0">
                  <div className="imagedrop theme-border-color" >
                    <input type="file" ref={coverImageRef} onChange={(event) => handleCoverImageChange(event, 'file1')} accept="image/*" className="opacity-0 w-100 h-100" key={coverImageFile ? coverImageFile : "inputKey"} />
                    <label className="theme-text-color position-absolute"><CameraRetroIcon className=" theme-text-color" /> </label>
                  </div>
                </div>
                <div className="col-6 pe-0">
                  <div>
                    {coverImageFile && (
                      <div className="imagedropcopy theme-border-color text-end">
                        <div className="row mx-0">
                          <div className="col-10 p-0 ">
                            <img src={URL?.createObjectURL(coverImageFile)} alt="Uploaded" className="thumbnails-upload-images" />
                          </div>
                          <div className="col-2 p-0" style={{ alignSelf: "center" }}>
                            <button onClick={() => setCoverImageFile(null)} type="button" className="bg-transparent theme-text-color"><IoClose className="custom-close-btn"></IoClose></button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {error?.file1 && <div className="errorred mt-1 p-0">{error?.file1}</div>}
              </div>

              <MessageLabel text={label_management?.audios[25]?.title} className={compressResolutionFormat?.audios === 1 ? "mandatorySimple" : ""} />
              <MessageSubHeading text={label_management?.audios[25]?.sub_title} />
              {loading ? (<Response_Loading_processing></Response_Loading_processing>) : (<img src={formData?.Player_thumbnail} alt="Image" className="thumbnails-images" />)}
              <div className="mx-0 my-3 row">
                <div className="col-6 ps-0">
                  <div className="imagedrop theme-border-color"  >
                    <input type="file" ref={playerImageRef} onChange={(event) => handlePlayerImageChange(event, 'file2')} accept="image/*" className="opacity-0 w-100 h-100" key={playerImageFile ? playerImageFile : "inputKey"} />
                    <label className=" theme-text-color position-absolute">
                      <CameraRetroIcon className=" theme-text-color" />
                    </label>
                  </div>
                </div>
                <div className="col-6 pe-0 ">
                  <div>
                    {playerImageFile && (
                      <div className="imagedropcopy  theme-border-color text-end">
                        <div className="row mx-0 ">
                          <div className="col-10 p-0 ">
                            <img src={URL?.createObjectURL(playerImageFile)} alt="Uploaded" className="thumbnails-upload-images" />
                          </div>
                          <div className="col-2 p-0" style={{ alignSelf: "center" }} >
                            <button onClick={() => setPlayerImageFile(null)} type="button" className="bg-transparent theme-text-color" ><IoClose className="custom-close-btn" /></button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {error?.file2 && <div className="errorred mt-1 p-0">{error?.file2}</div>}
              </div>

              <MessageLabel text={label_management?.audios[26]?.title} className={compressResolutionFormat?.audios === 1 ? "mandatorySimple" : ""} />
              <MessageSubHeading text={label_management?.audios[26]?.sub_title} />
              {loading ? (<Response_Loading_processing></Response_Loading_processing>) : (<img src={formData?.TV_Thumbnail} alt="Image" className="thumbnails-images" />)}
              <div className="mx-0 my-3 row">
                <div className="col-6 ps-0">
                  <div className="imagedrop theme-border-color" >
                    <input type="file" ref={tvImageRef} onChange={(event) => handleTvImageChange(event, 'file3')} accept="image/*" className="opacity-0 w-100 h-100 " key={tvImageFile ? tvImageFile : "inputKey"} />
                    <label className=" theme-text-color position-absolute">
                      <CameraRetroIcon className=" theme-text-color" />
                    </label>
                  </div>
                </div>
                <div className="col-6 pe-0 ">
                  <div>
                    {tvImageFile && (
                      <div className="imagedropcopy theme-border-color text-end">
                        <div className="row mx-0">
                          <div className="col-10 p-0">
                            <img src={URL?.createObjectURL(tvImageFile)} alt="Uploaded" className="thumbnails-upload-images" />
                          </div>
                          <div className="col-2 p-0" style={{ alignSelf: "center" }} >
                            <button onClick={() => setTvImageFile(null)} type="button" className="bg-transparent theme-text-color" ><IoClose className="custom-close-btn" /></button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {error?.file3 && <div className="errorred mt-1 p-0">{error?.file3}</div>}
              </div>
            </Wrapper >

            <Wrapper>
              <MessageHeading text={label_management?.audios[27]?.title} />
              <MessageLabel text={label_management?.audios[28]?.title} />
              <MessageSubHeading text={label_management?.audios[28]?.sub_title} />
              <select defaultValue={formData?.access} onChange={handleInput} name="access" id="access" className="form-select  theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0" ref={accessInputRef} >

                {defaultOption?.accessOption.length >= 0 && defaultOption?.accessOption?.map((item, index) => {
                  let defaultValue = formData?.access == item?.role;
                  return (<option value={item?.role} className="theme-bg-color theme-text-color" selected={defaultValue}>{item?.name}</option>)
                })}
              </select>
              {formDataError?.accesserror && (<MessageBox text={`${formDataError?.accesserror}`} classname='errorred mb-2 d-block' />)}
              {formData?.access == "ppv" ?
                <>
                  {global_ppv_status_active?.PPV_Individual_Contents === "0" || global_ppv_status_active?.PPV_Individual_Contents === 0 ? (
                    <>
                      <MessageLabel text="PPV Price" />
                      <div className="row mx-0 ">
                        <div className="col-8 ps-0">
                          <MessageSubHeading text="Apply PPV Price from Global Settings? " />
                        </div>
                        <div className="col-4 pe-0 text-end">
                          <label className="switch">
                            <input name="ppv_price" id="ppv_price" checked={checkboxChecked} onChange={handleCheckboxChange} type="checkbox" className="rs-input" />
                            <span className="slider round" name="ppv_price" checked={checkboxChecked} onChange={handleCheckboxChange} ></span>
                          </label>
                        </div>
                      </div>
                      <div className="row mx-0 mb-3 ">
                        <input type="number" className="rs-input form-control-lg mt-2 theme-bg-color-secondary theme-text-color border-0" placeholder="PPV Price" name="ppv_price" id="ppv_price" value={formData?.ppv_price} onChange={handleInput} />
                      </div>
                      <MessageLabel text={label_management?.audios[43]?.title} />
                      <MessageSubHeading text={label_management?.audios[43]?.sub_title} />
                      <select onChange={handleInput} className="form-select theme-bg-color-secondary theme-text-color border-0" name="ios_ppv_price" >
                        {defaultOption?.iosOption.length >= 0 && defaultOption?.iosOption?.map((item, key) => (<option value={item?.id} className="theme-text-color theme-bg-color">{item?.plan_price}</option>))}
                      </select>
                    </>
                  ) :
                    <div className="mt-2">
                      <div className="col-sm-12">
                        <div className='modalofglobalppv'>
                          <Form>
                            <>
                              {global_ppv_status_active?.ppv_status === "1" || global_ppv_status_active?.ppv_status === 1 ? (
                                <Form.Check
                                  type="radio"
                                  label={label_management?.audios[39]?.title}
                                  name="priceOption"
                                  value="1"
                                  checked={editUser?.global_ppv === "1" || editUser?.global_ppv === 1}
                                  // onChange={handleChange}                            
                                  onClick={() => handleChange({ target: { value: '1' } })}
                                />
                              ) : null}
                            </>
                            <Form.Check
                              type="radio"
                              label={label_management?.audios[44]?.title}
                              name="priceOption"
                              value="2"
                              checked={editUser?.global_ppv === null || (editUser?.global_ppv !== "1" && editUser?.global_ppv !== 1)}
                              // onChange={handleChange}

                              onClick={() => handleChange({ target: { value: '2' } })}
                            />
                          </Form>
                          <Modal show={showModal} onHide={handleClose} dialogClassName="modal-lg modal-dialog-scrollable" backdrop="static"
                            keyboard={false}>
                            <Modal.Header closeButton>
                              <Modal.Title>{modalType === '1' ? `${label_management?.audios[40]?.title}` : `${label_management?.audios[45]?.title}`}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="modal-body-custom">
                              {step === 1 ? (
                                <Form>
                                  <Form.Group>
                                    <Form.Label
                                      className="mandatorySimple">{modalType === '1' ? `${label_management?.audios[41]?.title}` : `${label_management?.audios[46]?.title}`}</Form.Label>
                                    <Form.Control
                                      type="number"
                                      name="price"
                                      value={modalType === '1' ? formDataglobalprice.price : formDatacustomprice.price}
                                      onChange={(e) => handlePriceChange(e, modalType === '1' ? 'global' : 'custom')}
                                      disabled={modalType === '1'}
                                    />
                                  </Form.Group>
                                  {modalType === '2' && (
                                    <Form.Group>
                                      <Form.Label
                                        className="mandatorySimple">{label_management?.audios[42]?.title}</Form.Label>
                                      <Form.Control
                                        as="select"
                                        name="priceType"
                                        value={formDatacustomprice.priceType}
                                        onChange={handleCustomPriceTypeChange}
                                        disabled
                                      >
                                        <option value="">Select Countries</option>
                                        {allCurrencylistfromappi?.map(item => (
                                          <option key={item?.code} value={item?.code}>{item?.country}</option>
                                        ))}
                                      </Form.Control>
                                    </Form.Group>
                                  )}
                                  {modalType === '1' && (
                                    <Form.Group>
                                      <Form.Label
                                        className="mandatorySimple">{label_management?.audios[42]?.title}</Form.Label>
                                      <Form.Control
                                        as="select"
                                        name="priceType"
                                        value={formDatacustomprice.priceType}
                                        onChange={handleCustomPriceTypeChange}
                                        disabled
                                      >
                                        <option value="">Select Countries</option>
                                        {allCurrencylistfromappi?.map(item => (
                                          <option key={item?.code} value={item?.code}>{item?.country}</option>
                                        ))}
                                      </Form.Control>
                                    </Form.Group>
                                  )}
                                  {/* <div className='text-end mt-2'>
                         <Button variant="primary" onClick={handleNext}>
                           Next
                         </Button>
                       </div> */}

                                  <div className="col-sm-12 mt-2">
                                    <label className="m-0">{label_management?.audios[43]?.title}</label>
                                    <p className="p1">{label_management?.audios[43]?.sub_title}</p>
                                    <div className="panel-body ppv_price_ios mt-2">
                                      <select
                                        name="ios_ppv_price"
                                        id="ios_ppv_price"
                                        onChange={handleInput}
                                        value={formData?.ios_ppv_price}
                                        className="form-select"
                                      >
                                        <option value="">Select IOS Price</option>
                                        {inapppurchase?.map((item, key) => (
                                          <option
                                            key={key}
                                            value={item?.product_id}
                                            selected={item?.product_id === formData?.ios_ppv_price}
                                          >
                                            {item?.plan_price}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>

                                </Form>
                              ) : (
                                <>
                                  <div className="table-responsive">
                                    <Table striped bordered hover>
                                      <thead>
                                        <tr>
                                          <th>Country</th>
                                          <th>Current Price</th>
                                          <th>Converted Price</th>
                                          <th>Difference</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {(modalType === '1' ? formDataglobalprice.convertedPrices : formDatacustomprice.convertedPrices).map((item, index) => (
                                          <tr key={index}>
                                            <td>{item?.country_name || item?.Country} ({item?.Code})</td>
                                            <td>{item?.Symbol || item?.symbol} {item?.price || item?.ActualPrice}</td>
                                            <td>
                                              <Form.Control
                                                type="number"
                                                value={item?.editPrice !== undefined ? item?.editPrice : item?.convertedPrice}
                                                onChange={(e) => handleInputChangeeditable(index, 'editPrice', e.target.value)}
                                                disabled={modalType === '1'}
                                              />
                                            </td>
                                            <td>{item?.difference || item?.RateDifference}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </Table>
                                  </div>
                                  {/* <div className='text-end mt-2'>
                         <Button variant="secondary" onClick={() => setStep(1)}>
                           Previous
                         </Button>
                         <Button variant="primary" onClick={handleSubmit}>
                           Submit
                         </Button>
                       </div> */}
                                </>
                              )}
                            </Modal.Body>

                            <hr></hr>
                            {step === 1 ? (

                              <div className='text-end p-2'>
                                <Button variant="primary" onClick={handleNext}>
                                  Next
                                </Button>
                              </div>
                            ) : (
                              <div className='text-end p-2'>
                                <Button variant="secondary" onClick={() => setStep(1)}>
                                  Previous
                                </Button>
                                <Button variant="primary" onClick={handleSubmit} className='ms-2'>
                                  Submit
                                </Button>
                              </div>
                            )}

                          </Modal>
                        </div>
                      </div>
                    </div>
                  }
                </>
                : ""}
            </Wrapper>

            <Wrapper>
              <MessageHeading text={label_management?.audios[29]?.title} />
              <MessageLabel text={label_management?.audios[30]?.title} />
              <MessageSubHeading text={label_management?.audios[30]?.sub_title} />
              <input type="text" name="website_page_title" value={formData?.website_page_title} onChange={handleSeoInput} placeholder="website title" className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0" />

              <MessageLabel text={label_management?.audios[31]?.title} />
              <MessageSubHeading text={label_management?.audios[31]?.sub_title} />
              <input type="text" name="website_URL" value={formData?.website_URL} onChange={handleSeoInput} placeholder="website url" className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0" />

              <MessageLabel text={label_management?.audios[32]?.title} />
              <MessageSubHeading text={label_management?.audios[32]?.sub_title} />
              <textarea type="text" name="Meta_description" rows="5" value={formData?.Meta_description} onChange={handleSeoInput} placeholder="meta description" className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"></textarea>
            </Wrapper>

            <Wrapper>
              <MessageHeading text={label_management?.audios[33]?.title} />
              <div className="row mx-0">
                <div className="col-9 ps-0">
                  <MessageLabel text={label_management?.audios[34]?.title} />
                </div>
                <div className="col-3 text-end">
                  <label className="switch">
                    <input name="featured" onChange={(e) => handleInputSwitch(e)} defaultChecked={formData?.featured == 1 ? true : false} checked={formData?.featured == 1 ? true : false} type="checkbox" className="rs-input" />
                    <span name="featured" onChange={(e) => handleInputSwitch(e)} value={formData?.featured == 1 ? "1" : "0"} className="slider round" ></span>
                  </label>
                </div>
              </div>

              <div className="row mx-0">
                <div className="col-9 ps-0">
                  <MessageLabel text={label_management?.audios[35]?.title} />
                </div>
                <div className="col-3 text-end">
                  <label className="switch">
                    <input name="active" onChange={(e) => handleInputSwitch(e)} defaultChecked={formData?.active == 1 ? true : false} checked={formData?.active == 1 ? true : false} className="rs-input" type="checkbox" />
                    <span name="active" onChange={(e) => handleInputSwitch(e)} value={formData?.active == 1 ? "1" : "0"} className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="row mx-0">
                <div className="col-9 ps-0">
                  <MessageLabel text={label_management?.audios[36]?.title} />
                </div>
                <div className="col-3 text-end">
                  <label className="switch">
                    <input name="banner" onChange={(e) => handleInputSwitch(e)} defaultChecked={formData?.banner == 1 ? true : false} checked={formData?.banner == 1 ? true : false} type="checkbox" className="r-input" />
                    <span name="banner" onChange={(e) => handleInputSwitch(e)} value={formData?.banner == 1 ? "1" : "0"} className="slider round" ></span>
                  </label>
                </div>
              </div>
            </Wrapper>
          </RightWrapper>
          <AdminCustomButton saveMethod={handleUpdate} saveText={label_management?.audios[69]?.title} className="ms-auto" />
        </FormContainer >
      </div >
    </>
  );
}

export default Editnewaudio;