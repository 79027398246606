import React, { useState, useCallback, useEffect, useRef } from "react";
// import { Modal, Button, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import JoditEditor from "jodit-react";
import Dropzone from "react-dropzone";
import Select from "react-select";
import { Modal, Form, Table, Button } from 'react-bootstrap';
import { toast } from "react-toastify";
import { TagsInput } from "react-tag-input-component";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import "./EditSeriesList.css";
import deleteitem from "../../../components/Images/deleteitem.svg";
import edititem from "../../../components/Images/edititem.svg";
import useCompressImageConfig from "../../../components/CommonApis/Compressimageapi";
import usePpvGlobalImageConfig from "../../../components/CommonApis/ppv_global_change";
import NavigationData from "../../../layout/Sidebar/NavigationData";


function EditSeriesList(props) {
  const { id } = useParams();
  var seriesid = id;
  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
    id: "",
    genre_id: "",
    user_id: "",
    type: "",
    access: "",
    active: "",
    ppv_status: "",
    status: "",
    draft: "",
    ios_ppv_price: "",
    ppv_price: "",
    details: "",
    description: "",
    featured: "",
    duration: "",
    views: "",
    rating: "",
    image: "",
    player_image: "",
    tv_image: "",
    embed_code: "",
    mp4_url: "",
    webm_url: "",
    ogg_url: "",
    language: "",
    year: "",
    trailer: "",
    banner: "",
    search_tag: "",
    series_trailer: "",
    season_trailer: "",
    uploaded_by: "",
    url: "",
    Thumbnail: "",
    deleted_at: "",
    Player_thumbnail: "",
    TV_Thumbnail: "",
    image_url: "",
    website_page_title: "",
    website_URL: "",
    Meta_description: "",
    ppv_gobal_price: "",
    global_ppv: "",
  });
  const [editname, setEditname] = useState({
    title: "",
  });
  const [editUsereditUser, setEditUsereditUser] = useState({
    title: "",
    slug: "",
    ppv_price: "",
    source_type: "",
    PPVType: "",
    global_ppv: "",
    ios_ppv_price: "",
  });

  const handleInputsetEditUsereditUser = (e) => {
    const { name, value } = e.target;
    setEditUsereditUser((prevEditUsereditUser) => ({
      ...prevEditUsereditUser,
      [name]: value,
    }));

    setEditUsereditUser({ ...editUsereditUser, title: e.target.value });
    setEditUsereditUser({ ...editUsereditUser, [e.target.name]: e.target.value });

  };


  const { label_management } = NavigationData();
  const access_token = localStorage.getItem("access_token");
  const [manageseason, setManageseason] = useState([]);
  const [editseason, setEditseason] = useState([]);
  const [user_access, setUser_access] = useState([]);
  const [ios_ppv_pricename, setIos_ppv_pricename] = useState([]);
  const [useraccessseason, setUserAccessseason] = useState([]);
  const [inapppurchase, setInappPurchase] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);

  const [loading, setLoading] = useState(true);

  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");

  const handleTagsChange = (newTags) => {
    setTags(newTags);
  };

  const [publishType, setPublishType] = useState("publish_now");
  const [publishTime, setPublishTime] = useState("");
  const [formDatapublishedit, setFormDatapublishedit] = useState(
    new FormData()
  );


  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setEditUser((prevState) => {
        return { ...prevState, ppv_price: inputValueAPI };
      });
    } else {
      setEditUser((prevState) => {
        return { ...prevState, ppv_price: "" };
      });
    }
  };

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value; // setInputValue(event.target.value);
    setEditUser((prevState) => {
      return { ...prevState, [name]: value };
    });
  };


  const [checkboxCheckedseason, setcheckboxCheckedseason] = useState(false);
  const [inputValueseason, setInputValueseason] = useState("");
  const [inputValueAPIseason, setInputValueAPIseason] = useState("");

  const handleCheckboxChangeseason = () => {
    setcheckboxCheckedseason(!checkboxCheckedseason);
    if (!checkboxCheckedseason) {
      setInputValueseason(inputValueAPIseason);
    } else {
      setInputValueseason("");
    }
  };

  const handleInputChangeseason = (event) => {
    setInputValueseason(event.target.value);
  };


  const [selectedFile, setSelectedFile] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    // Handle file drop here
    const file = acceptedFiles[0];
    setSelectedFile(file);
  }, []);

  const handleFileChangessss = (event) => {
    // Handle file selection from file input
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleChangeyear = (event) => {
    const value = event.target.value;
    setEditUser({ ...editUser, year: value });
  };

  const handleInputdata = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });
  };

  const handleChangeFirst = (e) => {
    setEditUser({ ...editUser, details: e });
    // console.log(e, "onchnageddddd");
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };


  const [datanewppvpprice, setDatanewppvpprice] = useState();
  const [ppv_gobal_price, setppv_gobal_price] = useState();
  const [allCurrencyglobal, setAllCurrencyglobal] = useState([]);
  const [allCurrencycustoms, setAllCurrencycustoms] = useState([]);
  const [allCurrencylistfromappichoosed, setAllCurrencylistfromappichoosed] = useState();
  const { allCurrencylistfromappi, globalPpvStatusActive } = usePpvGlobalImageConfig();
  const global_ppv_status_active = globalPpvStatusActive


  // MULTI SELECT NEW
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  // const [optionsrelated_video, setOptionsrelated_video] = useState([]);
  const [selectedValuesrelated_video, setSelectedValuesrelated_video] =
    useState([]);

  const [optionsartists, setOptionsartists] = useState([]);
  const [selectedValuesartists, setSelectedValuesartists] = useState([]);

  const [optionscategories, setOptionscategories] = useState([]);
  const [selectedValuescategories, setSelectedValuescategories] = useState([]);

  const [optionslanguages, setOptionslanguages] = useState([]);
  const [selectedValueslanguages, setSelectedValueslanguages] = useState([]);

  const [optionsavailable_country, setOptionsAvailable_country] = useState([]);
  const [selectedValuesavailable_country, setSelectedValuesAvailable_country] =
    useState([]);

  const [optionsAge_Restrict, setOptionsAge_Restrict] = useState([]);
  const [selectedValuesAge_Restrict, setSelectedValuesAge_Restrict] = useState(
    []
  );

  const [formData, setFormData] = useState({
  });

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/series/edit/${id}`,
        { headers: headers }
      );

      const data = response?.data?.Series[0]?.block_country_id;
      const dataavailable_country =
        response?.data?.Series[0]?.available_countries;
      const dataartists = response?.data?.Series[0]?.Seriesartist;
      const datacategories = response?.data?.Series[0]?.SeriesCategory;
      const datalanguages = response?.data?.Series[0]?.SeriesLanguage;
      const dataAge_Restrict = response?.data?.Series[0]?.selected_Age_Restrict;

      var DefaultGlobalContentPriceRates = response?.data?.GlobalContentPriceRates;
      setAllCurrencyglobal(DefaultGlobalContentPriceRates);
      var DefaultContentPriceRates = response?.data?.ContentPriceRates;
      setAllCurrencycustoms(DefaultContentPriceRates);
      const selectedData = data;
      const formattedOptions = selectedData?.map((item) => ({
        value: item?.id,
        label: item?.country_name,
      }));

      const formattedOptionsavailable_country = dataavailable_country?.map(
        (item) => ({
          value: item?.id,
          label: item?.country_name,
        })
      );
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item?.artist_id,
        label: item?.name,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item?.category_id,
        label: item?.name,
      }));
      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item?.language_id,
        label: item?.name,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      // const formattedOptionsrelated_video = datarelated_video?.map((item) => ({
      //   value: item?.id,
      //   label: item?.id,
      // }));

      // Set the initial selected values
      setSelectedValues(formattedOptions);
      setOptions(formattedOptions);

      // setSelectedValuesrelated_video(formattedOptionsrelated_video);
      // setOptionsrelated_video(formattedOptionsrelated_video);

      setSelectedValuesAvailable_country(formattedOptionsavailable_country);
      setOptionsAvailable_country(formattedOptionsavailable_country);

      setSelectedValuesartists(formattedOptionsartists);
      setOptionsartists(formattedOptionsartists);

      setSelectedValuescategories(formattedOptionscategories);
      setOptionscategories(formattedOptionscategories);

      setSelectedValueslanguages(formattedOptionslanguages);
      setOptionslanguages(formattedOptionslanguages);

      setSelectedValuesAge_Restrict(formattedOptionsAge_Restrict);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
      setLoading(false);
      setTags(
        response?.data?.Series[0]?.search_tag.split(",").map((tag) => tag.trim())
      );
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/series/create`,
        { headers: headers }
      );
      const data = response?.data?.Block_Country;
      const dataavailable_country = response?.data?.Available_country;
      const dataartists = response?.data?.artists;
      const datacategories = response?.data?.series_categories;
      const datalanguages = response?.data?.languages;
      const dataAge_Restrict = response?.data?.Age_Restrict;
      // setSelectedLanguages(data)
      // console.log(data);
      var result = response?.data?.user_access;
      var InappPurchase = response?.data?.InappPurchase;
      setUser_access(result);
      setInappPurchase(InappPurchase);
      setIos_ppv_pricename(InappPurchase);
      const globelset = response?.data?.ppv_gobal_price;
      setInputValueAPI(globelset);
      setInputValueAPIseason(globelset);


      var Defaultppv_gobal_price = response?.data?.ppv_gobal_price;
      setppv_gobal_price(Defaultppv_gobal_price)
      var DefaultCurrencytest = response?.data?.DefaultCurrency[0];
      setAllCurrencylistfromappichoosed(DefaultCurrencytest);

      const selectedData = data;
      const formattedOptions = selectedData?.map((item) => ({
        value: item?.id,
        label: item?.country_name,
      }));
      const formattedOptionsavailable_country = dataavailable_country?.map(
        (item) => ({
          value: item?.id,
          label: item?.country_name,
        })
      );
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item?.id,
        label: item?.artist_name,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      // const formattedOptionsrelated_video = datarelated_video.map((item) => ({
      //   value: item?.id,
      //   label: item?.id,
      // }));

      // Set the options for the multi-select component
      setOptions(formattedOptions);
      setOptionsAvailable_country(formattedOptionsavailable_country);
      setOptionsartists(formattedOptionsartists);
      setOptionscategories(formattedOptionscategories);
      setOptionslanguages(formattedOptionslanguages);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      // setOptionsrelated_video(formattedOptionsrelated_video);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(async () => {
    fetchData();
  }, []);
  // const handleLanguageChange = (event) => {
  //   const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
  //   setSelectedLanguages(selectedOptions);
  // };

  const handleSelectChange = (selectedOptions) => {
    const block_country = selectedOptions?.map((option) => option?.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      block_country: block_country,
    });

    setSelectedValues(selectedOptions);
  };
  const handleSelectChangeavailable_country = (
    selectedOptionsavailable_country
  ) => {
    const available_country = selectedOptionsavailable_country?.map(
      (option) => option?.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      available_country: available_country,
    });

    setSelectedValuesAvailable_country(selectedOptionsavailable_country);
  };
  const handleSelectChangeartists = (selectedOptionsartists) => {
    const artists = selectedOptionsartists?.map((option) => option?.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      artists: artists,
    });

    setSelectedValuesartists(selectedOptionsartists);
  };

  const handleSelectChangecategories = (selectedOptionscategories) => {
    const categories = selectedOptionscategories?.map(
      (option) => option?.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      categories: categories,
    });

    setIsValidationHiddencategory(true);
    setSelectedValuescategories(selectedOptionscategories);
  };

  const handleSelectChangerelated_video = (selectedOptionsrelated_video) => {
    const related_video = selectedOptionsrelated_video?.map(
      (option) => option?.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      related_video: related_video,
    });

    setSelectedValuesrelated_video(selectedOptionsrelated_video);
  };

  const handleSelectChangelanguages = (selectedOptionslanguages) => {
    const languages = selectedOptionslanguages?.map((option) => option?.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      languages: languages,
    });

    setIsValidationHiddenlanguages(true);
    setSelectedValueslanguages(selectedOptionslanguages);
  };
  const handleSelectChangeAge_Restrict = (selectedOptionsAge_Restrict) => {
    const Age_Restrict = selectedOptionsAge_Restrict?.map(
      (option) => option?.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      Age_Restrict: Age_Restrict,
    });

    setSelectedValueslanguages(selectedOptionsAge_Restrict);
  };

  // MULTI SELECT NEW

  const { compressResolutionFormat } = useCompressImageConfig();

  const [error, setError] = useState({
    file1: '',
    file2: '',
    file3: '',
    file4: '',
  });

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);

  // const handleFileChangesss = (event, fileInputRef, setSelectedFile) => {
  //   const file = event.target.files[0];
  //   setSelectedFile(file);
  // };

  const validateImageRatio = (file, expectedRatio, expectedWidth, expectedHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const ratio = width / height;
        const expectedRatioPrecision = 0.01; // Allow for some minor floating-point precision issues
        const ratioMatch = Math.abs(ratio - expectedRatio) < expectedRatioPrecision;
        const dimensionMatch = (width === expectedWidth && height === expectedHeight);
        if (ratioMatch || dimensionMatch) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
      img.onerror = () => {
        reject(new Error('Error loading image'));
      };
    });
  };


  const handleFileChange = async (event, fileInputRef, setSelectedFile, errorKey) => {
    const file = event.target.files[0];
    setError(prevErrors => ({ ...prevErrors, [errorKey]: '' }));

    if (compressResolutionFormat?.series === 1) {
      let isValid = false;

      if (fileInputRef === fileInputRef1) {
        isValid = await validateImageRatio(file, 9 / 16, 1080, 1920);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file1: 'Image must be in 9:16 Ratio or 1080x1920px.' }));
          fileInputRef.current.value = null;
          return;
        }
      } else if (fileInputRef === fileInputRef2) {
        isValid = await validateImageRatio(file, 16 / 9, 1280, 720);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file2: 'Image must be in 16:9 Ratio or 1280x720px.' }));
          fileInputRef.current.value = null;
          return;
        }
      } else if (fileInputRef === fileInputRef3) {
        isValid = await validateImageRatio(file, 16 / 9, 1280, 720);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file3: 'Image must be in 16:9 Ratio or 1280x720px.' }));
          fileInputRef.current.value = null;
          return;
        }
      } else if (fileInputRef === fileInputRef4) {
        isValid = await validateImageRatio(file, 16 / 9, 1280, 720);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file4: 'Image must be in 16:9 Ratio or 1280x720px.' }));
          fileInputRef.current.value = null;
          return;
        }
      }
    }

    setSelectedFile(file);
    setError(prevErrors => ({ ...prevErrors, [errorKey]: '' }));
  };


  const handleDelete = (index) => {
    setError('');
    switch (index) {
      case 1:
        setSelectedFile1(null);
        if (fileInputRef1.current) {
          fileInputRef1.current.value = null;
        }
        break;
      case 2:
        setSelectedFile2(null);
        if (fileInputRef2.current) {
          fileInputRef2.current.value = null;
        }
        break;
      case 3:
        setSelectedFile3(null);
        if (fileInputRef3.current) {
          fileInputRef3.current.value = null;
        }
        break;
      case 4:
        setSelectedFile4(null);
        if (fileInputRef4.current) {
          fileInputRef4.current.value = null;
        }
        break;
      default:
        break;
    }
  };

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    // console.log(selectedValue5); // Selected value
  };



  const [series, setSeries] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [ppv_gobal_priceppv_gobal_price, setppv_gobal_priceppv_gobal_price] = useState();
  const [allCurrencyglobalallCurrencyglobal, setallCurrencyglobalallCurrencyglobal] = useState([]);
  const [allCurrencycustomsallCurrencycustoms, setallCurrencycustomsallCurrencycustoms] = useState([]);
  const [allCurrencylistfromappichoosedallCurrencylistfromappichoosed, setallCurrencylistfromappichoosedallCurrencylistfromappichoosed] = useState();


  useEffect(async () => {
    const fetchAdsPositions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/admin/series/create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response?.json();
          // console.log(data);

          // const adsPositionOptions = data?.ads_position?.map((position) => ({
          //   value: position?.position,
          //   label: position?.name,
          // }));
          // console.log(adsPositionOptions);
          // setAdsPositions(adsPositionOptions);
        } else {
          throw new Error("Request failed with status: " + response?.status);
        }
      } catch (error) {
        // console.log("Error fetching ads positions:", error);
      }
    };
    fetchAdsPositions();

    const indexseason = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/series_season/create`,
        { headers: headers }
      );
      const resData = await reqData?.json();

      var result = resData?.user_access;
      // console.log(result)
      // console.log(resData)
      setUserAccessseason(result);


      var Defaultppv_gobal_priceppv_gobal_price = resData?.ppv_gobal_price;
      setppv_gobal_priceppv_gobal_price(Defaultppv_gobal_priceppv_gobal_price)
      var DefaultCurrencytest = resData?.DefaultCurrency[0];
      setallCurrencylistfromappichoosedallCurrencylistfromappichoosed(DefaultCurrencytest);
      var DefaultGlobalContentPriceRates = resData?.GlobalContentPriceRates;
      setallCurrencyglobalallCurrencyglobal(DefaultGlobalContentPriceRates);
      var DefaultContentPriceRates = resData?.ContentPriceRates;
      setallCurrencycustomsallCurrencycustoms(DefaultContentPriceRates);
    };
    indexseason();

    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/series/edit/${seriesid}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.Series[0];
      setEditUser(res);
      setEditname(res);
    };
    getUser();

    const index = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/unique-series-season/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      // var res = resData?.Series[0];
      // var image = resData?.Series[0];
      var result = resData?.SeriesSeason;
      // setSeries(result)
      // console.log(result)
      setEditseason(result);
      setManageseason(result);

      const seriesData = resData?.Series?.access;
      setSeries(seriesData);
    };
    index();
  }, [seriesid]);


  useEffect(() => {
    // Filter useraccessseason based on series value
    let options = [];
    switch (series) {
      case 'guest':
        options = useraccessseason; // Show all options for guest
        break;
      case 'subscriber':
        options = useraccessseason?.filter(option => ['subscriber', 'ppv'].includes(option.role || option.value));
        break;
      case 'registered':
        options = useraccessseason?.filter(option => ['registered', 'subscriber', 'ppv'].includes(option.role || option.value));
        break;
      case 'ppv':
        options = useraccessseason?.filter(option => ['subscriber', 'ppv'].includes(option.role || option.value));
        break;
      default:
        options = []; // No options for unknown series
    }
    setFilteredOptions(options);
  }, [series, useraccessseason]);



  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));
    setEditUsereditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);
    setValidationMessagetitleeditUsereditUser("");
    setIsValidationHiddentitleeditUsereditUser(true);

    setEditUser({ ...editUser, [e.target.name]: e.target.value });
    setEditUsereditUser({ ...editUser, [e.target.name]: e.target.value });

  };

  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const [validationMessagecategory, setValidationMessagecategory] =
    useState("");
  const [isValidationHiddencategory, setIsValidationHiddencategory] =
    useState(true);

  const [validationMessagelanguages, setValidationMessagelanguages] =
    useState("");
  const [isValidationHiddenlanguages, setIsValidationHiddenlanguages] =
    useState(true);

  const titleInputRef = useRef(null);
  const categoryInputRef = useRef(null);
  const languageInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();

    let focusInputRef = null;

    if (editUser?.title === "") {
      setValidationMessagetitle("Title cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (!selectedValuescategories || selectedValuescategories?.length === 0) {
      setValidationMessagecategory("Please select at least one Category.");
      setIsValidationHiddencategory(false);
      focusInputRef = categoryInputRef;
    }
    // if (!selectedValueslanguages || selectedValueslanguages?.length === 0) {
    //   setValidationMessagelanguages("Please select at least one Language.");
    //   setIsValidationHiddenlanguages(false);
    //   focusInputRef = languageInputRef;
    // }

    let valid = true;

    if (compressResolutionFormat?.series === 1) {
      if (!selectedFile1 && !editUser?.image_url) {
        setError(prevErrors => ({ ...prevErrors, file1: 'Image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }

      if (!selectedFile2 && !editUser?.player_image) {
        setError(prevErrors => ({ ...prevErrors, file2: 'Player image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }

      if (!selectedFile3 && !editUser?.tv_image) {
        setError(prevErrors => ({ ...prevErrors, file3: 'TV image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }
    }

    if (!valid) {
      return;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }
    const updatedTagsString = tags.join(", ");

    const editInputvalue = {
      title: editUser?.title,
      type: editUser?.type,
      slug: editUser?.slug,
      series_id: editUser?.id,
      genre_id: editUser?.genre_id,
      user_id: editUser?.user_id,
      access: editUser?.access,
      details: editUser?.details,
      description: editUser?.description,
      active: editUser?.active,
      ppv_status: editUser?.ppv_status,
      status: editUser?.status,
      draft: editUser?.draft,
      featured: editUser?.featured,
      duration: editUser?.duration,
      views: editUser?.views,
      rating: editUser?.rating,
      image: editUser?.image,
      player_image: editUser?.player_image,
      tv_image: editUser?.tv_image,
      publish_status: editUser?.publish_status,
      publish_type: publishType,
      publish_time: publishTime,
      embed_code: editUser?.embed_code,
      mp4_url: editUser?.mp4_url,
      webm_url: editUser?.webm_url,
      ogg_url: editUser?.ogg_url,
      language: editUser?.language,
      year: editUser?.year,
      trailer: editUser?.trailer,
      banner: editUser?.banner,
      ppv_price: editUser?.ppv_price,
      search_tag: updatedTagsString,
      series_trailer: editUser?.series_trailer,
      season_trailer: editUser?.season_trailer,
      uploaded_by: editUser?.uploaded_by,
      url: editUser?.url,
      deleted_at: editUser?.deleted_at,
      global_ppv: checkboxChecked === true ? 1 : 0 || editUser?.global_ppv,
      ios_ppv_price: editUser?.ios_ppv_price,
      image_url: editUser?.image_url,
      ads_position: editUser?.ads_position,
      languages_id: selectedValueslanguages?.map((option) => option?.value),
      artists: selectedValuesartists?.map((option) => option?.value),
      block_country_id: selectedValues?.map((option) => option?.value),
      genre_id: selectedValuescategories?.map((option) => option?.value),
      available_countries_id: selectedValuesavailable_country?.map(
        (option) => option.value
      ),
      ...formData,
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let seventhApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/series/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;

        resultapi = resjson;

        const formData = new FormData();

        formData.append("series_id", id);
        if (selectedFile1) {
          formData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          formData.append("player_image", selectedFile2);
        }
        if (selectedFile3) {
          formData.append("tv_image", selectedFile3);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-series`,
          formData,
          { headers: headers }
        );

        const formDataseo = new FormData();
        formDataseo.append("series_id", id);
        formDataseo.append("website_page_title", editUser?.website_page_title);
        formDataseo.append("website_URL", editUser?.website_URL);
        formDataseo.append("Meta_description", editUser?.Meta_description);

        const formDataseoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/series/SEO`,
          formDataseo,
          { headers: headers }
        );

        if (imageResponse?.data?.status === true) {
          secondApiStatus = true;
        }

        if (formDataseoresponse?.data?.status === true) {
          seventhApiStatus = true;
        }
        // secondApiStatus = true;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus && secondApiStatus && seventhApiStatus) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson.status === false) {
        var resultError = resjson;
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setIsValidationHiddentitle(false);
        setValidationMessageslug(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  // const handleUpdate = async (e) => {
  //   e.preventDefault();

  //   if (editUser?.title === "") {
  //     setValidationMessagetitle("Title cannot be empty.");
  //     setIsValidationHiddentitle(false);
  //     return;
  //   }

  //   if (editUser?.slug === "") {
  //     setValidationMessageslug("Slug cannot be empty.");
  //     setIsValidationHiddenslug(false);
  //     return;
  //   }

  //   if (!selectedValuescategories || selectedValuescategories.length === 0) {
  //     setValidationMessagecategory("Please select at least one Category.");
  //     setIsValidationHiddencategory(false);
  //     return;
  //   }

  //   if (!selectedValueslanguages || selectedValueslanguages.length === 0) {
  //     setValidationMessagelanguages("Please select at least one Language.");
  //     setIsValidationHiddenlanguages(false);
  //     return;
  //   }

  //   const updatedTagsString = tags.join(", ");

  //   const editInputvalue = {
  //     title: editUser?.title,
  //     type: editUser?.type,
  //     slug: editUser?.slug,
  //     id: editUser?.id,
  //     genre_id: editUser?.genre_id,
  //     user_id: editUser?.user_id,
  //     access: editUser?.access,
  //     details: editUser?.details,
  //     description: editUser?.description,
  //     active: editUser?.active,
  //     ppv_status: editUser?.ppv_status,
  //     featured: editUser?.featured,
  //     duration: editUser?.duration,
  //     views: editUser?.views,
  //     rating: editUser?.rating,
  //     image: editUser?.image,
  //     player_image: editUser?.player_image,
  //     tv_image: editUser?.tv_image,
  //     embed_code: editUser?.embed_code,
  //     mp4_url: editUser?.mp4_url,
  //     webm_url: editUser?.webm_url,
  //     ogg_url: editUser?.ogg_url,
  //     language: editUser?.language,
  //     year: editUser?.year,
  //     trailer: editUser?.trailer,
  //     banner: editUser?.banner,
  //     ppv_price: inputValue,
  //     search_tag: updatedTagsString,
  //     series_trailer: editUser?.series_trailer,
  //     season_trailer: editUser?.season_trailer,
  //     uploaded_by: editUser?.uploaded_by,
  //     url: editUser?.url,
  //     deleted_at: editUser?.deleted_at,
  //     image_url: editUser?.image_url,
  //     ads_position: editUser?.ads_position,
  //     ...formData,
  //   };

  //   // console.log(editInputvalue);
  //   let res = await fetch(
  //     `${process.env.REACT_APP_Baseurl}/admin/series/update/` + seriesid,
  //     {
  //       method: "POST",
  //       headers: headers,

  //       body: JSON.stringify(editInputvalue),
  //     }
  //   );

  //   let resjson = await res.json();
  //   // console.log(resjson)
  //   if (resjson.status === true) {
  //     // setMessage(resjson.success);
  //     // setTimeout(() => {
  //     //   //   navigate('/livestream');
  //     // }, 2000);
  //     var result = resjson;
  //     // console.log('result', result);
  //     // navigate('/series-list')
  //     // alert("Added successfully  !");

  //     // var series_id = resjson.Series_id
  //     // // console.log(series_id)
  //     // var resssss = resjson.data

  //     const formDataseo = new FormData();
  //     formDataseo.append("seriesid", seriesid);
  //     formDataseo.append("website_page_title", editUser?.website_page_title);
  //     formDataseo.append("website_URL", editUser?.website_URL);
  //     formDataseo.append("Meta_description", editUser?.Meta_description);

  //     axios
  //       .post(
  //         `${process.env.REACT_APP_Baseurl}/admin/series/SEO`,
  //         formDataseo,
  //         { headers: headers }
  //       )
  //       .then((response) => {
  //         console.log("File(s) uploaded successfully:", response.data);
  //       });

  //     const formData = new FormData();
  //     formData.append("series_id", seriesid);
  //     if (selectedFile1) {
  //       formData.append("image", selectedFile1);
  //     }
  //     if (selectedFile2) {
  //       formData.append("player_image", selectedFile2);
  //     }
  //     if (selectedFile3) {
  //       formData.append("TV_image", selectedFile3);
  //     }

  //     {
  //       axios
  //         .post(
  //           `${process.env.REACT_APP_Baseurl}/admin/Image-upload-series`,
  //           formData,
  //           { headers: headers }
  //         )
  //         .then((response) => {
  //           // console.log("File(s) uploaded successfully:", response.data);
  //           // Perform any actions with the response data as needed.
  //           // navigate("/series-list");
  //           // alert("Added successfully  !");
  //         });
  //     }

  //     setValidationMessagetitle("");
  //     setValidationMessageslug("");

  //     setValidationMessagecategory("");
  //     setValidationMessagelanguages("");

  //     props.setApiresponsealert(result);
  //     props.setShowalert(true);

  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 6000);
  //   } else {
  //     if (resjson.status === false) {
  //       var resultError = resjson;
  //       props.setApiresponsealert(resultError);
  //       props.setShowalert(true);
  //       // console.log(resultError)

  //       setIsValidationHiddentitle(false);
  //       setValidationMessageslug(false);

  //       setIsValidationHiddencategory(false);
  //       setIsValidationHiddenlanguages(false);
  //     }
  //   }
  // };

  // const [access, setAccess] = useState("");
  // const [ppv_price, setPpv_price] = useState("");
  const [ppv_interval, setPpv_interval] = useState("");
  const [trailer_type, setTrailer_type] = useState("");
  const [ios_product_id, setIos_product_id] = useState("");
  // const [trailer, setTrailer] = useState("");
  // const [trailerimage, setTrailerImage] = useState("");


  const [validationMessagetitleeditUsereditUser, setValidationMessagetitleeditUsereditUser] = useState("");
  const [isValidationHiddentitleeditUsereditUser, setIsValidationHiddentitleeditUsereditUser] = useState(true);

  const titleInputRefeditUsereditUser = useRef(null);

  const [validationMessageAccess, setValidationMessageAccess] =
    useState("");
  const [isValidationHiddenAccess, setIsValidationHiddenAccess] =
    useState(true);

  const handleAccess = (data) => {
    setStoreliveuseraccessseason(data)
    setValidationMessageAccess("");
    setIsValidationHiddenAccess(true);
  }

  const accessInputRef = useRef(null);

  async function series_season() {

    let focusInputRef = null;

    if (editUsereditUser?.title === "") {
      setValidationMessagetitleeditUsereditUser("Title cannot be empty.");
      setIsValidationHiddentitleeditUsereditUser(false);
      focusInputRef = titleInputRefeditUsereditUser;
    }
    if (!storeliveuseraccessseason) {
      setValidationMessageAccess("Please select user access.");
      setIsValidationHiddenAccess(false);
      focusInputRef = accessInputRef;
    }

    let valid = true;

    if (compressResolutionFormat?.season === 1) {
      if (!selectedFile4) {
        setError(prevErrors => ({ ...prevErrors, file4: 'Season title image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }
    }

    if (!valid) {
      return;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const formData = new FormData();

    const formDatatest = modalTypemodalType === '1' ? formDataglobalpriceformDataglobalprice : formDatacustompriceformDatacustomprice;

    formData.append("series_id", seriesid);
    // formData.append("ppv_price", inputValueseason);
    if (global_ppv_status_active?.PPV_Individual_Contents === "0" || global_ppv_status_active?.PPV_Individual_Contents === 0) {
      formData.append("ppv_price", inputValueseason);
    } else {
      formData.append("ppv_price", datanewppvpprice?.price);
    }

    formData.append("title", editUsereditUser?.title);
    formData.append("slug", editUsereditUser?.slug);
    formData.append("access", storeliveuseraccessseason);
    formData.append("ppv_interval", ppv_interval);
    formData.append("ios_product_id", ios_product_id);
    formData.append("global_ppv", checkboxCheckedseason === true ? 1 : 0,);
    formData.append("ios_ppv_price", editUsereditUser?.ios_ppv_price);

    formData.append("source_type", 6);
    // formData.append("source_id", series_id);
    formData.append("PPVType", modalTypemodalType);
    formData.append("PriceType", formDatatest?.priceType || '');
    formData.append("IOSPrice", editUsereditUser?.ios_ppv_price || '');
    formData.append("ActualPrice", formDatatest?.price || '');

    // Serialize convertedPriceRates into a JSON string
    // Format and serialize convertedPriceRates
    if (formDatatest?.convertedPrices && formDatatest?.convertedPrices.length > 0) {
      formDatatest?.convertedPrices?.forEach((item, index) => {
        // Append each property with its corresponding value
        formData.append(`convertedPriceRates[${index}][Code]`, item?.Code || '');
        formData.append(`convertedPriceRates[${index}][Country]`, item?.country_name || item?.Country || '');
        formData.append(`convertedPriceRates[${index}][RateDifference]`, item?.difference || item?.RateDifference || '');
        formData.append(`convertedPriceRates[${index}][ConvertedPriceRate]`, item?.editPrice || item?.convertedPrice || '');
        formData.append(`convertedPriceRates[${index}][ChangedPrice]`, item?.editPrice || item?.convertedPrice || '');
        formData.append(`convertedPriceRates[${index}][Symbol]`, item?.Symbol || item?.symbol);
      });
    } else {
      formData.append('convertedPriceRates', JSON.stringify([]));
    }

    const headers = {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let seventhApiStatus = false;

    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/series_season/store`,
        formData,
        { headers: headers }
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;

        resultapi = response?.data;

        var result = response?.data?.SeriesSeason_id;
        // console.log("result", result);
        // // navigate('/series-list')
        // // alert("Added successfully  !");

        const imageFormData = new FormData();
        imageFormData.append("season_id", result);
        if (selectedFile4) {
          imageFormData.append("image", selectedFile4);
        }
        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-series_season`,
          imageFormData,
          { headers: headers }
        );

        const formDataseo = new FormData();

        formDataseo.append("season_id", result);
        formDataseo.append("trailer", selectedFile);
        formDataseo.append("trailer_type", trailer_type);

        const formDataseoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/Trailer-series_season`,
          formDataseo,
          { headers: headers }
        );

        if (imageResponse?.data?.status === true) {
          secondApiStatus = true;
        }
        if (formDataseoresponse?.data?.status === true) {
          seventhApiStatus = true;
        }
      }
    } catch (error) { }

    if (firstApiStatus && secondApiStatus && seventhApiStatus) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi === false) {
        var resultError = resultapi;
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setIsValidationHiddentitle(false);
        setValidationMessageslug(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  // async function series_season() {
  //   // console.log("append data");

  //   const formData = new FormData();

  //   formData.append("series_id", seriesid);
  //   formData.append("ppv_price", inputValue);
  //   formData.append("access", storeliveuseraccessseason);
  //   formData.append("ppv_interval", ppv_interval);
  //   formData.append("ios_product_id", ios_product_id);

  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //     "Access-Control-Allow-Origin": "*",
  //   };

  //   await axios
  //     .post(
  //       `${process.env.REACT_APP_Baseurl}/admin/series_season/store`,
  //       formData,
  //       { headers: headers }
  //     )
  //     .then((response) => {
  //       // console.log('api', response);
  //       if (response.data.status === true) {
  //         var result = response.data.SeriesSeason_id;
  //         // console.log("result", result);
  //         // // navigate('/series-list')
  //         // // alert("Added successfully  !");

  //         const formData = new FormData();
  //         formData.append("season_id", result);
  //         if (selectedFile4) {
  //           formData.append("image", selectedFile4);
  //         }

  //         {
  //           axios
  //             .post(
  //               `${process.env.REACT_APP_Baseurl}/admin/Image-upload-series_season`,
  //               formData,
  //               { headers: headers }
  //             )
  //             .then((response) => {
  //               // console.log("File(s) uploaded successfully:", response.data);
  //               // Perform any actions with the response data as needed.
  //               // navigate("/series-list");
  //               // alert("Added successfully  !");
  //             })
  //             .catch((error) => {
  //               console.error("Error uploading file(s):", error);
  //               // Handle any errors that occurred during the upload process.
  //             });
  //         }

  //         // const formDat = new FormData();

  //         // formDat.append("season_id", seriesid);
  //         // formDat.append("image", trailerimage);

  //         // axios
  //         //   .post(
  //         //     `${process.env.REACT_APP_Baseurl}/admin/Image-upload-series_season`,
  //         //     formDat,
  //         //     { headers: headers }
  //         //   )
  //         //   .then((response) => {
  //         //     // console.log('api', response);
  //         //     if (response.data.status === true) {
  //         //       var result = response.data;
  //         //       console.log("result", result);
  //         //       alert("Added successfully  !");
  //         //     }
  //         //   });

  //         const formDa = new FormData();

  //         formDa.append("season_id", result);
  //         formDa.append("trailer", selectedFile);
  //         formDa.append("trailer_type", trailer_type);

  //         axios
  //           .post(
  //             `${process.env.REACT_APP_Baseurl}/admin/Trailer-series_season`,
  //             formDa,
  //             { headers: headers }
  //           )
  //           .then((response) => {
  //             // console.log('api', response);
  //             if (response.data.status === true) {
  //               var result = response.data;
  //               // console.log("result", result);
  //               alert("Added successfully  !");
  //               window.location.reload();
  //             }
  //           });
  //       }
  //     })

  //     .catch((err) => {
  //       console.log("Error");
  //       alert("Enter Correct Details");
  //     });
  // }

  const deleteOperation = (id) => {
    axios
      .delete(`${process.env.REACT_APP_Baseurl}/admin/series_season/delete/${id}`, { headers })
      .then((response) => {
        const resultapi = response?.data;

        // Check the status in the response
        if (resultapi?.status === true) {
          // Success case
          props.setApiresponsealert(resultapi);
          props.setShowalert(true);
          // Optionally reload or update UI
          window.location.reload();
        } else {
          // Failure case
          props.setApiresponsealert(resultapi);
          props.setShowalert(true);
        }
      })
  };


  useEffect(async () => {
    if (selectedAdsPosition !== "") {
      // console.log(selectedAdsPosition, "adsssssssssss");
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);

      axios

        .post(
          `${process.env.REACT_APP_Baseurl}/admin/advertisement-tag-url`,
          formData,
          { headers: headers }
        )

        .then((response) => {
          const data = response?.data;
          // console.log(data, "exceptioned");

          // const adsNames = data?.Advertisement?.map((position) => ({
          //   value: position?.id,
          //   label: position?.ads_name,
          // }));

          // setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);

  const [allliveuseraccess, setallliveuseraccess] = useState("");
  // const [storeliveuseraccess, setStoreliveuseraccess] = useState("");

  function alllive_streamuseraccess() {
    switch (allliveuseraccess) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <>
            {global_ppv_status_active?.PPV_Individual_Contents === "0" || global_ppv_status_active?.PPV_Individual_Contents === 0 ? (

              <div className="row mt-3 text-start ">
                <div className="col-sm-12">
                  <label className="m-0">{label_management?.series[42]?.title}</label>
                  <div className="col-lg-12 row">
                    <div className="col-8 col-lg-8">
                      <p className="p1">{label_management?.series[42]?.sub_title}</p>
                    </div>
                    <div className="col-4 col-lg-4">
                      <label className="switch">
                        <input
                          name="ppv_price"
                          defaultChecked={checkboxChecked}
                          onChange={handleCheckboxChange}
                          type="checkbox"
                          className="rs-input"
                        />
                        <span
                          className="slider round"
                          name="ppv_price"
                          checked={checkboxChecked}
                          onChange={handleCheckboxChange}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="panel-body">
                    <input
                      type="number"
                      className="rs-input"
                      placeholder="PPV Price"
                      value={editUser?.ppv_price}
                      id="ppv_price"
                      name="ppv_price"
                      // value={inputValue}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-sm-12 mt-2">
                  <label className="m-0">{label_management?.series[39]?.title}</label>
                  <p className="p1">{label_management?.series[39]?.sub_title}</p>
                  <div className="panel-body ppv_price_ios mt-2">
                    <select
                      name="ios_ppv_price"
                      id="ios_ppv_price"
                      onChange={handleInput}
                      value={editUser?.ios_ppv_price}
                      className="form-select"
                    >
                      {inapppurchase?.map((item, key) => (
                        <option value={item?.product_id}>{item?.plan_price}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            ) :
              <div className="mt-2">
                <div className="col-sm-12">
                  <div className='modalofglobalppv'>
                    <Form>
                      <>
                        {global_ppv_status_active?.ppv_status === "1" || global_ppv_status_active?.ppv_status === 1 ? (
                          <Form.Check
                            type="radio"
                            label={label_management?.series[34]?.title}
                            name="priceOption"
                            value="1"
                            checked={editUser?.global_ppv === "1" || editUser?.global_ppv === 1}
                            // onChange={handleChange}
                            onClick={() => handleChange({ target: { value: '1' } })}
                          />
                        ) : null}
                      </>
                      <Form.Check
                        type="radio"
                        label={label_management?.series[35]?.title}
                        name="priceOption"
                        value="2"
                        checked={editUser?.global_ppv === null || (editUser?.global_ppv !== "1" && editUser?.global_ppv !== 1)}
                        // onChange={handleChange}
                        onClick={() => handleChange({ target: { value: '2' } })}
                      />
                    </Form>
                    <Modal show={showModal} onHide={handleClose} dialogClassName="modal-lg modal-dialog-scrollable" backdrop="static"
                      keyboard={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>{modalType === '1' ? `${label_management?.series[36]?.title}` : `${label_management?.series[40]?.title}`}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="modal-body-custom">
                        {step === 1 ? (
                          <Form>
                            <Form.Group>
                              <Form.Label
                                className="mandatorySimple">{modalType === '1' ? `${label_management?.series[37]?.title}` : `${label_management?.series[41]?.title}`}</Form.Label>
                              <Form.Control
                                type="number"
                                name="price"
                                value={modalType === '1' ? formDataglobalprice.price : formDatacustomprice.price}
                                onChange={(e) => handlePriceChange(e, modalType === '1' ? 'global' : 'custom')}
                                disabled={modalType === '1'}
                              />
                            </Form.Group>
                            {modalType === '2' && (
                              <Form.Group>
                                <Form.Label
                                  className="mandatorySimple">{label_management?.series[38]?.title}</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="priceType"
                                  value={formDatacustomprice.priceType}
                                  onChange={handleCustomPriceTypeChange}
                                  disabled
                                >
                                  <option value="">Select Countries</option>
                                  {allCurrencylistfromappi?.map(item => (
                                    <option key={item?.code} value={item?.code}>{item?.country}</option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            )}
                            {modalType === '1' && (
                              <Form.Group>
                                <Form.Label
                                  className="mandatorySimple">{label_management?.series[38]?.title}</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="priceType"
                                  value={formDatacustomprice.priceType}
                                  onChange={handleCustomPriceTypeChange}
                                  disabled
                                >
                                  <option value="">Select Countries</option>
                                  {allCurrencylistfromappi?.map(item => (
                                    <option key={item?.code} value={item?.code}>{item?.country}</option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            )}
                            {/* <div className='text-end mt-2'>
 <Button variant="primary" onClick={handleNext}>
   Next
 </Button>
</div> */}

                            <div className="col-sm-12 mt-2">
                              <label className="m-0">{label_management?.series[39]?.title}</label>
                              <p className="p1">{label_management?.series[39]?.sub_title}</p>
                              <div className="panel-body ppv_price_ios mt-2">
                                <select
                                  name="ios_ppv_price"
                                  id="ios_ppv_price"
                                  onChange={handleInput}
                                  value={editUser?.ios_ppv_price}
                                  className="form-select"
                                >
                                  <option value="">Select IOS Price</option>
                                  {inapppurchase?.map((item, key) => (
                                    <option value={item?.product_id}>{item?.plan_price}</option>
                                  ))}
                                </select>
                              </div>
                            </div>

                          </Form>
                        ) : (
                          <>
                            <div className="table-responsive">
                              <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Country</th>
                                    <th>Current Price</th>
                                    <th>Converted Price</th>
                                    <th>Difference</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(modalType === '1' ? formDataglobalprice.convertedPrices : formDatacustomprice.convertedPrices).map((item, index) => (
                                    <tr key={index}>
                                      <td>{item?.country_name || item?.Country} ({item?.Code})</td>
                                      <td>{item?.Symbol || item?.symbol} {item?.price || item?.ActualPrice}</td>
                                      <td>
                                        <Form.Control
                                          type="number"
                                          value={item?.editPrice !== undefined ? item?.editPrice : item?.convertedPrice}
                                          onChange={(e) => handleInputChangeeditable(index, 'editPrice', e.target.value)}
                                          disabled={modalType === '1'}
                                        />
                                      </td>
                                      <td>{item?.difference || item?.RateDifference}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                            {/* <div className='text-end mt-2'>
 <Button variant="secondary" onClick={() => setStep(1)}>
   Previous
 </Button>
 <Button variant="primary" onClick={handleSubmit}>
   Submit
 </Button>
</div> */}
                          </>
                        )}
                      </Modal.Body>

                      <hr></hr>
                      {step === 1 ? (

                        <div className='text-end p-2'>
                          <Button variant="primary" onClick={handleNext}>
                            Next
                          </Button>
                        </div>
                      ) : (
                        <div className='text-end p-2'>
                          <Button variant="secondary" onClick={() => setStep(1)}>
                            Previous
                          </Button>
                          <Button variant="primary" onClick={handleSubmit} className='ms-2'>
                            Submit
                          </Button>
                        </div>
                      )}
                    </Modal>
                  </div>
                </div>
              </div>
            }
          </>
        );

      default:
        return null;
    }
  }

  const [allliveuseraccessseason, setallliveuseraccessseason] = useState("");
  const [storeliveuseraccessseason, setStoreliveuseraccessseason] =
    useState("");

  function alllive_streamuseraccessseason() {
    switch (allliveuseraccessseason) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <>
            {global_ppv_status_active?.PPV_Individual_Contents === "0" || global_ppv_status_active?.PPV_Individual_Contents === 0 ? (


              <div className="row mt-3 text-start ">
                <div className="col-sm-12">
                  <label className="m-0">PPV Price</label>
                  <div className="col-lg-12 row">
                    <div className="col-8 col-lg-8">
                      <p className="p1">Apply PPV Price from Global Settings? </p>
                    </div>
                    <div className="col-4 col-lg-4">
                      <label className="switch">
                        <input
                          name="ppv_price"
                          defaultChecked={checkboxCheckedseason}
                          onChange={handleCheckboxChangeseason}
                          type="checkbox"
                          className="rs-input"
                        />
                        <span
                          className="slider round"
                          name="ppv_price"
                          checked={checkboxCheckedseason}
                          onChange={handleCheckboxChangeseason}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="panel-body">
                    <input
                      type="number"
                      className="rs-input"
                      placeholder="PPV Price"
                      name="ppv_price"
                      id="price"
                      value={inputValueseason}
                      onChange={handleInputChangeseason}
                    />
                  </div>
                </div>

                <div className="col-sm-12 mt-2">
                  <label className="m-0"> IOS PPV Price</label>
                  <p className="p1">Apply IOS PPV Price from Global Settings?</p>
                  <div className="panel-body ppv_price_ios  mt-2">
                    <select
                      // onChange={(e) => setIos_ppv_price(e.target.value)}
                      className="form-select"
                      id="ios_ppv_price"
                      name="ios_ppv_price"
                      onChange={(e) => setIos_product_id(e.target.value)}
                    >
                      <option value="">Choose an IOS PPV Price</option>
                      {ios_ppv_pricename?.map((item) => (
                        <option value={item?.product_id}>{item?.plan_price}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-sm-12 mt-2">
                  <label>PPV Interval</label>
                  <p className="p1">Please Mention How Many Episodes are Free</p>

                  <div className="panel-body ppv_price_ios  mt-2">
                    <input
                      type="text"
                      id="ppv_interval"
                      name="ppv_interval"
                      onChange={(e) => setPpv_interval(e.target.value)}
                      className="rs-input"
                    />
                  </div>
                </div>
              </div>
            ) :

              <div className="mt-2">
                <div className="col-sm-12">
                  <div className='modalofglobalppv'>
                    <Form>
                      <>
                        {global_ppv_status_active?.ppv_status === "1" || global_ppv_status_active?.ppv_status === 1 ? (
                          <Form.Check
                            type="radio"
                            label={label_management?.series_season[7]?.title}
                            name="priceOption"
                            value="1"
                            checked={editUsereditUser?.global_ppv === "1" || editUsereditUser?.global_ppv === 1}
                            // onChange={handleChange}
                            onClick={() => handleChangehandleChange({ target: { value: '1' } })}
                          />
                        ) : null}
                      </>
                      <Form.Check
                        type="radio"
                        label={label_management?.series_season[8]?.title}
                        name="priceOption"
                        value="2"
                        checked={editUsereditUser?.global_ppv === null || (editUsereditUser?.global_ppv !== "1" && editUsereditUser?.global_ppv !== 1)}
                        // onChange={handleChange}
                        onClick={() => handleChangehandleChange({ target: { value: '2' } })}
                      />
                    </Form>
                    <Modal show={showModalshowModal} onHide={handleClosehandleClose} dialogClassName="modal-lg modal-dialog-scrollable" backdrop="static"
                      keyboard={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>{modalTypemodalType === '1' ? `${label_management?.series_season[9]?.title}` : `${label_management?.series_season[17]?.title}`}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="modal-body-custom">
                        {stepstep === 1 ? (
                          <Form>
                            <Form.Group>
                              <Form.Label
                                className="mandatorySimple">{modalTypemodalType === '1' ? `${label_management?.series_season[10]?.title}` : `${label_management?.series_season[18]?.title}`}</Form.Label>
                              <Form.Control
                                type="number"
                                name="price"
                                value={modalTypemodalType === '1' ? formDataglobalpriceformDataglobalprice.price : formDatacustompriceformDatacustomprice.price}
                                onChange={(e) => handlePriceChangehandlePriceChange(e, modalTypemodalType === '1' ? 'global' : 'custom')}
                                disabled={modalTypemodalType === '1'}
                              />
                            </Form.Group>
                            {modalTypemodalType === '2' && (
                              <Form.Group>
                                <Form.Label
                                  className="mandatorySimple">{label_management?.series_season[11]?.title}</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="priceType"
                                  value={formDatacustompriceformDatacustomprice.priceType}
                                  onChange={handleCustomPriceTypeChangehandleCustomPriceTypeChange}
                                  disabled
                                >
                                  <option value="">Select Countries</option>
                                  {allCurrencylistfromappi?.map(item => (
                                    <option key={item?.code} value={item?.code}>{item?.country}</option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            )}
                            {modalTypemodalType === '1' && (
                              <Form.Group>
                                <Form.Label
                                  className="mandatorySimple">{label_management?.series_season[11]?.title}</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="priceType"
                                  value={formDatacustompriceformDatacustomprice.priceType}
                                  onChange={handleCustomPriceTypeChangehandleCustomPriceTypeChange}
                                  disabled
                                >
                                  <option value="">Select Countries</option>
                                  {allCurrencylistfromappi?.map(item => (
                                    <option key={item?.code} value={item?.code}>{item?.country}</option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            )}
                            {/* <div className='text-end mt-2'>
                 <Button variant="primary" onClick={handleNexthandleNext}>
Next
</Button>
</div> */}

                            <div className="col-sm-12 mt-2">
                              <label className="m-0">{label_management?.series_season[12]?.title}</label>
                              <p className="p1">{label_management?.series_season[12]?.sub_title}</p>
                              <div className="panel-body ppv_price_ios mt-2">
                                <select
                                  name="ios_ppv_price"
                                  id="ios_ppv_price"
                                  onChange={handleInputsetEditUsereditUser}
                                  value={editUsereditUser?.ios_ppv_price}
                                  className="form-select"
                                >
                                  <option value="">Select IOS Price</option>
                                  {inapppurchase?.map((item, key) => (
                                    <option value={item?.product_id}>{item?.plan_price}</option>
                                  ))}
                                </select>
                              </div>
                            </div>

                          </Form>
                        ) : (
                          <>
                            <div className="table-responsive">
                              <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Country</th>
                                    <th>Current Price</th>
                                    <th>Converted Price</th>
                                    <th>Difference</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(modalTypemodalType === '1' ? formDataglobalpriceformDataglobalprice.convertedPrices : formDatacustompriceformDatacustomprice.convertedPrices).map((item, index) => (
                                    <tr key={index}>
                                      <td>{item?.country_name || item?.Country} ({item?.Code})</td>
                                      <td>{item?.Symbol || item?.symbol} {item?.price || item?.ActualPrice}</td>
                                      <td>
                                        <Form.Control
                                          type="number"
                                          value={item?.editPrice !== undefined ? item?.editPrice : item?.convertedPrice}
                                          onChange={(e) => handleInputChangeeditablehandleInputChangeeditable(index, 'editPrice', e.target.value)}
                                          disabled={modalTypemodalType === '1'}
                                        />
                                      </td>
                                      <td>{item?.difference || item?.RateDifference}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                            {/* <div className='text-end mt-2'>
<Button variant="secondary" onClick={() => setStepstep(1)}>
Previous
</Button>
<Button variant="primary" onClick={handleSubmithandleSubmit}>
Submit
</Button>
</div> */}
                          </>
                        )}
                      </Modal.Body>

                      <hr></hr>
                      {stepstep === 1 ? (

                        <div className='text-end p-2'>
                          <Button variant="primary" onClick={handleNexthandleNext}>
                            Next
                          </Button>
                        </div>
                      ) : (
                        <div className='text-end p-2'>
                          <Button variant="secondary" onClick={() => setStepstep(1)}>
                            Previous
                          </Button>
                          <Button variant="primary" onClick={handleClosehandleClosehandleClosehandleClose} className='ms-2'>
                            Processed
                          </Button>
                        </div>
                      )}
                    </Modal>
                  </div>
                </div>
              </div>
            }
          </>
        );
      default:
        return null;
    }
  }






  // {ppv price with global and customs} 
  // {ppv price with global and customs} 

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [step, setStep] = useState(1);
  const [formDataglobalprice, setFormDataglobalprice] = useState({
    priceType: allCurrencylistfromappichoosed?.code,  // Default value for global price
    price: ppv_gobal_price,
    convertedPrices: []
  });
  const [formDatacustomprice, setFormDatacustomprice] = useState({
    priceType: allCurrencylistfromappichoosed?.code,  // Default value for custom price
    price: editUser?.ppv_price,
    convertedPrices: []
  });



  const handleChange = (e) => {
    const { value } = e.target;
    if (value === "1") {
      setEditUser((prevState) => ({
        ...prevState,
        global_ppv: "1",
      }));
    } else if (value === "2") {
      setEditUser((prevState) => ({
        ...prevState,
        global_ppv: null,
      }));
    }

    setModalType(e.target.value);
    setShowModal(true);
    setStep(1);
  };

  const handleClose = () => {
    setShowModal(false);
    setFormDataglobalprice({
      priceType: allCurrencylistfromappichoosed?.code,
      price: ppv_gobal_price,
      convertedPrices: []
    });
    setFormDatacustomprice({
      priceType: allCurrencylistfromappichoosed?.code,
      price: editUser?.ppv_price,
      convertedPrices: []
    });
  };

  const handleNext = async () => {
    if (step === 1) {
      const formData = modalType === '1' ? formDataglobalprice : formDatacustomprice;

      if (!formData?.priceType || !formData?.price) {
        toast.error('Please fill in the required fields.')
        return;
      }

      try {
        if (modalType === '2' && formData?.price !== editUser?.ppv_price) {
          const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${formData?.priceType}`);
          const rates = response?.data?.rates;

          const convertedPrices = allCurrencylistfromappi?.map(item => {
            let country_name = item?.country;
            let Symbol = item?.Symbol || item?.symbol;
            let Code = item?.code;

            const matchingCountry = allCurrencylistfromappi.find(currency => currency.code === item?.code);
            if (matchingCountry) {
              country_name = matchingCountry?.country;
              Symbol = matchingCountry?.Symbol || matchingCountry?.symbol;
            }

            return {
              Code: Code,
              country_name: country_name,
              Symbol: Symbol,
              convertedPrice: (formData?.price * (rates[item?.code] || 0)).toFixed(2),
              price: formData?.price,
              difference: ((formData?.price * (rates[item?.code] || 0)) - formData?.price).toFixed(2),
              editPrice: (formData?.price * (rates[item?.code] || 0)).toFixed(2),
              isEditable: true,
            };
          });

          setFormDatacustomprice(prevState => ({
            ...prevState,
            convertedPrices: convertedPrices
          }));
        } else if (modalType === '2' && formData?.price === editUser?.ppv_price) {
          const customPrices = allCurrencycustoms?.map(item => ({
            ...item,
            convertedPrice: item?.ChangedPrice || (formData?.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
            difference: item?.RateDifference || 0,
            isEditable: true
          }));

          setFormDatacustomprice(prevState => ({
            ...prevState,
            convertedPrices: customPrices
          }));
        } else if (modalType === '1') {
          const globalPrices = allCurrencyglobal?.map(item => ({
            ...item,
            convertedPrice: item?.ChangedPrice || (formData?.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
            isEditable: false
          }));
          setFormDataglobalprice(prevState => ({
            ...prevState,
            convertedPrices: globalPrices
          }));
        }

        setStep(2);
      } catch (error) {
        console.error('Error converting currency:', error);
      }
    }
  };


  const handleSubmit = async () => {
    const formData = modalType === '1' ? formDataglobalprice : formDatacustomprice;

    if (!formData || formData?.convertedPrices.length === 0) {
      alert('Please fill in all the fields.');
      return;
    }

    // Prepare data to submit
    const dataToSubmit = {
      source_type: 3, // Note - 1 (Videos) or 2 (Livestream) or 3 (Series) or 4 (Episode) or 5 (Audios) or 6 season or 7 golbal
      source_id: id,
      PPVType: modalType,
      PriceType: formData?.priceType, // Separate priceType outside of convertedPrices
      IOSPrice: editUser?.ios_ppv_price,
      ActualPrice: formData?.price,
      convertedPriceRates: formData?.convertedPrices.map(item => ({
        Code: item?.Code,
        Country: item?.country_name || item?.Country,
        RateDifference: item?.difference || item?.RateDifference,
        ConvertedPriceRate: item?.editPrice || item?.convertedPrice,
        ChangedPrice: item?.editPrice || item?.convertedPrice,
        Symbol: item?.Symbol || item?.symbol,
      }))
    };

    let firstApiStatus = false;
    let resultapi = {};
    let resjson = {};

    try {
      const res = await fetch(`${process.env.REACT_APP_Baseurl}/admin/Series/PayPerViewPrice/StoreUpdate`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(dataToSubmit),
      });

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;
        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
      // Optionally show a user-friendly error message
      alert('An error occurred while submitting the form. Please try again.');
    }

    if (firstApiStatus) {
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      handleClose();
      setStep(1);
    } else {
      if (resjson?.status === false) {
        props?.setApiresponsealert(resjson);
        props?.setShowalert(true);
      }
    }
  };


  const handleInputChangeeditable = (index, fieldName, value) => {
    const updatedConvertedPrices = [...(modalType === '1' ? formDataglobalprice.convertedPrices : formDatacustomprice.convertedPrices)];
    updatedConvertedPrices[index][fieldName] = value;
    if (modalType === '1') {
      setFormDataglobalprice(prevState => ({
        ...prevState,
        convertedPrices: updatedConvertedPrices
      }));
    } else {
      setFormDatacustomprice(prevState => ({
        ...prevState,
        convertedPrices: updatedConvertedPrices
      }));
    }
  };

  const handleCustomPriceTypeChange = async (event) => {
    const selectedCountryCode = event.target.value;

    if (selectedCountryCode && selectedCountryCode !== allCurrencylistfromappichoosed?.code) {
      try {
        // Fetch conversion rates from API
        const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${selectedCountryCode}`);
        const rates = response.data.rates;

        // Convert and update prices based on the API data
        const convertedPrices = allCurrencylistfromappi?.map(item => {
          let country_name = item?.country;
          let Symbol = item?.Symbol || item?.symbol;
          let Code = item?.code;

          // Find matching country data
          const matchingCountry = allCurrencylistfromappi.find(currency => currency.code === item?.code);
          if (matchingCountry) {
            country_name = matchingCountry?.country;
            Symbol = matchingCountry?.Symbol || matchingCountry?.symbol;
          }

          return {
            Code: Code,
            Country: country_name,
            Symbol: Symbol,
            convertedPrice: (formDatacustomprice.price * (rates[item?.code] || 0)).toFixed(2),
            price: formDatacustomprice.price,
            difference: ((formDatacustomprice.price * (rates[item?.code] || 0)) - formDatacustomprice.price).toFixed(2),
            editPrice: (formDatacustomprice.price * (rates[item?.code] || 0)).toFixed(2),
            isEditable: true,
          };
        });

        setFormDatacustomprice(prevState => ({
          ...prevState,
          priceType: selectedCountryCode,
          convertedPrices: convertedPrices
        }));
      } catch (error) {
        console.error('Error fetching currency conversion rates:', error);
      }
    } else {
      // Set the existing data if allCurrencylistfromappichoosed?.code is selected
      const customPrices = allCurrencycustoms?.map(item => ({
        ...item,
        convertedPrice: item?.ChangedPrice || (formDatacustomprice.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
        difference: item?.RateDifference || 0,
        isEditable: true
      }));

      setFormDatacustomprice(prevState => ({
        ...prevState,
        priceType: allCurrencylistfromappichoosed?.code,
        convertedPrices: customPrices
      }));
    }
  };

  const handlePriceChange = (e, formType) => {
    const { name, value } = e.target;
    if (formType === 'global') {
      setFormDataglobalprice(prevState => ({
        ...prevState,
        [name]: value
      }));
    } else if (formType === 'custom') {
      setFormDatacustomprice(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };




  // {ppv price with global and customs} 
  // {ppv price with global and customs} 


  const [showModalshowModal, setShowModalshowModal] = useState(false);
  const [modalTypemodalType, setModalTypemodalType] = useState('');
  const [stepstep, setStepstep] = useState(1);
  const [formDataglobalpriceformDataglobalprice, setFormDataglobalpriceformDataglobalprice] = useState({
    priceType: allCurrencylistfromappichoosedallCurrencylistfromappichoosed?.code,  // Default value for global price
    price: ppv_gobal_priceppv_gobal_price,
    convertedPrices: []
  });
  const [formDatacustompriceformDatacustomprice, setFormDatacustompriceformDatacustomprice] = useState({
    priceType: allCurrencylistfromappichoosedallCurrencylistfromappichoosed?.code,  // Default value for custom price
    price: editUsereditUser?.ppv_price,
    convertedPrices: []
  });



  const handleChangehandleChange = (e) => {
    const { value } = e.target;
    if (value === "1") {
      setEditUsereditUser((prevState) => ({
        ...prevState,
        global_ppv: "1",
      }));
    } else if (value === "2") {
      setEditUsereditUser((prevState) => ({
        ...prevState,
        global_ppv: null,
      }));
    }

    setModalTypemodalType(e.target.value);
    setShowModalshowModal(true);
    setStepstep(1);
  };

  const handleClosehandleClose = () => {
    setShowModalshowModal(false);
    setFormDataglobalpriceformDataglobalprice({
      priceType: allCurrencylistfromappichoosedallCurrencylistfromappichoosed?.code,
      price: ppv_gobal_priceppv_gobal_price,
      convertedPrices: []
    });
    setFormDatacustompriceformDatacustomprice({
      priceType: allCurrencylistfromappichoosedallCurrencylistfromappichoosed?.code,
      price: editUsereditUser?.ppv_price,
      convertedPrices: []
    });
  };

  const handleClosehandleClosehandleClosehandleClose = () => {
    setShowModalshowModal(false);
  };

  const handleNexthandleNext = async () => {
    if (stepstep === 1) {
      const formData = modalTypemodalType === '1' ? formDataglobalpriceformDataglobalprice : formDatacustompriceformDatacustomprice;

      if (!formData?.priceType || !formData?.price) {
        toast.error('Please fill in the required fields.')
        return;
      }

      try {
        if (modalTypemodalType === '2' && formData?.price !== editUsereditUser?.ppv_price) {
          const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${formData?.priceType}`);
          const rates = response?.data.rates;

          const convertedPrices = allCurrencylistfromappi?.map(item => {
            let country_name = item?.country;
            let Symbol = item?.Symbol || item?.symbol;
            let Code = item?.code;

            const matchingCountry = allCurrencylistfromappi.find(currency => currency.code === item?.code);
            if (matchingCountry) {
              country_name = matchingCountry?.country;
              Symbol = matchingCountry?.Symbol || matchingCountry?.symbol;
            }

            return {
              Code: Code,
              country_name: country_name,
              Symbol: Symbol,
              convertedPrice: (formData?.price * (rates[item?.code] || 0)).toFixed(2),
              price: formData?.price,
              difference: ((formData?.price * (rates[item?.code] || 0)) - formData?.price).toFixed(2),
              editPrice: (formData?.price * (rates[item?.code] || 0)).toFixed(2),
              isEditable: true,
            };
          });

          setFormDatacustompriceformDatacustomprice(prevState => ({
            ...prevState,
            convertedPrices: convertedPrices
          }));
        } else if (modalTypemodalType === '2' && formData?.price === editUsereditUser?.ppv_price) {
          const customPrices = allCurrencycustomsallCurrencycustoms?.map(item => ({
            ...item,
            convertedPrice: item?.ChangedPrice || (formData?.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
            difference: item?.RateDifference || 0,
            isEditable: true
          }));

          setFormDatacustompriceformDatacustomprice(prevState => ({
            ...prevState,
            convertedPrices: customPrices
          }));
        } else if (modalTypemodalType === '1') {
          const globalPrices = allCurrencyglobalallCurrencyglobal?.map(item => ({
            ...item,
            convertedPrice: item?.ChangedPrice || (formData?.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
            isEditable: false
          }));
          setFormDataglobalpriceformDataglobalprice(prevState => ({
            ...prevState,
            convertedPrices: globalPrices
          }));
        }

        setStepstep(2);
      } catch (error) {
        console.error('Error converting currency:', error);
      }
    }
  };


  const handleSubmithandleSubmit = async (series_id) => {
    const formData = modalTypemodalType === '1' ? formDataglobalpriceformDataglobalprice : formDatacustompriceformDatacustomprice;

    if (!formData || formData?.convertedPrices.length === 0) {
      // alert('Please fill in all the fields.');
      return;
    }

    // Prepare data to submit
    const dataToSubmit = {
      source_type: 3, // Note - 1 (Videos) or 2 (Livestream) or 3 (Series) or 4 (Episode) or 5 (Audios) or 6 season or 7 golbal
      source_id: series_id,
      PPVType: modalTypemodalType,
      PriceType: formData?.priceType, // Separate priceType outside of convertedPrices
      IOSPrice: editUsereditUser?.ios_ppv_price,
      ActualPrice: formData?.price,
      convertedPriceRates: formData?.convertedPrices.map(item => ({
        Code: item?.Code,
        Country: item?.country_name || item?.Country,
        RateDifference: item?.difference || item?.RateDifference,
        ConvertedPriceRate: item?.editPrice || item?.convertedPrice,
        ChangedPrice: item?.editPrice || item?.convertedPrice,
        Symbol: item?.Symbol || item?.symbol,
      }))
    };

    let firstApiStatus = false;
    let resultapi = {};
    let resjson = {};

    try {
      const res = await fetch(`${process.env.REACT_APP_Baseurl}/admin/Episode/PayPerViewPrice/StoreUpdate`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(dataToSubmit),
      });

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;
        resultapi = resjson;
      }
    } catch (error) {
    }

    if (firstApiStatus) {
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      handleClosehandleClose();
      setStepstep(1);
    } else {
      if (resjson?.status === false) {
        props?.setApiresponsealert(resjson);
        props?.setShowalert(true);
      }
    }
  };


  const handleInputChangeeditablehandleInputChangeeditable = (index, fieldName, value) => {
    const updatedConvertedPrices = [...(modalTypemodalType === '1' ? formDataglobalpriceformDataglobalprice.convertedPrices : formDatacustompriceformDatacustomprice.convertedPrices)];
    updatedConvertedPrices[index][fieldName] = value;
    if (modalTypemodalType === '1') {
      setFormDataglobalpriceformDataglobalprice(prevState => ({
        ...prevState,
        convertedPrices: updatedConvertedPrices
      }));
    } else {
      setFormDatacustompriceformDatacustomprice(prevState => ({
        ...prevState,
        convertedPrices: updatedConvertedPrices
      }));
    }
  };

  const handleCustomPriceTypeChangehandleCustomPriceTypeChange = async (event) => {
    const selectedCountryCode = event.target.value;

    if (selectedCountryCode && selectedCountryCode !== allCurrencylistfromappichoosedallCurrencylistfromappichoosed?.code) {
      try {
        // Fetch conversion rates from API
        const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${selectedCountryCode}`);
        const rates = response.data.rates;

        // Convert and update prices based on the API data
        const convertedPrices = allCurrencylistfromappi?.map(item => {
          let country_name = item?.country;
          let Symbol = item?.Symbol || item?.symbol;
          let Code = item?.code;

          // Find matching country data
          const matchingCountry = allCurrencylistfromappi.find(currency => currency.code === item?.code);
          if (matchingCountry) {
            country_name = matchingCountry?.country;
            Symbol = matchingCountry?.Symbol || matchingCountry?.symbol;
          }

          return {
            Code: Code,
            Country: country_name,
            Symbol: Symbol,
            convertedPrice: (formDatacustompriceformDatacustomprice.price * (rates[item?.code] || 0)).toFixed(2),
            price: formDatacustompriceformDatacustomprice.price,
            difference: ((formDatacustompriceformDatacustomprice.price * (rates[item?.code] || 0)) - formDatacustompriceformDatacustomprice.price).toFixed(2),
            editPrice: (formDatacustompriceformDatacustomprice.price * (rates[item?.code] || 0)).toFixed(2),
            isEditable: true,
          };
        });

        setFormDatacustompriceformDatacustomprice(prevState => ({
          ...prevState,
          priceType: selectedCountryCode,
          convertedPrices: convertedPrices
        }));
      } catch (error) {
        console.error('Error fetching currency conversion rates:', error);
      }
    } else {
      // Set the existing data if allCurrencylistfromappichoosedallCurrencylistfromappichoosed?.code is selected
      const customPrices = allCurrencycustomsallCurrencycustoms?.map(item => ({
        ...item,
        convertedPrice: item?.ChangedPrice || (formDatacustompriceformDatacustomprice.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
        difference: item?.RateDifference || 0,
        isEditable: true
      }));

      setFormDatacustompriceformDatacustomprice(prevState => ({
        ...prevState,
        priceType: allCurrencylistfromappichoosedallCurrencylistfromappichoosed?.code,
        convertedPrices: customPrices
      }));
    }
  };


  const handlePriceChangehandlePriceChange = (e, formType) => {
    const { name, value } = e.target;
    if (formType === 'global') {
      setFormDataglobalpriceformDataglobalprice(prevState => {
        const updatedState = { ...prevState, [name]: value };
        setDatanewppvpprice(updatedState)
        return updatedState;
      });
    } else if (formType === 'custom') {
      setFormDatacustompriceformDatacustomprice(prevState => {
        const updatedState = { ...prevState, [name]: value };
        setDatanewppvpprice(updatedState)
        return updatedState;
      });
    }
  };


  // {ppv price with global and customs} 
  // {ppv price with global and customs} 

  const [showFirstModal, setShowFirstModal] = useState(false);

  const handleFirstModal = () => {
    setShowFirstModal(true);
  };

  const closeFirstModal = () => {
    setShowFirstModal(false);
  };

  // {ppv price with global and customs} 

  const [modalId, setModalId] = useState(null);

  const handleDeleteClick = (id) => {
    setModalId(id);
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>{label_management?.series[43]?.title}</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    {label_management?.series[33]?.title}
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>{editname?.title}</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      {label_management?.series[3]?.title}<span className="mandatory">*</span>
                    </label>
                    <p className="t-1">
                      {label_management?.series[3]?.sub_title}
                    </p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="title"
                        name="title"
                        className="rs-input form-control-lg"
                        value={editUser?.title}
                        onChange={handleInput}
                        placeholder="Series Title"
                        ref={titleInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddentitle && (
                        <p>{validationMessagetitle}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">{label_management?.series[4]?.title}</label>
                    <p className="t-1">{label_management?.series[4]?.sub_title}</p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="slug"
                        name="slug"
                        className="rs-input form-control-lg"
                        value={editUser?.slug}
                        onChange={handleInput}
                        placeholder="Series Slug"
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenslug && (
                        <p>{validationMessageslug}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">{label_management?.series[5]?.title}</label>
                    <p className="t-1">{label_management?.series[5]?.sub_title}</p>
                    <div className="mt-2">
                      <textarea
                        className="rs-input short-description"
                        id="description"
                        name="description"
                        onChange={handleInput}
                        value={editUser?.description}
                        placeholder="Description"
                      />
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">

                      <label className="m-0">{label_management?.series[6]?.title}</label>
                      <p className="t-1">{label_management?.series[6]?.sub_title}</p>
                      <div className="mt-2">
                        <JoditEditor
                          className="rs-input"
                          value={editUser?.details}
                          onChange={(e) => {
                            handleChangeFirst(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">{label_management?.series[7]?.title}</label>
                      <p className="t-1">{label_management?.series[7]?.sub_title}</p>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="duration"
                          className="rs-input form-control-lg"
                          value={editUser?.duration}
                          maxLength="8"
                          // placeholder="duration"
                          onChange={handleInputdata}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">{label_management?.series[8]?.title}</label>
                      <p className="t-1">{label_management?.series[8]?.sub_title}</p>
                      <div className="mt-2">
                        <input
                          type="date"
                          name="date"
                          id="date"
                          value={editUser?.year || ''}
                          onChange={handleChangeyear}
                          className="rs-input form-control-lg"
                          placeholder="Year"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                  <div className="card p-3">
                    <h5>{label_management?.series[9]?.title}</h5>
                    <hr></hr>

                    <div className=" text-start">
                      <label className="m-0">{label_management?.series[10]?.title}</label>
                      <p className="t-1">{label_management?.series[10]?.sub_title}</p>
                      <div className="mt-2">
                        <select
                          className="form-select"
                          id="rating"
                          name="rating"
                          size="lg"
                          onChange={handleInput}
                          value={editUser?.rating}
                        >
                          {/* <option value="0">0</option> */}
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                    </div>

                    {/* <div className=" mt-2 text-start">
                      <label className="m-0">Age Restrict :</label>
                      <p className="t-1">Select A Series Age Below:</p>
                      <div className="mt-2">
                        <select
                          className="rs-input form-control-lg "
                          id="age_restrict"
                          name="age_restrict"
                          size="lg"
                          onChange={handleInput}
                          value={editUser?.age_restrict}
                        >
                          {age_restrictdata.map((item) => (
                            <option selected value={item?.id}>
                              {item?.slug}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div> */}

                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.series[11]?.title}</label>
                      <p className="t-1">{label_management?.series[11]?.sub_title}</p>

                      <div className="mt-2">
                        <Select
                          options={optionsartists}
                          isMulti
                          className=""
                          onChange={handleSelectChangeartists}
                          value={selectedValuesartists}
                        />
                      </div>
                    </div>
                    <div className=" text-start mt-2">

                      <label className="m-0">{label_management?.series[12]?.title}<span className="mandatory">*</span></label>
                      <p className="t-1">{label_management?.series[12]?.sub_title}</p>

                      <div className="mt-2">
                        <Select
                          options={optionscategories}
                          isMulti
                          className=""
                          onChange={handleSelectChangecategories}
                          value={selectedValuescategories}
                          ref={categoryInputRef}
                        />
                      </div>
                      <span className="errorred">
                        {!isValidationHiddencategory && (
                          <p>{validationMessagecategory}</p>
                        )}
                      </span>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.series[13]?.title}</label>
                      <p className="t-1">{label_management?.series[13]?.sub_title}</p>
                      <div className="text-dark">
                        <Select
                          options={optionslanguages}
                          isMulti
                          className=""
                          onChange={handleSelectChangelanguages}
                          value={selectedValueslanguages}
                        // ref={languageInputRef}
                        />
                      </div>
                      {/* <span className="errorred">
                        {!isValidationHiddenlanguages && (
                          <p>{validationMessagelanguages}</p>
                        )}
                      </span> */}
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.series[14]?.title}</label>
                      <p className="t-1">{label_management?.series[14]?.sub_title}</p>
                      <div className="text-dark">
                        <Select
                          options={options}
                          isMulti
                          className=""
                          onChange={handleSelectChange}
                          value={selectedValues}
                        />
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.series[15]?.title}</label>
                      <p className="t-1">{label_management?.series[15]?.sub_title}</p>
                      <div className="text-dark">
                        <Select
                          options={optionsavailable_country}
                          isMulti
                          className=""
                          onChange={handleSelectChangeavailable_country}
                          value={selectedValuesavailable_country}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>{label_management?.series[16]?.title}</h5>
                  <hr></hr>

                  <div className="col-sm-12">
                    <label className="m-0">{label_management?.series[17]?.title}</label>
                    <p className="t-1">{label_management?.series[17]?.sub_title}</p>
                    <div className="mt-2">
                      <TagsInput
                        value={tags}
                        onChange={handleTagsChange}
                        inputValue={newTag}
                        onChangeInput={(tag) => setNewTag(tag)}
                      />
                    </div>
                  </div>
                </div>



                <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    {label_management?.series[33]?.title}
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>{label_management?.series[18]?.title}</h5>
                  <hr></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0">{label_management?.series[19]?.title}
                      {compressResolutionFormat?.series === 1 && (
                        <span className="mandatory">*</span>
                      )}
                    </label>
                    <p className="t-1">
                      {label_management?.series[19]?.sub_title}
                    </p>

                    {/* <img src={editUser?.image_url} width="150" height="100" /> */}

                    <div>
                      {loading ? (
                        <Response_Loading_processing></Response_Loading_processing>
                      ) : (
                        <img
                          src={editUser?.image_url}
                          alt="Image_url"
                          className="thumbnails-images"
                        />
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1,
                                  'file1'
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile1)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(1)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {error?.file1 && <div className="errorred mt-1">{error?.file1}</div>}
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">{label_management?.series[20]?.title}
                      {compressResolutionFormat?.series === 1 && (
                        <span className="mandatory">*</span>
                      )}
                    </label>
                    <p className="t-1">
                      {label_management?.series[20]?.sub_title}
                    </p>
                    {/* <img
                      src={editUser?.player_image}
                      width="150"
                      height="100"
                    /> */}

                    <div>
                      {loading ? (
                        <Response_Loading_processing></Response_Loading_processing>
                      ) : (
                        <img
                          src={editUser?.player_image}
                          alt="Image_url"
                          className="thumbnails-images"
                        />
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef2.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef2}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef2,
                                  setSelectedFile2,
                                  'file2'
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile2 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile2)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(2)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {error?.file2 && <div className="errorred mt-1">{error?.file2}</div>}
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">{label_management?.series[21]?.title}
                      {compressResolutionFormat?.series === 1 && (
                        <span className="mandatory">*</span>
                      )}
                    </label>
                    <p className="t-1">
                      {label_management?.series[21]?.sub_title}
                    </p>
                    {/* <img src={editUser?.tv_image} width="150" height="100" /> */}

                    <div>
                      {loading ? (
                        <Response_Loading_processing></Response_Loading_processing>
                      ) : (
                        <img
                          src={editUser?.tv_image}
                          alt="Image_url"
                          className="thumbnails-images"
                        />
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef3.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef3}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef3,
                                  setSelectedFile3,
                                  'file3'
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile3 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile3)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(3)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {error?.file3 && <div className="errorred mt-1">{error?.file3}</div>}
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  {/* <div className="card p-3">
                    <h5>Visibility</h5>
                    <hr></hr>

                    <div className="col-sm-12">
                      <label className="mb-2">Publish Type</label>
                      <div
                        onClick={(event) => {
                          setEnable_restreampublic(event.target.value);
                        }}
                      >
                        <input
                          type="radio"
                          id="publish_now"
                          name="publish_type"
                          onClick={(event) => {
                            setEnable_restreampublic(event.target.value);
                          }}
                          onChange={(e) => setPublish_type(e.target.value)}
                          value="publish_now"
                          checked="checked"
                        />{" "}
                        <span
                          className="mt-2"
                          onClick={(event) => {
                            setEnable_restreampublic(event.target.value);
                          }}
                          onChange={(e) => setPublish_type(e.target.value)}
                        >
                          Publish Now
                        </span>
                      </div>
                      <div
                        onClick={(event) => {
                          setEnable_restreampublic(event.target.value);
                        }}
                      >
                        <input
                          type="radio"
                          id="publish_later"
                          value="publish_later"
                          name="publish_type"
                          className="mt-2"
                          onClick={(event) => {
                            setEnable_restreampublic(event.target.value);
                          }}
                          onChange={(e) => setPublish_type(e.target.value)}
                        />{" "}
                        <span
                          value="publish_later"
                          name="publish_type"
                          onClick={(event) => {
                            setEnable_restreampublic(event.target.value);
                          }}
                          onChange={(e) => setPublish_type(e.target.value)}
                        >
                          Publish Later
                        </span>{" "}
                      </div>
                    </div>

                    <div className="col-sm-12" id="publishlater">
                      <div
                        className="mt-2"
                        onClick={(event) => {
                          setEnablestreamvisiblepublic(event.target.value);
                        }}
                      >
                        {proceednextpublic()}
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="card p-3">
                    <h5>Visibility</h5>
                    <hr></hr>

                    <div className="col-sm-12">
                      <label className="mb-2">Publish Type</label>
                      <div>
                        <input
                          type="radio"
                          id="publish_now"
                          name="publish_type"
                          value="publish_now"
                          checked={publishType === "publish_now"}
                          onChange={handlePublishTypeChange}
                        />
                        <label htmlFor="publish_now" className="mt-2 ms-1">
                          Publish Now
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="publish_later"
                          name="publish_type"
                          value="publish_later"
                          checked={publishType === "publish_later"}
                          onChange={handlePublishTypeChange}
                        />
                        <label htmlFor="publish_later" className="mt-2 ms-1">
                          Publish Later
                        </label>
                        {publishType === "publish_later" && (
                          <div className="mb-3">
                            <label className="mt-2">Publish Time</label>
                            <input
                              type="datetime-local"
                              className="form-control"
                              id="publish_time"
                              name="publish_time"
                              value={publishTime}
                              onChange={handlePublishTimeChange}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div> */}

                  <div className="card p-3">
                    <h5>{label_management?.series[22]?.title}</h5>
                    <hr></hr>

                    <div className="col-sm-12">
                      <label className="m-0 mandatorySimple">{label_management?.series[23]?.title}</label>
                      <p className="p1">{label_management?.series[23]?.sub_title}</p>
                      <select
                        onChange={handleInput}
                        value={editUser?.access}
                        name="access"
                        id="access"
                        className="form-select"
                        onClick={(event) => {
                          setallliveuseraccess(event.target.value);
                        }}
                      >
                        {/* <option value={item?.role}>{item?.name}</option> */}
                        <option value="">Select a User Access</option>
                        {user_access?.map((item, key) => (
                          <option value={item?.role || item?.value}>{item?.name}</option>
                        ))}
                      </select>
                    </div>

                    <div>{alllive_streamuseraccess()}</div>
                  </div>

                  <div className="card p-3">
                    <h5>{label_management?.series[24]?.title}</h5>
                    <hr></hr>

                    <div className="col-sm-12 row">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">{label_management?.series[25]?.title}</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="featured"
                            id="featured"
                            onChange={handleInputenable}
                            // value={editUser?.featured}
                            className="rs-input"
                            defaultChecked={
                              editUser?.featured === 1 ? true : false
                            }
                            checked={editUser?.featured === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="featured"
                            id="featured"
                            onChange={handleInputenable}
                            value={editUser?.featured === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">{label_management?.series[26]?.title}</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="active"
                            id="active"
                            onChange={handleInputenable}
                            // value={editUser?.active}
                            defaultChecked={
                              editUser?.active === 1 ? true : false
                            }
                            checked={editUser?.active === 1 ? true : false}
                            className="rs-input"
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="active"
                            id="active"
                            onChange={handleInputenable}
                            value={editUser?.active === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">{label_management?.series[27]?.title}</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="banner"
                            onChange={handleInputenable}
                            className="r-input"
                            id="banner"
                            // value={editUser?.banner}
                            defaultChecked={
                              editUser?.banner === 1 ? true : false
                            }
                            checked={editUser?.banner === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="banner"
                            onChange={handleInputenable}
                            id="banner"
                            value={editUser?.banner === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">{label_management?.series[28]?.title}</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="series_trailer"
                            id="series_trailer"
                            onChange={handleInputenable}
                            className="r-input"
                            // value={editUser?.series_trailer}
                            defaultChecked={
                              editUser?.series_trailer === 1 ? true : false
                            }
                            checked={
                              editUser?.series_trailer === 1 ? true : false
                            }
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="series_trailer"
                            id="series_trailer"
                            onChange={handleInputenable}
                            value={editUser?.series_trailer === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                    {/* <div className="col-sm-12 row">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">
                        Season 1 :
                        </p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="series_trailer"
                            id="series_trailer"
                            onChange={handleInput}
                            className="r-input"
                            defaultChecked={series_trailer.series_trailer == 1 ? true : false}
                            checked={series_trailer.series_trailer == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="series_trailer"
                            id="series_trailer"
                            onChange={handleInput}
                            value={series_trailer.series_trailer == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div> */}
                  </div>

                  <div className="card p-3">
                    <h5>{label_management?.series[29]?.title}</h5>
                    <hr></hr>

                    <div className="col-sm-12">
                      <label className="m-0">{label_management?.series[30]?.title}</label>

                      <div className=" mt-2">
                        <input
                          type="text"
                          value={editUser?.website_page_title}
                          name="website_page_title"
                          className="rs-input form-control-lg"
                          onChange={handleInput}
                          placeholder="website title"
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 mt-2">
                      <label className="m-0">{label_management?.series[31]?.title}</label>

                      <div className=" mt-2">
                        <input
                          type="text"
                          value={editUser?.website_URL}
                          name="website_URL"
                          className="rs-input form-control-lg"
                          onChange={handleInput}
                          placeholder="website url"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 mt-2">
                      <label className="m-0">{label_management?.series[32]?.title}</label>

                      <div className=" mt-2">
                        <textarea
                          type="text"
                          value={editUser?.Meta_description}
                          name="Meta_description"
                          className="rs-input form-control-lg"
                          onChange={handleInput}
                          placeholder="meta description"
                        ></textarea>
                      </div>
                    </div>
                  </div>


                  {/* <div className="card p-3">
                    <h5>Choose Ads Settings</h5>
                    <hr></hr>

                    <div className="col-sm-12 mt-2">
                      <label className="m-0">Choose Ads Position</label>

                      <div className=" mt-2">
                        <Select
                          options={adsPositions}
                          className="rs-input"
                          onChange={(e) => handleAdsPositionChange(e)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 mt-2">
                      <label className="m-0">Choose Advertisement</label>

                      <div className=" mt-2">
                        <Select
                          options={secondSelectOptions}
                          className="rs-input"
                          onChange={(e) => setSecondSelectOptions(e)}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="iq-card">
              <div className="admin-section-title ">
                <div className="row p-3">
                  <div className="col-md-8">
                    <h3 className="fs-title">{label_management?.series_season[0]?.title}</h3>
                  </div>
                  <div className="col-md-4 d-flex justify-content-end">
                    {/* <Link
                      to=""
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      Create Season
                    </Link> */}
                    <button
                      className="btn btn-primary"
                      onClick={handleFirstModal}
                    >
                      {label_management?.series_season[1]?.title}
                    </button>
                  </div>
                </div>


                {showFirstModal && (
                  <div className="modal fade show fade show" role="dialog" aria-modal="true" style={{ display: "block", background: "#00000085", }}>

                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">{label_management?.series_season[2]?.title}</h4>
                          <button type="button" className="btn-close" onClick={closeFirstModal}></button>
                        </div>

                        <div className="modal-body">


                          <div className=" text-start">
                            <label className="m-0">
                              {label_management?.series_season[3]?.title}<span className="mandatory">*</span>
                            </label>
                            <p className="t-1">
                              {label_management?.series_season[3]?.sub_title}
                            </p>
                            <div className="mt-2">
                              <input
                                type="text"
                                id="title"
                                name="title"
                                className="rs-input form-control-lg"
                                value={editUsereditUser?.title}
                                onChange={handleInput}
                                placeholder="Series Title"
                                ref={titleInputRefeditUsereditUser}
                              />
                            </div>
                            <span className="errorred">
                              {!isValidationHiddentitleeditUsereditUser && (
                                <p>{validationMessagetitleeditUsereditUser}</p>
                              )}
                            </span>
                          </div>

                          <div>
                            <div className=" text-start mt-2">
                              <label className="m-0"> {label_management?.series_season[4]?.title}
                                {compressResolutionFormat?.season === 1 && (
                                  <span className="mandatory">*</span>
                                )}
                              </label>
                              <p className="t-1">{label_management?.series_season[4]?.sub_title}</p>

                              <div className="mt-2">
                                <div className="col-lg-12 row">
                                  <div className="col-6 col-sm-6 col-lg-6">
                                    <div
                                      className="imagedrop"
                                      onClick={() =>
                                        fileInputRef4.current.click()
                                      }
                                    >
                                      <input
                                        type="file"
                                        ref={fileInputRef4}
                                        onChange={(event) =>
                                          handleFileChange(
                                            event,
                                            fileInputRef4,
                                            setSelectedFile4,
                                            'file4'
                                          )
                                        }
                                        accept="image/*"
                                        style={{ display: "none" }}
                                      />
                                      <label>
                                        <CameraRetroIcon />
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-6 col-sm-6 col-lg-6">
                                    <div>
                                      {selectedFile4 && (
                                        <div className="imagedropcopy text-end">
                                          <div className="col-lg-12 row p-0 m-0">
                                            <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                              <img
                                                src={URL?.createObjectURL(
                                                  selectedFile4
                                                )}
                                                alt="Uploaded"
                                                className="thumbnails-upload-images"
                                              />
                                            </div>
                                            <div
                                              className="col-sm-2 col-lg-2 p-0 m-0"
                                              style={{ alignSelf: "center" }}
                                            >
                                              <button
                                                onClick={() => handleDelete(4)}
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                              ></button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {error?.file4 && <div className="errorred mt-1">{error?.file4}</div>}
                            </div>

                            <div className="mt-2">
                              <label>{label_management?.series_season[5]?.title}</label>
                              <div className="dropzoneStyle">
                                <Dropzone accept="video/*" onDrop={onDrop}>
                                  {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      <p className="p-4">
                                        Drag and drop a video file here, or click
                                        to select a file
                                      </p>
                                    </div>
                                  )}
                                </Dropzone>
                                {selectedFile && (
                                  <div>
                                    {/* <label> Season Trailer :</label>
                                   <h3>Selected File:</h3> */}
                                    <p>{selectedFile?.name}</p>
                                  </div>
                                )}
                                <input
                                  type="file"
                                  accept="video/*"
                                  style={{ display: "none" }}
                                  onChange={handleFileChangessss}
                                />
                              </div>
                            </div>

                            {/* <div className="form-group">
                            <label> Season Trailer :</label>
                            <div className="new-video-file form_video-upload">
                              <input
                                type="file"
                                className="rs-input"
                                accept="video/mp4,video/x-m4v,video/*"
                                name="trailer"
                                id="trailer"
                                onChange={(e) => setTrailer(e.target.files[0])}
                              />
                              <p>Drop and drag the video file</p>
                            </div>
                          </div> */}

                            {/* <div className="form-group">
                            <label>
                              Season Thumbnail{" "}
                              <span>(16:9 Ratio or 1280X720px)</span>
                            </label>
                            <br />
                            <input
                              type="file"
                              className="season_image rs-input"
                              name="trailerimage"
                              id="trailerimage"
                              onChange={(e) =>
                                setTrailerImage(e.target.files[0])
                              }
                            />
                          </div> */}

                            {/* <div className="form-group">
                            <label>Choose User Access</label>
                            <select
                              className="form-select"
                              id="access"
                              name="access"
                              onChange={(e) => setAccess(e.target.value)}
                            >
                              {useraccess?.map((item) => (
                                <option value={item?.role}>{item?.name}</option>
                              ))}
                            </select>
                          </div> */}

                            <div className="mt-2">
                              <div className="col-sm-12">
                                <label className="m-0">{label_management?.series_season[6]?.title}</label>
                                <p className="p1">
                                  {label_management?.series_season[6]?.sub_title}
                                </p>
                                {/* <select
                                  onChange={(e) =>
                                    setStoreliveuseraccessseason(e.target.value)
                                  }
                                  name="access"
                                  id="access"
                                  className="form-select"
                                  onClick={(event) => {
                                    setallliveuseraccessseason(
                                      event.target.value
                                    );
                                  }}
                                >
                                  {useraccessseason?.map((item) => (
                                    <option value={item?.value}>
                                      {item?.name}
                                    </option>
                                  ))}
                                </select> */}
                                <select
                                  onChange={(e) => handleAccess(e.target.value)}
                                  // onChange={(e) => setStoreliveuseraccessseason(e.target.value)}
                                  name="access"
                                  id="access"
                                  className="form-select"
                                  onClick={(event) => setallliveuseraccessseason(event.target.value)}
                                  ref={accessInputRef}
                                >
                                  {/* <option value="">Choose User Access</option> */}
                                  <option value="">Select a User Access</option>
                                  {filteredOptions?.map((item) => (
                                    <option key={item?.value} value={item?.role || item?.value}>
                                      {item?.name}
                                    </option>
                                  ))}
                                </select>
                                <span className="errorred mt-2 d-block">
                                  {!isValidationHiddenAccess && (
                                    <p>{validationMessageAccess}</p>
                                  )}
                                </span>
                              </div>

                              <div>{alllive_streamuseraccessseason()}</div>
                            </div>

                            {/* <div className="form-group" id="ppv_price">
                            <label className="">PPV Price</label>
                            <input
                              type="text"
                              className="rs-input"
                              placeholder="PPV Price"
                              name="ppv_price"
                              id="price"
                              onChange={(e) => setPpv_price(e.target.value)}
                            />
                          </div>

                          <div className="form-group">
                            <label className="m-0">IOS PPV Price</label>
                            <select
                              name="ios_ppv_price"
                              className="form-select"
                              id="ios_ppv_price"
                              onChange={(e) =>
                                setIos_product_id(e.target.value)
                              }
                            >
                              {ios_ppv_pricename?.map((item) => (
                                <option value={item?.product_id}>
                                  {item?.plan_price}
                                </option>
                              ))}
                            </select>
                          </div> */}

                            {/* <div className="form-group">
                            <label>PPV Interval</label>
                            <p className="p1">
                              Please Mention How Many Episodes are Free
                            </p>
                            <input
                              type="text"
                              id="ppv_interval"
                              name="ppv_interval"
                              onChange={(e) => setPpv_interval(e.target.value)}
                              className="rs-input"
                            />
                          </div> */}
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={closeFirstModal}
                          >
                            {label_management?.series_season[14]?.title}
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            id="submit-new-cat"
                            onClick={series_season}
                          >
                            {label_management?.series_season[13]?.title}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}


                <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">Confirm Deletion</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                        Are you sure you want to delete this item?
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={() => deleteOperation(modalId)}>Delete</button>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="row">
                  <div className="col-lg-6 p-0">
                    <table className="table table-bordered genres-table">
                      <tr className="table-header">
                        <th>Seasons</th>
                        <th>Operation</th>
                      </tr>
                      {editseason?.map((edit, key) => (
                        <tr>
                          <td valign="bottom">
                            <p>Season {key + 1} </p>
                          </td>
                          <td>
                            <p>
                              <Link
                                to={"/edit-season/" + edit?.id}
                                className="edit ms-1"
                              >
                                <img
                                  src={edititem}
                                  alt="flogo"
                                  width={15}
                                  height={15}
                                />
                                <span className="ms-2">Edit Season</span>
                              </Link>
                              <Link to="" className="delete ms-1" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => handleDeleteClick(edit?.id)}>
                                <span >
                                  <img
                                    src={deleteitem}
                                    alt="flogo"
                                    width={15}
                                    height={15}
                                  />
                                  <span className="ms-2">Delete</span>{" "}
                                </span>
                              </Link>
                            </p>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                  <div className="col-lg-6 p-0">
                    <table className="table table-bordered genres-table">
                      <tr className="table-header">
                        <th>Episodes</th>
                        <th>Operation</th>
                      </tr>
                      {manageseason?.map((manage, key) => (
                        <tr>
                          <td valign="bottom">
                            <p> {manage?.episode_count} Episodes </p>
                          </td>
                          <td>
                            <p>
                              <Link
                                to={
                                  "/manage-episode/" +
                                  seriesid +
                                  "/" +
                                  manage?.id
                                }
                                className="edit ms-1"
                              >
                                <img
                                  src={edititem}
                                  alt="flogo"
                                  width={15}
                                  height={15}
                                />{" "}
                                <span className="ms-2">Manage Episodes</span>
                              </Link>
                            </p>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <section className="section container-fluid pt-3">
            <div className="iq-card p-3">
              <h4>
                <i className="entypo-plus"></i> Edit Series
              </h4>
              <hr />
              <form>
                <div className="row mt-3">
                  <div className="col-sm-6 mb-3">
                    <label className="m-0">Title</label>
                    <p className="p1">
                      Add the series title in the textbox below.
                    </p>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      id="title"
                      placeholder="Series Title"
                      value={editUser?.title}
                      onChange={handleInput}
                    />
                    <input
                      type="text"
                      className="form-control"
                      name="type"
                      hidden
                      id="type"
                      placeholder="Series type"
                      value={editUser?.type}
                      onChange={handleInput}
                    />
                  </div>

                  <div className="col-sm-6" data-collapsed="0">
                    <label className="m-0">Slug</label>
                    <div className="panel-body">
                      <p className="p1">Add a URL Slug</p>
                      <input
                        type="text"
                        className="form-control"
                        name="slug"
                        id="slug"
                        placeholder="Series Slug"
                        value={editUser?.slug}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div
                      className="panel panel-primary mt-3"
                      data-collapsed="0"
                    >
                      <div className="panel-heading">
                        <div className="panel-title font-weight-bold">
                          <label className="m-0">Series Image Cover</label>
                        </div>
                      </div>

                      <div className="panel-body col-sm-8 p-0">
                        <p className="p1">
                          Select the series image ( 9:16 Ratio or 1080X1920px ):
                        </p>
                        <img
                          src={img?.image_url}
                          className="series-img"
                          width="200"
                        />
                        <input
                          type="file"
                          multiple="true"
                          onChange={(e) => setImage(e.target.files[0])}
                          className="form-control image series_image"
                          name="image"
                          id="image"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div
                      className="panel panel-primary mt-3"
                      data-collapsed="0"
                    >
                      {" "}
                      <div className="panel-heading">
                        <div className="panel-title font-weight-bold">
                          <label className="m-0">Series Player Image </label>
                        </div>
                      </div>
                      <div className="panel-body col-sm-8 p-0">
                        <p className="p1">
                          Select the Player image ( 16:9 Ratio or 1280X720px ):
                        </p>
                        <img
                          src={img?.player_image}
                          className="series-img"
                          width="200"
                        />
                        <input
                          type="file"
                          multiple="true"
                          onChange={(e) => setPlayer_image(e.target.files[0])}
                          className="form-control"
                          name="player_image"
                          id="player_image"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row d-flex">
                  <div className="col-md-6">
                    <div
                      className="panel panel-primary mt-3"
                      data-collapsed="0"
                    >
                      <div className="panel-heading">
                        <div className="panel-title font-weight-bold">
                          <label className="m-0">Series TV Image Cover</label>
                        </div>
                      </div>

                      <div className="panel-body col-sm-8 p-0">
                        <p className="p1">
                          Select the TV series image ( 16:9 Ratio or 1920 X 1080
                          px ):
                        </p>
                        <img
                          src={img?.tv_image}
                          className="series-img"
                          width="200"
                        />
                        <input
                          type="file"
                          multiple="true"
                          onChange={(e) => setTv_image(e.target.files[0])}
                          className="form-control image"
                          name="tv_image"
                          id="tv_image"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="panel panel-primary mt-3" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div
                      className="panel panel-primary mt-3"
                      data-collapsed="0"
                    >
                      <div className="panel-heading">
                        <div className="panel-title font-weight-bold">
                          <label className="m-0">
                            Series Details, Links, and Info
                          </label>
                        </div>{" "}
                      </div>
                      <div className="panel-body col-sm-12 p-0">
                      <JoditEditor
                          value={editUser?.details}
                          onChange={(e) => {
                            handleChangeFirst(e);
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className="panel panel-primary mt-3"
                      data-collapsed="0"
                    >
                      {" "}
                      <div className="panel-heading">
                        <div className="panel-title font-weight-bold">
                          <label className="m-0">Short Description</label>
                        </div>{" "}
                      </div>
                      <div className="panel-body col-sm-12 p-0">
                        <p className="p1">
                          Add a short description of the series below:
                        </p>
                        <textarea
                          className="form-control"
                          name="description"
                          id="description"
                          onChange={handleInput}
                          value={editUser?.description}
                        ></textarea>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-sm-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Cast and Crew</label>{" "}
                            </div>{" "}
                            <div className="panel-options">
                              {" "}
                              <Link to="#" data-rel="collapse">
                                <i className="entypo-down-open"></i>
                              </Link>{" "}
                            </div>
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Add artists for the series below:
                            </p>
                           
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Category</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Select a Series Category Below:
                            </p>

                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-sm-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Series Ratings</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body p-0 ">
                            <p className="p1">IMDb Ratings 10 out of 10</p>
                            <select
                              value={editUser?.rating}
                              className="form-select"
                              name="rating"
                              id="rating"
                              onChange={handleInput}
                            >
                               <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Language</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Select a Series Language Below:
                            </p>
                           
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row align-items-center mt-3">
                      <div className="col-sm-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Series Year</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body  p-0">
                            <p className="p1">Series Created Year</p>
                            <input
                              className="form-control"
                              name="year"
                              id="year"
                              onChange={handleInput}
                              value={editUser?.year}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6" data-collapsed="0">
                        <div className="panel-heading">
                          <div className="panel-title font-weight-bold">
                            <label className="m-0">Search Tags</label>
                          </div>

                          <div className="">
                            <input
                              className="form-control "
                              type="search"
                              placeholder="search_tags"
                              aria-label="Search"
                              value={editUser?.search_tag}
                              onChange={handleInput}
                            />
                          </div>
                        </div>
                      
                      </div>
                    </div>

                    <div className="row text-start mt-4">
                      <div className="col-sm-6">
                        <label className="m-0">Choose Ads Position</label>

                        <div className="">
                         

                          <Select
                            options={adsPositions}
                            onChange={(e) => handleAdsPositionChange(e)}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <label className="m-0">Choose Advertisement</label>

                        <div className="">
                          <Select
                            options={secondSelectOptions}
                            onChange={(e) => setSecondSelectOptions(e)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row p-3">
                      <div className="col-sm-4 p-0">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            {" "}
                            <div className="panel-title font-weight-bold">
                              {" "}
                              <label className="m-0">Duration</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Enter the duration in the (HH: MM : SS) format{" "}
                            </p>
                            <input
                              className="form-control"
                              name="duration"
                              id="duration"
                              value={editUser?.duration}
                            />
                          </div>
                        </div>

                        <div className="panel-body mt-3">
                          <div className="d-flex justify-content-between align-items-baseline">
                            <label className="m-0 p2">
                              Apply Global PPV Price:
                            </label>
                          
                            <label className="switch">
                              <input
                                name="ppv_status"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.ppv_status === 1 ? true : false
                                }
                                checked={
                                  editUser?.ppv_status === 1 ? true : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="ppv_status"
                                onChange={handleInput}
                                value={editUser?.ppv_status === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>
                       
                      </div>

                      <div className="col-sm-4">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            {" "}
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">User Access</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Who is allowed to view this series?
                            </p>
                            <select
                              value={editUser?.access}
                              className="form-control"
                              onChange={handleInput}
                            >
                               {data?.map((item) => (
                                <option value={item?.value}>
                                  {item?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-4">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            {" "}
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Status Settings</label>
                            </div>
                          </div>
                          <div className="panel-body mt-3">
                            <div className="d-flex justify-content-between align-items-baseline">
                              <label className="p2" for="featured">
                                Is this series Featured:
                              </label>
                             
                              <label className="switch">
                                <input
                                  name="status"
                                  onChange={handleInput}
                                  defaultChecked={
                                    editUser?.status === 1 ? true : false
                                  }
                                  checked={editUser?.status === 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="status"
                                  onChange={handleInput}
                                  value={editUser?.status === 1 ? "1" : "0"}
                                ></span>
                              </label>
                            </div>
                            <div className="d-flex justify-content-between align-items-baseline">
                              <label className="p2" for="banner" name="banner">
                                Banner :
                              </label>
                            
                              <label className="switch">
                                <input
                                  name="banner"
                                  onChange={handleInput}
                                  defaultChecked={
                                    editUser?.banner === 1 ? true : false
                                  }
                                  checked={editUser?.banner === 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="banner"
                                  onChange={handleInput}
                                  value={editUser?.banner === 1 ? "1" : "0"}
                                ></span>
                              </label>
                            </div>
                            <div className="d-flex justify-content-between align-items-baseline">
                              <label className="p2" for="active">
                                Is this series Active:
                              </label>

                             
                              <label className="switch">
                                <input
                                  name="active"
                                  onChange={handleInput}
                                  defaultChecked={
                                    editUser?.active === 1 ? true : false
                                  }
                                  checked={editUser?.active === 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="active"
                                  onChange={handleInput}
                                  value={editUser?.active === 1 ? "1" : "0"}
                                ></span>
                              </label>
                            </div>
                            <div className="d-flex justify-content-between align-items-baseline">
                              <label className="p2" for="featured">
                                Enable this series as Slider:
                              </label>
                           
                              <label className="switch">
                                <input
                                  name="featured"
                                  onChange={handleInput}
                                  defaultChecked={
                                    editUser?.featured === 1 ? true : false
                                  }
                                  checked={
                                    editUser?.featured === 1 ? true : false
                                  }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="featured"
                                  onChange={handleInput}
                                  value={editUser?.featured === 1 ? "1" : "0"}
                                ></span>
                              </label>
                            </div>
                            <div className="d-flex justify-content-between align-items-baseline mr-2">
                              <div>
                                <label className="p2" for="trailer">
                                  Season Trailer:
                                </label>
                              </div>
                              
                              <label className="switch">
                                <input
                                  name="trailer"
                                  onChange={handleInput}
                                  defaultChecked={
                                    editUser?.trailer === 1 ? true : false
                                  }
                                  checked={
                                    editUser?.trailer === 1 ? true : false
                                  }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="trailer"
                                  onChange={handleInput}
                                  value={editUser?.trailer === 1 ? "1" : "0"}
                                ></span>
                              </label>
                            </div>
                            <div className="d-flex justify-content-between align-items-baseline mr-2">
                              <div>
                                <label className="p2" for="active">
                                  Series Trailer:
                                </label>
                              </div>
                            
                              <label className="switch">
                                <input
                                  name="series_trailer"
                                  onChange={handleInput}
                                  defaultChecked={
                                    editUser?.series_trailer === 1 ? true : false
                                  }
                                  checked={
                                    editUser?.series_trailer === 1 ? true : false
                                  }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="series_trailer"
                                  onChange={handleInput}
                                  value={
                                    editUser?.series_trailer === 1 ? "1" : "0"
                                  }
                                ></span>
                              </label>
                            </div>
                            <div className="d-flex justify-content-between align-items-baseline mr-2">
                              <div className="">
                                <label className="p2" for="active">
                                  Season 1 :
                                </label>
                              </div>
                             
                              <label className="switch">
                                <input
                                  name="season_trailer"
                                  onChange={handleInput}
                                  defaultChecked={
                                    editUser?.season_trailer === 1 ? true : false
                                  }
                                  checked={
                                    editUser?.season_trailer === 1 ? true : false
                                  }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="season_trailer"
                                  onChange={handleInput}
                                  value={
                                    editUser?.season_trailer === 1 ? "1" : "0"
                                  }
                                ></span>
                              </label>
                            </div>

                            <div className="row justify-content-between align-items-baseline mr-2 views">
                              <div className="col-md-5">
                                <label className="p2" for="active">
                                  Views :
                                </label>
                              </div>
                              <div className="col-md-3 ">
                                
                                <label className="switch">
                                  <input
                                    name="views"
                                    onChange={handleInput}
                                    defaultChecked={
                                      editUser?.views === 1 ? true : false
                                    }
                                    checked={
                                      editUser?.views === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="views"
                                    onChange={handleInput}
                                    value={editUser?.views === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-sm-12 text-end">
                      <input
                        type="submit"
                        value="Upload"
                        className="btn btn-primary"
                        onClick={handleUpdate}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="iq-card">
              <div className="admin-section-title ">
                <div className="row p-3">
                  <div className="col-md-8">
                    <h3 className="fs-title">Manage Season &amp; Episodes</h3>
                  </div>
                  <div className="col-md-4 d-flex justify-content-end">
                    <Link
                      to=""
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      Create Season
                    </Link>
                  </div>
                </div>
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title">Add Season</h4>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>

                      <div className="modal-body">
                        <form
                          id="new-cat-form"
                          accept-charset="UTF-8"
                          action=""
                          enctype="multipart/form-data"
                          method="post"
                        >
                          <div className="form-group">
                            <label> Season Trailer :</label>
                            <div className="new-video-file form_video-upload">
                              <input
                                type="file"
                                className="form-control"
                                accept="video/mp4,video/x-m4v,video/*"
                                name="trailer"
                                id="trailer"
                                onChange={(e) => setTrailer(e.target.files[0])}
                              />
                              <p>Drop and drag the video file</p>
                            </div>
                          </div>

                          <div className="form-group">
                            <label>
                              Season Thumbnail{" "}
                              <span>(16:9 Ratio or 1280X720px)</span>
                            </label>
                            <br />
                            <input
                              type="file"
                              className="season_image form-control"
                              name="trailerimage"
                              id="trailerimage"
                              onChange={(e) =>
                                setTrailerImage(e.target.files[0])
                              }
                            />
                          </div>

                          <div className="form-group">
                            <label> Choose User Access:</label>
                            <select
                              className="form-control"
                              id="access"
                              name="access"
                              onChange={(e) => setAccess(e.target.value)}
                            >
                              {useraccess?.map((item) => (
                                <option value={item?.name}>{item?.name}</option>
                              ))}
                            </select>
                          </div>

                          <div className="form-group" id="ppv_price">
                            <label className="">PPV Price:</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="PPV Price"
                              name="ppv_price"
                              id="price"
                              onChange={(e) => setPpv_price(e.target.value)}
                            />
                          </div>

                          <div className="form-group">
                            <label className="m-0">IOS PPV Price:</label>
                            <select
                              name="ios_ppv_price"
                              className="form-control"
                              id="ios_ppv_price"
                              onChange={(e) =>
                                setIos_product_id(e.target.value)
                              }
                            >
                              <option value="">Select IOS PPV Price: </option>
                              <option value=""></option>
                            </select>
                          </div>

                          <div className="form-group">
                            <label>PPV Interval:</label>
                            <p className="p1">
                              Please Mention How Many Episodes are Free:
                            </p>
                            <input
                              type="text"
                              id="ppv_interval"
                              name="ppv_interval"
                              onChange={(e) => setPpv_interval(e.target.value)}
                              className="form-control"
                            />
                          </div>
                        </form>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          id="submit-new-cat"
                          onClick={series_season}
                        >
                          Save changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 p-0">
                    <table className="table table-bordered genres-table">
                      <tr className="table-header">
                        <th>Seasons</th>
                        <th>Operation</th>
                      </tr>
                      {editseason?.map((edit) => (
                        <tr>
                          <td valign="bottom">
                            <p>Season {edit?.id} </p>
                          </td>
                          <td>
                            <p>
                              <Link
                                to={"/edit-season/" + edit?.id}
                                className="edit ms-1"
                              >
                                <i
                                  className="fa fa-pencil-square"
                                  aria-hidden="true"
                                >
                                  {" "}
                                  Edit Season
                                </i>
                              </Link>
                              <Link to="" className="delete ms-1">
                                <span onClick={() => deleteOperation(edit?.id)}>
                                  <i className="fa fa-trash" aria-hidden="true">
                                    Delete
                                  </i>{" "}
                                </span>
                              </Link>
                            </p>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                  <div className="col-lg-6 p-0">
                    <table className="table table-bordered genres-table">
                      <tr className="table-header">
                        <th>Episodes</th>
                        <th>Operation</th>
                      </tr>
                      {manageseason?.map((manage) => (
                        <tr>
                          <td valign="bottom">
                            <p> Episodes {manage?.id} </p>
                          </td>
                          <td>
                            <p>
                              <Link
                                to={
                                  "/manage-episode/" +
                                  seriesid +
                                  "/" +
                                  manage?.id
                                }
                                className="edit ms-1"
                              >
                                <i
                                  className="fa fa-pencil-square"
                                  aria-hidden="true"
                                >
                                  Manage Episodes
                                </i>
                              </Link>
                            </p>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
        </div>
      </div>
    </>
  );
}

export default EditSeriesList;
