// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroll-active .react-bootstrap-table {
    overflow-x: auto;
    width: 100%;
}

.filterTypeCustom {
    padding: 12px 17px;
    font-size: 13px;
    font-weight: 500;
    height: 42px;
    width: 130px;
    appearance: none;
}
.filterTypeCustom.calender{
    width: 150px;
}

.filterIcon {
    top: 14px;
    right: 12px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Site/Analytics/PPVAnalytics.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI,YAAY;AAChB;;AAEA;IACI,SAAS;IACT,WAAW;AACf","sourcesContent":[".scroll-active .react-bootstrap-table {\n    overflow-x: auto;\n    width: 100%;\n}\n\n.filterTypeCustom {\n    padding: 12px 17px;\n    font-size: 13px;\n    font-weight: 500;\n    height: 42px;\n    width: 130px;\n    appearance: none;\n}\n.filterTypeCustom.calender{\n    width: 150px;\n}\n\n.filterIcon {\n    top: 14px;\n    right: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
