// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard .nav-link {
    border-radius: 5px;
    border-bottom: 1px solid #e9e6e6;
    color: #000000;
    background-color: #e1e1e1;
     width: 15rem;
    padding: 15px 10px;
}

.add {
    padding: 0.5rem 0.5rem;
    background-color: #198754!important;
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.remove_url{
    background-color: #dc3545!important;
}
.tab-content{
    /* padding-top: 15px; */
    width: 100%;
}
.p1{
    font-size: 12px!important;
}
.fonti i{
 font-size: 45px;
 background-color: #e0e0e0;
 border-radius: 10px;
 padding: 5px;
}

.site-setting {
    color: #000000;
}
.site-setting:hover{
    color: #000000;
}

.sitecard{
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    background: #fcfcfc;
    height: 100%;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/Pages/Site/Settings/StorefrontSettings.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gCAAgC;IAChC,cAAc;IACd,yBAAyB;KACxB,YAAY;IACb,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;IACtB,mCAAmC;IACnC,wBAAwB;IACxB,wDAAwD;AAC5D;AACA;IACI,mCAAmC;AACvC;AACA;IACI,uBAAuB;IACvB,WAAW;AACf;AACA;IACI,yBAAyB;AAC7B;AACA;CACC,eAAe;CACf,yBAAyB;CACzB,mBAAmB;CACnB,YAAY;AACb;;AAEA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;IACZ,4CAA4C;AAChD","sourcesContent":[".dashboard .nav-link {\n    border-radius: 5px;\n    border-bottom: 1px solid #e9e6e6;\n    color: #000000;\n    background-color: #e1e1e1;\n     width: 15rem;\n    padding: 15px 10px;\n}\n\n.add {\n    padding: 0.5rem 0.5rem;\n    background-color: #198754!important;\n    border-bottom-width: 1px;\n    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);\n}\n.remove_url{\n    background-color: #dc3545!important;\n}\n.tab-content{\n    /* padding-top: 15px; */\n    width: 100%;\n}\n.p1{\n    font-size: 12px!important;\n}\n.fonti i{\n font-size: 45px;\n background-color: #e0e0e0;\n border-radius: 10px;\n padding: 5px;\n}\n\n.site-setting {\n    color: #000000;\n}\n.site-setting:hover{\n    color: #000000;\n}\n\n.sitecard{\n    border-radius: 10px;\n    border: 1px solid #e0e0e0;\n    background: #fcfcfc;\n    height: 100%;\n    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
